import React from 'react';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-unresolved
import CsvDownloader from 'react-csv-downloader';
import { Icons } from '../../../constants/icons';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaModal from '../../../bitaComponents/BitaModal/BitaModal';
import Builder from '../../../builder/Builder';
import BitaInput from '../../BitaInput/BitaInput';
import { uploadFileSettings, setExcludeIsin } from '../../../state/actions/module-upload-actions';
import * as ApiDataActions from '../../../state/actions/api-data-actions';
import CustomFieldData from './CustomFieldData';
import { getDataComponents } from '../../../api-data-mapping/dataMap';

import '../ComponentsSettings.css';

const DataUpload = ({
  children,
  onApply = Function.prototype,
  buttonsModal,
  contentModal,
  filteUpload,
  typeFile,
  typeCurrency,
  data,
}) => {
  const [previewModalOpen, setPreviewModalOpen] = React.useState(false);
  const [createFieldModalOpen, setCreateFieldModalOpen] = React.useState(false);
  const [disabledSaveCustom, setDisabledSaveCustom] = React.useState(true);
  const [exludeIsinsModalOpen, setExludeIsinsModalOpen] = React.useState(false);
  const [builderState, setBuilderState] = React.useState({});
  const [datafield, setDatafield] = React.useState();
  const [search, setSearch] = React.useState();
  // eslint-disable-next-line no-unused-vars
  const stateApiData = useSelector(state => state.apiData.apiData);
  const token = useSelector(state => state.auth.token);
  const excludesIsin = useSelector(state => state.upload.exclude_isins);
  const loading = useSelector(state => state.upload.fileUploadLoading);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (excludesIsin.length > 0) {
      setExludeIsinsModalOpen(true);
    }
  }, [excludesIsin]);

  React.useEffect(() => {
    if (builderState?.type?.length > 0) {
      if (builderState?.['Field Name'] && builderState.field.length > 0) {
        builderState.field.forEach(field => {
          if (!(field.weight && field.operator && field.data_field?.id)) {
            setDisabledSaveCustom(true);
          } else {
            setDisabledSaveCustom(false);
          }
        });
      }
    } else {
      setDisabledSaveCustom(true);
    }
  }, [builderState]);

  const columns = [
    {
      id: 'isin',
      displayName: 'ISIN',
    },
  ];

  const formData = new FormData();
  React.useEffect(() => {
    let auxTypeFile = '';
    if (filteUpload && typeFile) {
      switch (typeFile) {
        case 'Risk Model':
          auxTypeFile = 'signals';
          break;
        case 'Universe':
          auxTypeFile = 'universe';
          break;
        case 'Data Fields':
          auxTypeFile = 'data_fields';
          break;
        case 'Benchmark':
          auxTypeFile = 'benchmark';
          break;
        default:
          break;
      }
      formData.append('template', filteUpload);
      formData.append('type', auxTypeFile);
      if (typeFile === 'Benchmark' && typeCurrency) {
        formData.append('currency', typeCurrency);
      }
    }
    // eslint-disable-next-line
  }, [filteUpload, typeFile]);

  const runModal = () => {
    setPreviewModalOpen(true);
  };
  const close = () => {
    setPreviewModalOpen(false);
  };

  const onChange = (path, value) => {
    setBuilderState(old => ({ ...old, ...{ [path]: value } }));
    setDatafield(old => ({ ...old, ...{ [path]: value } }));
  };

  const saveDataField = () => {
    if (builderState?.['Field Name'] && builderState.field.length > 0) {
      const _datafields = builderState.field.map(field => {
        return {
          weight: Number(field.weight.replace(/,/g, '')),
          operator: field.operator,
          data_field: { id: field.data_field.id, value: field.data_field.value },
        };
      });

      const _dataSent = {
        name: builderState?.['Field Name'],
        type: builderState?.type,
        data_fields: _datafields,
      };

      const dataComponent = getDataComponents.filter(
        v => v.key === 'saveDataField' && v.title === 'saveDataField',
      )[0];

      if (typeof dataComponent !== 'undefined') {
        dataComponent
          .getUrlData(_dataSent, token)
          .then(r => {
            toast('Your custom data field was successfully created!');
            setCreateFieldModalOpen(false);
            dispatch(ApiDataActions.setCustomDataFields(token));
            dispatch(ApiDataActions.setClientDataFields(token));
            dispatch(ApiDataActions.setAviableDataFields(token));
          })
          .catch(err => {
            toast.error('error saving field. Please try again');
          });
      }
    }
  };

  const handleSearch = val => {};

  return (
    <div className="dataUpload" style={{ marginTop: '-20px' }}>
      {buttonsModal ? (
        <BitaButton width="120px" height="35px" primary onClick={runModal}>
          {buttonsModal}
        </BitaButton>
      ) : (
        <div> </div>
      )}
      <div> {children} </div>

      <div className="contentBotones buttonUpload">
        <BitaButton
          width="120px"
          height="35px"
          primary
          loading={loading}
          disabled={!(filteUpload && typeFile)}
          onClick={() => {
            if (
              typeFile === 'Benchmark' &&
              stateApiData?.Benchmark?.data?.Benchmark?.find(
                item =>
                  item.name.toUpperCase() === filteUpload?.name?.split('.')?.[0]?.toUpperCase(),
              )
            ) {
              toast.error('The name is already in use. Please choose another one', {
                toastId: 'duplicatedName',
              });
            } else {
              onApply();
              if (filteUpload) {
                dispatch(uploadFileSettings(token, formData));
              }
            }
          }}
        >
          Upload
        </BitaButton>
      </div>

      <BitaModal isModalOpen={previewModalOpen} setModalState={setPreviewModalOpen}>
        <div className="modaldataUpload">
          <div className="close" onClick={close}>
            &times;
          </div>
          <div className="titleModal">
            {' '}
            <Icons.Avanti />
            {contentModal.title}
          </div>
          <div className="content">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div className="titleComponente">
                {' '}
                <Icons.parameters /> {contentModal.components[0].title}
              </div>
              <div>
                {' '}
                <BitaInput
                  type="text"
                  className="searchInput"
                  placeholder="Search by..."
                  title="Search by..."
                  autoFocus
                  id="inputSearchModalCustomField"
                  value={search}
                  handleChange={val => {
                    setSearch(val);
                  }}
                />
              </div>
            </div>
            <CustomFieldData search={search} />

            <BitaButton
              width="220px"
              height="35px"
              primary
              onClick={() => {
                setCreateFieldModalOpen(true);
              }}
            >
              {contentModal.components[0].buttonmodal}
            </BitaButton>
          </div>
          {contentModal.components[1].type === 'Button' ? (
            <BitaButton
              width="260px"
              height="35px"
              primary
              onClick={close}
              style={{ marginRight: '38%' }}
            >
              {contentModal.components[1].title}
            </BitaButton>
          ) : (
            <div> </div>
          )}
        </div>
      </BitaModal>

      <BitaModal
        isModalOpen={createFieldModalOpen}
        setModalState={setCreateFieldModalOpen}
        closeInOverlay={false}
      >
        <div className="modaldataCreateUpload modaldataUpload">
          <div
            className="close"
            onClick={() => {
              setCreateFieldModalOpen(false);
            }}
          >
            &times;
          </div>
          <div className="titleModal">
            {' '}
            <Icons.Avanti />
            {contentModal.components[0].onclick.title}
          </div>
          <div className="contentCreateUpload">
            {Object.values(contentModal.components[0].onclick.components).map(
              (component, index) => (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    height: component.type === 'AddibleRows' ? 'calc(100% - 160px)' : '',
                  }}
                  key={index}
                >
                  {component.type === 'AddibleRows' ? (
                    <div
                      className="contentaddibleRows"
                      style={{
                        width: '100%',
                        justifyContent: 'center',
                      }}
                      data-simplebar
                    >
                      <div style={{ height: 'calc(100% - 200px)' }}>
                        <Builder {...component} widgetData={builderState} onChange={onChange} />
                      </div>
                    </div>
                  ) : (
                    <Builder {...component} widgetData={builderState} onChange={onChange} />
                  )}
                </div>
              ),
            )}
            <BitaButton
              width="260px"
              height="35px"
              primary
              disabled={disabledSaveCustom}
              onClick={() => {
                saveDataField();
              }}
              style={{ position: 'relative', zIndex: '99999' }}
            >
              Save Custom Field
            </BitaButton>
          </div>
        </div>
      </BitaModal>

      <BitaModal isModalOpen={exludeIsinsModalOpen} setModalState={setExludeIsinsModalOpen}>
        <div className="modalExcludeIsin ">
          <div
            className="close"
            onClick={() => {
              setExludeIsinsModalOpen(false);
            }}
          >
            &times;
          </div>
          <div className="titleModal">
            {' '}
            <Icons.Avanti />
            ISIN VALIDATION
          </div>
          <div className="contentModalExcludeIsin ">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {excludesIsin.length} ISINs were not found in the database:
              <CsvDownloader
                filename="ISINs-No-found"
                separator=";"
                datas={
                  excludesIsin &&
                  excludesIsin?.map(item => {
                    return { isin: item };
                  })
                }
                columns={columns}
              >
                <Icons.download />
              </CsvDownloader>
            </div>
            <div className="isinContent" data-simplebar>
              <div
                className="isins"
                style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}
              >
                {excludesIsin && excludesIsin.map(isin => <p>{isin}</p>)}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <BitaButton
                width="260px"
                height="35px"
                primary
                onClick={() => {
                  setExludeIsinsModalOpen(false);
                  dispatch(setExcludeIsin(false));
                }}
              >
                Accept
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>
    </div>
  );
};

export default DataUpload;
