/* eslint-disable import/no-unresolved */
import React from 'react';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import XLSX from 'sheetjs-style';
import { useSelector } from 'react-redux';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaTable from '../../../bitaComponents/BitaTable/BitaTable';
import BitaSelect from '../../BitaSelect/BitaSelect';
import BitaSpinner from '../../BitaSpinner/BitaSpinner';
import {
  formatMarketCapColumn,
  formatFreefloatMarketCapColumn,
  hasValidBitaDomain,
  hasValidIdBNP,
} from '../../../utils/utils';
import './summary_constituents.css';

const selectAction = () => null;

const columnDefault = ['Name', 'FIGI', 'Ticker', 'Market Cap'];
// const dataColumnList = [
//   { label: 'Free Float Marketcap', value: 'Free Float Marketcap' },
//   { label: 'ISIN', value: 'ISIN' },
//   { label: 'Name', value: 'Name' },
// ];

const ConstituentsComponent = props => {
  const userData = useSelector(state => state.auth.userdata);
  const [listColumnSelect, setListColumnSelect] = React.useState([]);
  const isBitaUser = hasValidBitaDomain(userData?.email);
  const isGlobalXUser =
    userData?.id_company === '62e3eb96edd11900122a529d' ||
    userData?.email.includes('globalxetfs.com') ||
    userData.email.includes('sk.karem') ||
    userData?.email.includes('kd@bitadata.com') ||
    userData?.company?.name === 'Global X';
  const isSix = userData.id_company === process.env.REACT_APP_SIX_ID;
  const isBNP = hasValidIdBNP(userData.id_company);

  const dataColumn = (data, lisColumn) => {
    const namesColumnSelect = isBitaUser
      ? ['ISIN', 'Free Float Marketcap']
      : isGlobalXUser || isSix || isBNP
      ? ['ISIN']
      : lisColumn.map(item => item.value);

    const newData = data.map(item => {
      const newObjet = {};
      [...columnDefault, ...namesColumnSelect].forEach(column => {
        newObjet[column] = column === 'Name' ? item.Name : item[column];
      });
      return newObjet;
    });
    return newData;
  };

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;chartset-UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const dataMarketCap = formatMarketCapColumn({
      ...props,
      constituents: dataColumn(props.constituents, listColumnSelect),
    });

    const dataFreeFloat = isBitaUser
      ? formatFreefloatMarketCapColumn(dataMarketCap)
      : dataMarketCap;
    const ws = XLSX.utils.json_to_sheet(dataFreeFloat);
    XLSX.utils.book_append_sheet(workbook, ws, 'Results');
    const excelBuffer = XLSX.writeFile(
      workbook,
      `Universe-Constituents-${props.selectedDate?.value + fileExtension}`,
      { type: 'file' },
    );
  };

  return (
    <div className="constituents">
      <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
        <span style={{ marginRight: '10px' }}>Select a date: </span>
        <BitaSelect
          label="Select date"
          value={props.selectedDate}
          data={props.options.sort((a, b) => {
            const currentDate = new Date(a.value);
            const nextDate = new Date(b.value);
            if (currentDate < nextDate) {
              return 1;
            }
            if (currentDate > nextDate) {
              return -1;
            }
            return 0;
          })}
          // width={width}
          handleSelection={props.onSelectDate}
          selectClassname="builderSelect"
        />
        <BitaButton
          onClick={exportToExcel}
          primary
          width="180px"
          height="35px"
          style={{ marginLeft: '10px' }}
        >
          Download Constituents
        </BitaButton>
        {/* {isBitaUser && (
          <div style={{ width: '200px' }}>
            <Select
              closeMenuOnSelect={false}
              onChange={value => setListColumnSelect(value)}
              isMulti
              placeholder="Add Column to file "
              options={dataColumnList}
              classNamePrefix="multidropdown"
              className="multidropdown-container"
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  neutral90: 'white',
                  primary: 'white',
                  primary25: '#2a2d31',
                  neutral0: '#2a2d31',
                },
              })}
            />
          </div>
        )} */}
      </div>
      {(props.selectedDate && !props.isLoading && (
        <BitaTable
          data={
            dataColumn(
              props.constituents.map(entry => {
                return {
                  ...entry,
                  name: entry?.name?.length > 40 ? `${entry.name.substring(0, 40)}...` : entry.name,
                };
              }),
              listColumnSelect,
            ) || []
          }
          title="TOTAL CONSTITUENTS"
          selectAction={selectAction}
          showFields={
            isBitaUser || isGlobalXUser || isSix || isBNP
              ? [...columnDefault, 'ISIN']
              : columnDefault
          }
          secondaryTheme
          pagination
        />
      )) || (
        <div>
          <BitaSpinner />
          <div style={{ width: '100%', textAlign: 'center', marginTop: '25px' }}>
            Generating Point in Time Universe
          </div>
        </div>
      )}
    </div>
  );
};

export default ConstituentsComponent;
