import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Redirect } from 'react-router-dom';
import styles from './Login.module.scss';
import { ReactComponent as Logo } from '../../static/img/Icons/BITA.svg';
import { login } from '../../state/actions/auth-actions';
import * as ApiDataActions from '../../state/actions/api-data-actions';
import { initState } from '../../state/actions/download-actions';
import ForgotPasswordModal from './forgot-password-modal/ForgotPasswordModal';
import { BitaInformativeModal } from '../../components/BitaModal/BitaModal';

const mapStateToProps = state => {
  return {
    loading: state.auth.login.loading,
    error: state.auth.login.error,
    token: state.auth.token,
    userdata: state.auth.userdata,
  };
};

const Login = props => {
  const dispatch = useDispatch();
  const [username, setUsername] = React.useState(false);
  const [password, setPassword] = React.useState(false);
  const [remember, setRemember] = React.useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = React.useState(false);
  const usernameRef = React.useRef('username');
  const pwRef = React.useRef('password');
  const token = useSelector(state => state.auth.token);
  const userdata = useSelector(state => state.auth.userdata);

  const handleForgotModal = () => {
    setOpenForgotPasswordModal(!openForgotPasswordModal);
  };

  const accessUser = () => {
    const allow = [
      'eduardo',
      'victor',
      'basilisk',
      'joseph_bc2',
      'chicadelcable42069',
      'sk.karem',
      'alfredo_quintana',
      'carolina_teran',
      'sabrina_rojas',
      'paul herrera',
      'ehidalgo',
      'roberto',
      'gianluca',
      'denys',
      'daniel tupano',
    ];
    return allow.indexOf(username.toLowerCase()) > -1;
  };
  const doLogin = () => {
    /**
     * Dates months start at 0
     */
    if (
      !accessUser() &&
      process.env.REACT_APP_API_URL !== 'https://api-test.bitacore.com/v1' &&
      new Date() >= new Date(2021, 3, 24, 0, 0, 0, 0) &&
      new Date(2021, 3, 26, 0, 0, 0, 0) >= new Date()
    ) {
      dispatch(
        ApiDataActions.setApiData({
          title: 'modal',
          data: {
            open: true,
            text: `
            Dear BITACore User,

            We’ve been working on BITACore's platform infrastructure to improve your experience. To update the infrastructure, we’ll have a scheduled downtime.

            The platform will be under maintenance mode starting from Saturday 24th to Sunday 25th of April, 00.00 UTC.

            During this downtime, access to the platform will be limited. We apologize for the inconveniences and hassles this may cause to your workflow and experience.

            If you have questions about the platform or feedback regarding your experience so far using BITACore, reach us at bitacore@bitadata.com

            Have a wonderful weekend,
            BITA Team`,
          },
        }),
      );
      return;
    }

    if (!username || !password) {
      toast('Please, enter your user and password.');
    } else {
      props.dispatch(login(username.toLowerCase(), password, remember));
    }
  };

  const handleKeyDown = keyValue => {
    if (keyValue === 'Enter') {
      if (username.length && password.length) {
        doLogin();
      } else if (username.length) pwRef.current.focus();
      else usernameRef.current.focus();
    }
  };

  const validateRedirection = () => {
    return (
      props.location &&
      props.location.state &&
      !props.location.state.from.pathname.includes('analyzer') &&
      props.location.state.from.pathname
    );
  };
  if (props.error) {
    // remplazar esto con un cartel más bonito o error en el mismo formulario
    console.log(`Error: ${props.error}`);
  }
  useEffect(() => {
    if (token && token !== '') {
      dispatch(ApiDataActions.setUniversesList(token));
      dispatch(ApiDataActions.setMethodologiesList(token));
      dispatch(ApiDataActions.setIndexesList(token));
      dispatch(ApiDataActions.setThemesList());
      dispatch(ApiDataActions.setDataFields(token));
      dispatch(ApiDataActions.setCustomDataFields(token));
      dispatch(ApiDataActions.setClientDataFields(token));
      dispatch(ApiDataActions.setBaskets(token));
      dispatch(ApiDataActions.setAviableDataFields(token));
      dispatch(ApiDataActions.setRiskModel(token, `${userdata.first_name} ${userdata.last_name}`));
      dispatch(ApiDataActions.setBenchmarkList(token));
      dispatch(ApiDataActions.setCardStats(token));
      dispatch(ApiDataActions.setCardStatsOptimized(token));
      dispatch(ApiDataActions.setCardStatsStructured(token));
      dispatch(initState);
    }
  }, [token]);

  if (token && token !== '') {
    return <Redirect push to={validateRedirection() || '/'} />;
  }

  return (
    <div className={styles.content}>
      <div className={styles.box}>
        <Logo className={styles.logo} />
        <div className={styles.form}>
          <input
            type="text"
            id="username"
            placeholder="Email"
            onChange={evt => setUsername(evt.target.value)}
            ref={usernameRef}
            onKeyPress={evt => handleKeyDown(evt.key)}
          />
          <div className={styles.rowpass}>
            <input
              className={styles.password}
              type="password"
              id="password"
              placeholder="Password"
              onChange={evt => setPassword(evt.target.value)}
              onKeyPress={evt => handleKeyDown(evt.key)}
              ref={pwRef}
            />
            <div className={styles.forgot} onClick={handleForgotModal}>
              Forgot?{' '}
            </div>
          </div>
          <div className={styles.centered}>
            <input
              className={styles.loginButton}
              type="submit"
              id="loginbtn"
              value={props.loading ? 'Loading...' : 'Login'}
              disabled={props.loading}
              onClick={() => doLogin()}
            />
          </div>
        </div>
        <div className={styles.separator} />
        <div className={styles.information}>
          <div className={styles.informationText}>
            Don’t have a BITACore account? Contact our Sales team to discover our license pricing.
          </div>
          <div className={styles.informationLink}>bitacore@bitadata.com</div>
        </div>
      </div>
      <ForgotPasswordModal visibility={openForgotPasswordModal} close={handleForgotModal} />
    </div>
  );
};

export default connect(mapStateToProps)(Login);
