/* eslint-disable array-callback-return */
import React from 'react';
import { useSelector } from 'react-redux';

import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import moment from 'moment';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaTable from '../../../bitaComponents/BitaTable/BitaTable';
import { IconsModules } from '../../../constants/icons-module';
import BitaCheckbox from '../../BitaCheckbox/BitaCheckbox';
import BitaInput from '../../BitaInput/BitaInput';

import '../ComponentsSettings.css';

export const RadioGroup = props => {
  return props.options.map(option => (
    <React.Fragment key={option}>
      <input
        id={`radio-${option}`}
        type="radio"
        name="radio-filter"
        value={option}
        onChange={() => {
          props.onChange(option);
        }}
        checked={props.optionSelected === option}
      />
      <label htmlFor={`radio-${option}`}>{option}</label>
    </React.Fragment>
  ));
};

const ContentManager = ({
  data = [],
  options = [],
  actions = [],
  searchBar = false,
  type,
  onApply,
  optionSelected,
  noDataText,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = React.useState();
  const [dataSearch, setDataSearch] = React.useState(data);
  const [deleteMensaje, setDeleteMensaje] = React.useState(false);
  let itemsChecked = {};
  const [dataDelete, setDataDelete] = React.useState({});
  const userdata = useSelector(state => state.auth.userdata);
  const iconF = iconY => {
    switch (iconY) {
      case 'Universe':
        return IconsModules.UniverseModule;
      case 'Indexes':
        return IconsModules.IndexModule;
      case 'Methodologies':
        return IconsModules.MethodologyModule;
      case 'Structure Indexes':
        return IconsModules.StructureIModule;
      default:
        return IconsModules.UniverseModule;
    }
  };

  dataSearch.map(item => {
    item.created_at = moment(item.created_at).format('YYYY-MM-DD');
    item.updated_at = moment(item.updated_at).format('YYYY-MM-DD');
    return item;
  });

  const Image = iconF(type);

  React.useEffect(() => {
    const auxData = [];
    if (value !== '' && value !== undefined) {
      if (optionSelected !== 'Created by Organization') {
        data.map(item => {
          if (
            value &&
            item.name.toUpperCase().lastIndexOf(value.toUpperCase()) > -1 &&
            item.author.includes(`${userdata.first_name} ${userdata.last_name}`)
          ) {
            auxData.push(item);
          }
        });
      } else {
        data.map(item => {
          if (value && item.name.toUpperCase().lastIndexOf(value.toUpperCase()) > -1) {
            auxData.push(item);
          }
        });
      }
      setDataSearch(auxData);
    } else if (optionSelected !== 'Created by Organization') {
      data.map(obj => {
        if (obj.author && obj.author.includes(`${userdata.first_name} ${userdata.last_name}`)) {
          auxData.push(obj);
        }
      });
      setDataSearch(auxData);
    } else {
      setDataSearch(data);
    }
  }, [value, data, optionSelected]);

  const onchangeCheckbox = React.useCallback(
    (checked, row) => {
      if (checked) itemsChecked[row] = checked;
      if (!checked) delete itemsChecked[row];
      if (Object.values(itemsChecked).length) {
        setDeleteMensaje(true);
        dataDelete.data = itemsChecked;
        setDataDelete({ ...dataDelete, data: itemsChecked });
      }
      if (Object.values(itemsChecked).length === 0) {
        setDeleteMensaje(false);
      }
    },
    // eslint-disable-next-line
    [],
  );

  const checkboxContent = (typeCheckbox, id) => {
    return (
      <td>
        {options.map(option => (
          <div className="optionAction">
            <BitaCheckbox
              label="delete"
              key={id}
              handleChange={checked => onchangeCheckbox(checked, id)}
            />
          </div>
        ))}
      </td>
    );
  };

  return (
    <>
      <div
        className="content-Widget UserManagement contentManager"
        style={{ padding: '18px 5px 18px 18px' }}
      >
        {' '}
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ marginTop: '-60px' }}>
            {/* <RadioGroup
              options={[
                'Created by Organization',
                `Created by ${userdata.first_name} ${userdata.last_name}`,
              ]}
              optionSelected={optionSelected}
              onChange={onChangeSelected}
            /> */}
          </div>
        </div>
        <div data-simplebar style={{ height: '390px', overflow: 'auto' }}>
          <div>
            {dataSearch.length > 0 ? (
              <BitaTable
                data={dataSearch}
                showFields={['name', 'author', 'created_at', 'updated_at', 'edit']}
                search={false}
                selectAction={() => {}}
                typeElementTable={type ? `settings-${type}` : 'settings-Universes'}
                checkboxContent={checkboxContent}
                key={`settings-${type}`}
              />
            ) : (
              <div className="message">{noDataText}</div>
            )}
          </div>
        </div>
      </div>

      <div className="contentBotones">
        {searchBar ? (
          <div className="search">
            <BitaInput
              value={value}
              type="text"
              className="searchInput"
              inputStyle="searchInput"
              title="Search by..."
              handleChange={val => {
                setValue(val);
              }}
            />
          </div>
        ) : null}
        {actions.map(action => (
          <BitaButton width="220px" height="35px" primary>
            {action}
          </BitaButton>
        ))}
        <div />
        <BitaButton
          width="120px"
          height="35px"
          primary
          disabled={!deleteMensaje}
          onClick={() => {
            if (deleteMensaje) {
              itemsChecked = {};
              Object.keys(dataDelete.data).map(item => {
                if (data.filter(_item => _item.id.toString() === item.toString()).length === 0) {
                  delete dataDelete.data[item];
                }
              });
              setDeleteMensaje(false);
              onApply(dataDelete);
            }
          }}
        >
          Apply
        </BitaButton>
      </div>
    </>
  );
};

export default ContentManager;
