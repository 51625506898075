/* eslint-disable */
import React from 'react';
import { ReactComponent as Avanti } from '../static/img/Icons/Avanti.svg';

import { ReactComponent as pencil } from '../static/img/Icons/pencil.svg';
import { ReactComponent as account } from '../static/img/Icons/account.svg';
import { ReactComponent as attribution } from '../static/img/Icons/attribution.svg';
import { ReactComponent as baseuniverse } from '../static/img/Icons/baseuniverse.svg';
import { ReactComponent as constraints } from '../static/img/Icons/constraints.svg';
import { ReactComponent as deleteIcon } from '../static/img/Icons/delete.svg';
import { ReactComponent as edit } from '../static/img/Icons/edit.svg';
import { ReactComponent as expertchat } from '../static/img/Icons/expertchat.svg';
import { ReactComponent as filters } from '../static/img/Icons/filters.svg';
import { ReactComponent as info } from '../static/img/Icons/info.svg';
import { ReactComponent as logout } from '../static/img/Icons/logout.svg';
import { ReactComponent as parameters } from '../static/img/Icons/parameters.svg';
import {
  ReactComponent as parametrization,
  ReactComponent as UniverseIconparametrization,
} from '../static/img/Icons/parametrization.svg';
import { ReactComponent as performance } from '../static/img/Icons/performance.svg';
import { ReactComponent as projectdashboard } from '../static/img/Icons/projectdashboard.svg';
import { ReactComponent as save } from '../static/img/Icons/save.svg';
import { ReactComponent as search } from '../static/img/Icons/search.svg';
import { ReactComponent as download } from '../static/img/Icons/download.svg';

import { ReactComponent as structured } from '../static/img/Icons/structured.svg';
import { ReactComponent as universelection } from '../static/img/Icons/universelection.svg';
import { ReactComponent as viewOff } from '../static/img/Icons/view_off.svg';
import { ReactComponent as view } from '../static/img/Icons/view.svg';
import { ReactComponent as internet } from '../static/img/Icons/internet.svg';

import { ReactComponent as universeBuilderIcon } from '../static/img/Icons/dashboard/Universe.svg';
import { ReactComponent as analyzerIcon } from '../static/img/Icons/dashboard/Analyzer.svg';
import { ReactComponent as indexBuilderIcon } from '../static/img/Icons/dashboard/Index.svg';
import { ReactComponent as optimizedIndexBuilderIcon } from '../static/img/Icons/dashboard/Optimizer.svg';
import { ReactComponent as structuredIndexBuilderIcon } from '../static/img/Icons/dashboard/Structured.svg';
import { ReactComponent as portfolioBastekIcon } from '../static/img/Icons/dashboard/Icon-PB-Home.svg';
import { ReactComponent as structuredProductBuilderIcon } from '../static/img/Icons/dashboard/Structured_Products.svg';
import { ReactComponent as factsheetBuilderIcon } from '../static/img/Icons/dashboard/factsheet-builder-icon.svg';
import { ReactComponent as rebalancingReconstitutionBuilderIcon } from '../static/img/Icons/dashboard/Rebalancing_reconstitution.svg';
import { ReactComponent as settings } from '../static/img/Icons/Settings.svg';
import { ReactComponent as editIcon } from '../static/img/Icons-modules/Create_New_Methodology.svg';

import { ReactComponent as globe } from '../static/img/Icons/globe.svg';

import { ReactComponent as UniverseIcongeography } from '../static/img/Icons/filter/public.svg';
import { ReactComponent as UniverseIconsector } from '../static/img/Icons/filter/location_city.svg';
import { ReactComponent as UniverseIconthemes } from '../static/img/Icons/filter/category.svg';
import { ReactComponent as UniverseIcontopics } from '../static/img/Icons/filter/whatshot.svg';
import { ReactComponent as UniverseIconratings } from '../static/img/Icons/filter/Available_Filters.svg';
import { ReactComponent as UniverseIconfactors } from '../static/img/Icons/filter/timeline.svg';
import { ReactComponent as UniverseIconsize } from '../static/img/Icons/filter/fullscreen_exit.svg';
import { ReactComponent as UniverseIconfundamentals } from '../static/img/Icons/filter/book.svg';
import { ReactComponent as UniverseIconexchange } from '../static/img/Icons/filter/exchange.svg';
import { ReactComponent as UniverseIconsingleinstrument } from '../static/img/Icons/filter/singleinstrument.svg';
import { ReactComponent as UniverseIconliquidity } from '../static/img/Icons/filter/liquidity.svg';

import { ReactComponent as CustomerService } from '../static/img/Icons/customer-service.svg';
import { ReactComponent as chat } from '../static/img/Icons/chat.svg';
import { ReactComponent as clientSupport } from '../static/img/Icons/client-support.svg';
import { ReactComponent as mail } from '../static/img/Icons/mail.svg';
import { ReactComponent as resource } from '../static/img/Icons/resource.svg';
import { ReactComponent as resourcesCenter } from '../static/img/Icons/resources-center.svg';
import { ReactComponent as training } from '../static/img/Icons/training.svg';
import { ReactComponent as IconAccount } from '../static/img/Icons/account-icon.svg';
import { ReactComponent as IconTutorials } from '../static/img/Icons/Icons_tutorials.svg';
import { ReactComponent as showLess } from '../static/img/Icons/show_less-white.svg';
import { ReactComponent as showMore } from '../static/img/Icons/show_more-white.svg';
import { ReactComponent as showLessHover } from '../static/img/Icons/show_less-blue.svg';
import { ReactComponent as showMoreHover } from '../static/img/Icons/show_more-blue.svg';

import { ReactComponent as UniverseSidebar } from '../static/img/sidebar/sidebar-midscreen-universe.svg';
import { ReactComponent as IndexSidebar } from '../static/img/sidebar/sidebar-midscreen-index.svg';
import { ReactComponent as LogoSidebar } from '../static/img/sidebar/sidebar-midscreen-bitalogo.svg';
import { ReactComponent as OptimizedSidebar } from '../static/img/sidebar/sidebar-midscreen-optimizedindex.svg';
import { ReactComponent as StructuredIndexSidebar } from '../static/img/sidebar/sidebar-midscreen-structuredindex.svg';
import { ReactComponent as StructuredProductSidebar } from '../static/img/sidebar/sidebar-midscreen-structuredproduct.svg';
import { ReactComponent as AnalyzerSidebar } from '../static/img/sidebar/sidebar-midscreen-analyzer.svg';
import { ReactComponent as HomeSidebar } from '../static/img/sidebar/home-sidebar.svg';

import { ReactComponent as SettingsSidebar } from '../static/img/sidebar/AM-Settings.svg';
import { ReactComponent as LogoutSidebar } from '../static/img/sidebar/AM-Logout.svg';
import { ReactComponent as Arrowup } from '../static/img/Icons/arrow_up.svg';
import { ReactComponent as Arrowdown } from '../static/img/Icons/arrow_down.svg';
import { ReactComponent as CustomizationIcon } from '../static/img/Icons/art.svg';
import { ReactComponent as ColorEditIcon } from '../static/img/Icons/edit_color.svg';
import { ReactComponent as ColorEditBlackIcon } from '../static/img/Icons/edit_color_black.svg';

export const Icons = {
  IconAccount,
  Avanti,
  Arrowup,
  Arrowdown,
  showLess,
  showMore,
  showLessHover,
  showMoreHover,
  CustomerService,
  chat,
  clientSupport,
  mail,
  resource,
  resourcesCenter,
  training,
  UniverseIcongeography,
  UniverseIconsector,
  UniverseIconthemes,
  UniverseIcontopics,
  UniverseIconratings,
  UniverseIconfactors,
  UniverseIconliquidity,
  UniverseIconsize,
  UniverseIconfundamentals,
  UniverseIconparametrization,
  UniverseIconexchange,
  UniverseIconsingleinstrument,
  globe,
  settings,
  save,
  search,
  download,
  pencil,
  logout,
  account,
  attribution,
  baseuniverse,
  constraints,
  deleteIcon,
  edit,
  expertchat,
  filters,
  info,
  parameters,
  parametrization,
  performance,
  projectdashboard,
  structured,
  editIcon,
  universelection,
  view,
  viewOff,
  internet,
  universeBuilderIcon,
  analyzerIcon,
  factsheetBuilderIcon,
  indexBuilderIcon,
  optimizedIndexBuilderIcon,
  structuredProductBuilderIcon,
  structuredIndexBuilderIcon,
  rebalancingReconstitutionBuilderIcon,
  portfolioBastekIcon,
  CustomizationIcon,
  ColorEditIcon,
  ColorEditBlackIcon,
  IconTutorials,
};
