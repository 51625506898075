import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import BitaCalendarDate from '../../../components/BitaCalendar/BitaCalendarDate';
import { getDataComponents } from '../../../api-data-mapping/dataMap';
import axios from '../../../Axios';

export const BuilderCalendarDateComponent = props => {
  const [minDate, setMinDate] = useState(null);
  const [firstRevisioneUniversoSelected, setfirstRevisioneUniversoSelected] = useState(null);
  const apiState = useSelector(state => state.apiData.apiData);
  const listRiskModels = apiState?.risk_models?.data?.['Risk Model Bita'];
  const startDateParentIndex = useSelector(
    state => state?.backtest?.runBacktestForm?.parent_index_startdate,
  );
  const dataPortofolioBasket = useSelector(state => state?.summary?.Portfolio);
  const startDatePortofolioBasket = useSelector(
    state => state?.summary?.Portfolio?.PortfolioSelected?.first_date,
  );
  const idRiskModelsSelected = useSelector(
    state => state?.backtest?.runBacktestForm?.riskModelSelected,
  );
  // Start date del risk model default
  const risk_models_start_date = moment(listRiskModels?.[1]?.start_date).format('YYYY-MM-DD');
  // Array auxiliar de las fechas (risk models default start date y universe default first revision)
  const auxArrayDates = [
    moment(apiState.first_revision_universe_global_default || '2009-01-05'),
    moment(listRiskModels?.[1]?.start_date),
  ];
  const [firstRevison, setFirstRevision] = useState(
    moment(Math.max(...auxArrayDates)) || moment('2009-09-18'),
  );
  const [defaultEndDate, setDefaultEndDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const universeSelected = lodash.get(apiState, 'universeSelected.id', false);
  const token = useSelector(state => state.auth.token);
  const location = useLocation();
  const [startDateP, setStartDateP] = useState(null);

  // Se obtiene la first revision del universo selecionado y se compara con la start date del risk model por default
  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/end-date`;
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
      setDefaultEndDate(moment(res.data.data.config['end-date']));
      setEndDate(res.data.data.config['end-date']);
    });
    if (universeSelected) {
      const dataComponent = getDataComponents.filter(
        v => v.key === 'universeSelectedFirstRevision',
      )[0];
      const promise = dataComponent.getUrlData(universeSelected, token);
      promise.then(res => {
        if (res.data) {
          setfirstRevisioneUniversoSelected(res.data);
          setFirstRevision(moment(Math.max(...[moment(res.data), moment(risk_models_start_date)])));
        }
      });
    }
  }, []);

  const storedDate = lodash.get(props, ['stepData', 'Index Backtest', 'Start Date'], null);
  useEffect(() => {
    if (storedDate) {
      setMinDate(storedDate);
    }
  }, [storedDate]);

  useEffect(() => {
    if (idRiskModelsSelected && firstRevisioneUniversoSelected) {
      // debugger;
      const start_date_risk = listRiskModels?.find(aux => aux.id === idRiskModelsSelected?.data?.id)
        ?.start_date;
      setFirstRevision(
        moment(Math.max(...[moment(firstRevisioneUniversoSelected), moment(start_date_risk)])),
      );
    }
  }, [idRiskModelsSelected]);

  const date =
    (props.title === 'End Date' && endDate) ||
    (props.title !== 'Start Date' && minDate) ||
    firstRevison;

  useEffect(() => {
    if (props.onChange && !props.default_date) {
      props.onChange(props.title, date);
    }
    setMinDate(firstRevison);
  }, [firstRevison]);

  useEffect(() => {
    if (location.pathname.includes('portfolio-basket-calculation') && startDatePortofolioBasket) {
      setMinDate(startDatePortofolioBasket);
      setStartDateP(startDatePortofolioBasket);
    }
  }, [startDatePortofolioBasket]);

  useEffect(() => {
    if (location.pathname.includes('/optimized-index/optimized-index') && startDateParentIndex) {
      setMinDate(moment(startDateParentIndex));
    }
    setStartDateP(
      location.pathname.includes('/optimized-index/optimized-index') && startDateParentIndex
        ? moment(startDateParentIndex)
        : false,
    );
  }, [startDateParentIndex, location.pathname]);

  const onSelect = value => {
    if (props.onChange) props.onChange(props.title, value);
  };

  // if it's not a backtest popup calendar
  if (!(props.stepData && props.stepData['Index Backtest'])) {
    if (props.title === 'End Date') {
      return <BitaCalendarDate title={props.title} select={onSelect} date={defaultEndDate} />;
    }
    if (props.title === 'Determination Date') {
      return (
        <BitaCalendarDate title={props.title} select={onSelect} date={endDate} maxDate={endDate} />
      );
    }
    return <BitaCalendarDate title={props.title} select={onSelect} />;
  }
  if (props.title === 'Start Date' || props.default_date) {
    return (
      <BitaCalendarDate
        title={props.title}
        select={onSelect}
        date={
          location.pathname.includes('portfolio-basket-calculation') && startDatePortofolioBasket
            ? startDatePortofolioBasket
            : props.default_date || startDateP || firstRevison
        }
        minDate={
          location.pathname.includes('portfolio-basket-calculation') && startDatePortofolioBasket
            ? startDatePortofolioBasket
            : startDateP || firstRevison
        }
        maxDate={new Date()}
      />
    );
  }

  return (
    <BitaCalendarDate
      title={props.title}
      select={onSelect}
      date={props.default_date}
      minDate={minDate || firstRevison}
    />
  );
};
