/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as CommonActions from '../../../constants/common-constants';
// import HighchartsReact from 'highcharts-react-official';
import FileSaver from 'file-saver';
import axios from '../../../Axios';
import styles from './AttributionAnalyzer.module.scss';
import { fromArrayLike, getDateFromUTC } from '../../../utils/utils';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaSpinner from '../../BitaSpinner/BitaSpinner';
import BitaSelect from '../../BitaSelect/BitaSelect';
import BitaSearchBar from '../../../bitaComponents/BitaSearchBar/BitaSearchBar';
import RadioButtonTab from './lib/RadioButtonTab';
import ConfigYAML from '../../../config/ConfigYAML';
import { Placeholders } from '../../../constants/placeholders';
import { IconsModules } from '../../../constants/icons-module';
import { excludeUser } from '../../../constants/exclude-modules-user';
import { selectUniverse } from '../../../state/actions/universe-actions';
import { updateUniverse, updateMethodology } from '../../../state/actions/module-selection-actions';
import { setApiData } from '../../../state/actions/api-data-actions';
import RunBacktestModal from '../../../pages/runBacktestModal';
import RunRebalancingReconstitution from '../../../pages/runRebalancingReconstitution';
import ModalBasketCalculation from '../ModalBasketCalculation/ModalBasketCalculation';
import { versionIndexName, hasValidBitaDomain } from '../../../utils/utils';
import IndexDescription from '../IndexDescription';
import DownloadCenter from '../DownloadCenter';
import StyleFactor from './AttributionCharts/StyleFactorComponent';
import AttributionPerformance from './AttributionCharts/AttributionPerformance';
import { DownloadCenterModal } from '../../BitaModal/DownloadCenterModal';

const AttributionAnalyzer = ({ benchmarkList }) => {
  const apiData = useSelector(state => state.apiData);
  const [optionAttribution, setOptionAttribution] = useState([]);
  const [styleFactorDate, setStyleFactorDate] = useState('');
  const [indexStats, setIndexStats] = useState(null);
  const [pseudoTimeout, setPseudoTimeout] = useState(false);
  const [optionStyleFactor, setStyleFactor] = React.useState();
  const [riskModel, setRiskModel] = useState(11);
  const userData = useSelector(state => state.auth.userdata);
  const dispatch = useDispatch();
  const [pointInTime, setPointInTime] = useState('1Y');
  const backtestData = useSelector(state => state.backtest);
  const company = useSelector(state => state.auth.company);
  const [currentAttributionTab, setCurrentAttributionTab] = React.useState(
    'RISK FACTOR ATTRIBUTION',
  );
  const currentIndexSelected = useSelector(
    state => state.apiData.apiData['Current Index Selected'],
  );
  const index_list = _.get(apiData, ['apiData', 'Indexes', 'data'], []);
  const [dateLabel, setDateLabel] = useState('');
  const ModalBCRef = useRef();
  const modalRef = useRef();
  const ModalRRRef = useRef();

  const isShowActivateCalculation =
    hasValidBitaDomain(userData?.email) || userData?.company?.name === 'demo_company';

  const isRegularOrRebalancingIndex =
    currentIndexSelected?.data?.index_type === 'regular' ||
    currentIndexSelected?.data?.index_type === 'rebalancing_module';

  let ImageI;
  if (currentIndexSelected?.data?.index_type === 'regular') {
    ImageI = IconsModules.IndexModule;
  } else if (currentIndexSelected?.data?.index_type === 'optimized_index') {
    ImageI = IconsModules.OptimizedModule;
  } else if (currentIndexSelected?.data?.index_type === 'basket_index') {
    ImageI = IconsModules.PortfolioBasketModule;
  } else {
    ImageI = IconsModules.StructureIModule;
  }

  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  React.useEffect(() => {
    dispatch({
      type: CommonActions.SUBHEADER_UPDATE,
      payload:
        currentIndexSelected?.data?.index_type === 'rebalancing_module'
          ? 'ANALYZER · STYLE FACTOR TILTS'
          : 'ANALYZER · ATTRIBUTION',
    });
  }, [dispatch]);

  useEffect(() => {
    if (apiData.apiData && apiData.apiData['Index Stats']) {
      const stats = _.get(apiData, 'apiData[Index Stats].data', []);
      setIndexStats(stats);
    }
  }, [apiData.apiData['Index Stats']]);

  useEffect(() => {
    if (currentIndexSelected.data && currentIndexSelected.data.benchmark_id) {
      const usedBenchmark = benchmarkList.filter(
        bench => bench.id === currentIndexSelected.data.benchmark_id,
      );
      if (usedBenchmark.length > 0) setOptionAttribution(usedBenchmark[0]);
      const riskModelList = _.get(
        apiData,
        ['apiData', 'risk_models', 'data', 'Risk Model Bita'],
        [],
      );
      const risk = riskModelList.filter(r => r.name === currentIndexSelected.data.risk_name);
      if (risk.length > 0) {
        setRiskModel(risk[0]);
      }
    }
  }, [currentIndexSelected, benchmarkList]);

  useEffect(() => {
    setStyleFactorDate(optionStyleFactor);
  }, [optionStyleFactor]);

  const getMethodology = idMeth => {
    const allMethodologies = _.get(apiData, 'apiData.[Methodologies]', {});
    const selectedMeth = allMethodologies.data.filter(m => m.id === idMeth)[0];
    if (selectedMeth) {
      dispatch(updateMethodology(selectedMeth));
      dispatch(
        setApiData({
          title: 'methodologySelected',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
      dispatch(
        setApiData({
          title: 'methodologySelectedCard',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
    }
  };

  const getUniverse = idUniverse => {
    const allUniverses = _.get(apiData, 'apiData.[AllUniverses]', {});
    const selectedUni = allUniverses.data.filter(u => u.id === idUniverse)[0];
    if (selectedUni) {
      dispatch(selectUniverse(selectedUni));
      dispatch(updateUniverse(selectedUni));
      dispatch(
        setApiData({
          title: 'universeSelected',
          data: selectedUni,
        }),
      );
      dispatch(
        setApiData({
          title: 'universeSelectedCard',
          data: selectedUni,
        }),
      );
    }
  };

  const activeRunBackTest = () => {
    if (currentIndexSelected && currentIndexSelected.data) {
      const idUniverse = currentIndexSelected.data.universe_id;
      const idMethodology = currentIndexSelected.data.methodology_id;
      getUniverse(idUniverse);
      getMethodology(idMethodology);
      dispatch(
        setApiData({
          title: 'indexBacktest',
          data: {
            'Index Backtest': {
              'INDEX NAME': versionIndexName(currentIndexSelected.data.name, index_list),
              'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
              'Start Date': currentIndexSelected.data.start_date.split('T')[0],
              'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
              Benchmark: optionAttribution,
              risk_model_id: riskModel,
              RiskOption: riskModel,
            },
          },
        }),
      );
      modalRef.current.runModal();
    }
    return {};
  };

  const activeRebalanceReconstitution = () => {
    dispatch(
      setApiData({
        title: 'indexBacktest',
        data: {
          'Index Backtest': {
            'PORTFOLIO NAME': versionIndexName(currentIndexSelected.data.name, index_list),
            'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
            'Start Date': currentIndexSelected.data.start_date.split('T')[0],
            'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
            Benchmark: optionAttribution,
            risk_model_id: riskModel,
            RiskOption: riskModel,
          },
        },
      }),
    );
    const idUniverse = currentIndexSelected.data.universe_id;
    const idMethodology = currentIndexSelected.data.methodology_id;
    getUniverse(idUniverse);
    getMethodology(idMethodology);
    ModalRRRef.current.runModal();
  };

  const isSameAsBacktest = () => {
    if (
      backtestData &&
      currentIndexSelected &&
      currentIndexSelected.data &&
      backtestData.id === currentIndexSelected.data.id
    ) {
      return true;
    }
    return false;
  };

  const filesDownloadCenter = isSameAsBacktest()
    ? _.get(backtestData, 'pdfs', {
        status: 'loading',
      })
    : _.get(apiData, 'apiData.[Download Center]', {
        status: 'loading',
      });

  const timer = ConfigYAML.jsonPath(['analyzer', 'timeout_files']);
  const filesSpinnerMessage = ConfigYAML.jsonPath(['analyzer', 'timeout_text']);
  useEffect(() => {
    if (indexStats !== null) {
      if (typeof indexStats === 'object' && indexStats.length !== 0) {
        setTimeout(() => {
          setPseudoTimeout(true);
        }, timer);
      }
    }
  }, [indexStats]);

  const [styleFactorTiltsDates, setStyleFactorTiltsDates] = useState([]);

  useEffect(() => {
    const dateList = _.get(currentIndexSelected, 'data.dates_constitutions_real', {});
    if (dateList) {
      setStyleFactorTiltsDates(
        Object.entries(dateList)
          .slice()
          .reverse()
          .map(([key, date], i) => {
            return { id: i, value: key, date: date };
          }),
      );
    }
  }, [currentIndexSelected]);

  useEffect(() => {
    setStyleFactor(styleFactorTiltsDates[0]?.date || '');
    setDateLabel(styleFactorTiltsDates[0]?.value || '');
  }, [styleFactorTiltsDates]);

  const datesButtons = [
    { name: 'YTD', period: 'YTD' },
    { name: '1 Year', period: '1Y' },
    { name: '3 Years', period: '3Y' },
    { name: '5 Years', period: '5Y' },
    { name: 'Max', period: 'Max' },
  ];

  return (
    <div className={styles.base}>
      <RunBacktestModal ubication="analyzer" showData ref={modalRef} disable_title />
      <RunRebalancingReconstitution ubication="analyzer" showData ref={ModalRRRef} />
      <ModalBasketCalculation ubication="analyzer" showData ref={ModalBCRef} disable_title />
      <DownloadCenterModal />

      <div className={styles.contentContainer}>
        <div className={styles.firstHalfContainer}>
          <div className={styles.halfWidthContainer}>
            <div className={styles.baseInfoContainer}>
              <div className={styles.baseInfoTitleContainer}>
                <span className={styles.baseInfoTitle}>
                  <ImageI /> {currentIndexSelected.data?.name}
                </span>
              </div>
              <IndexDescription
                indexType={currentIndexSelected?.data?.index_type}
                universe={currentIndexSelected?.data?.universe || ''}
                methodology={currentIndexSelected?.data?.methodology || ''}
                start={currentIndexSelected?.data?.start_date?.split('T')[0] || ''}
                end={currentIndexSelected?.data?.end_date?.split('T')[0] || ''}
                author={currentIndexSelected?.data?.author || ''}
                benchmark={currentIndexSelected?.data?.benchmark || ''}
                risk={currentIndexSelected?.data?.risk_name || ''}
                parentIndex={currentIndexSelected?.data?.parent_index}
                frontParameters={currentIndexSelected?.data?.front_parameters || {}}
              />
              {currentIndexSelected?.data?.index_type === 'regular' && (
                <div
                  className={styles.benchmarkContainer}
                  style={{
                    marginTop:
                      currentIndexSelected?.data?.index_type === 'optimized_index'
                        ? '-15px'
                        : '0px',
                  }}
                >
                  <span className={styles.benchmarkTitle}>Select New Benchmark</span>
                  <div className={styles.benchmarkSearch}>
                    <div className={styles.benchmarkSearchDropdown}>
                      <BitaSearchBar
                        searchOptions={benchmarkList}
                        onChange={selected => {
                          setOptionAttribution(selected);
                        }}
                        value={optionAttribution.value}
                      />
                    </div>
                    <BitaButton
                      primaryWhite
                      onClick={activeRunBackTest}
                      disabled={currentIndexSelected?.data?.index_type === 'structured_index'}
                    >
                      Recalculate
                    </BitaButton>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.downloadContainer}>
              <span className={styles.downloadTitle}>Download Center</span>
              {(currentIndexSelected?.data?.index_type === 'rebalancing_module' ||
                filesDownloadCenter.status === 'done' ||
                filesDownloadCenter?.files) &&
              pseudoTimeout ? (
                <DownloadCenter
                  files={Object.assign(
                    {
                      constituents: currentIndexSelected?.data?.constituents_url,
                      machine_readable_constituents_url:
                        currentIndexSelected?.data?.machine_readable_constituents_url,
                      price: currentIndexSelected?.data?.price_series_url,
                      guidebook: currentIndexSelected?.data?.guidebook_url,
                    },
                    filesDownloadCenter.files,
                  )}
                  company={company}
                  indexType={currentIndexSelected?.data?.index_type}
                  frontParameters={currentIndexSelected?.data?.front_parameters || {}}
                />
              ) : (
                <div>
                  <BitaSpinner />
                  {filesSpinnerMessage}
                </div>
              )}
            </div>
          </div>
          <div className={styles.styleFactorContainer}>
            {isShowActivateCalculation && !isRegularOrRebalancingIndex && (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  top: '-85px',
                  justifyContent: 'flex-end',
                }}
              >
                <BitaButton
                  primaryWhite
                  style={{
                    whiteSpace: 'nowrap',
                    width: '200px',
                    marginRight: '10px',
                    padding: '5px',
                    display: excludeUser[userData?.company?.name] ? 'none' : '',
                  }}
                  onClick={() => {
                    ModalBCRef.current.runModal();
                  }}
                >
                  Activate in Calculation
                </BitaButton>
              </div>
            )}
            {isRegularOrRebalancingIndex && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '-27px',
                }}
              >
                {isShowActivateCalculation && (
                  <BitaButton
                    primaryWhite
                    style={{
                      whiteSpace: 'nowrap',
                      width: '200px',
                      marginRight: '10px',
                      padding: '5px',
                      top: '-58px',
                      display: excludeUser[userData?.company?.name] ? 'none' : '',
                    }}
                    onClick={() => {
                      ModalBCRef.current.runModal();
                    }}
                  >
                    Activate in Calculation
                  </BitaButton>
                )}
                <BitaButton
                  primaryWhite
                  style={{
                    width: '200px',
                    height: '27px',
                    position: 'relative',
                    top: '-58px',
                    right: '0px',
                  }}
                  onClick={activeRebalanceReconstitution}
                >
                  Rebalance Portfolio/Index
                </BitaButton>
                {currentIndexSelected?.data?.index_type !== 'rebalancing_module' && (
                  <BitaButton
                    primaryWhite
                    style={{
                      width: '25%',
                      height: '27px',
                      position: 'relative',
                      top: '-58px',
                      left: '20px',
                    }}
                    onClick={activeRunBackTest}
                  >
                    Update Index
                  </BitaButton>
                )}
              </div>
            )}
            <div className={styles.styleFactorTitleContainer}>
              <span className={styles.styleFactorTitle}>Style Factor Tilts</span>
              <div className={styles.styleFactorDropdownContainer}>
                {styleFactorTiltsDates && (
                  <BitaSelect
                    className={styles.dropdownFactorContainer}
                    data={styleFactorTiltsDates}
                    label={dateLabel}
                    handleSelection={selected => {
                      setStyleFactor(selected.date);
                      setDateLabel(selected.value);
                    }}
                    selected={dateLabel}
                  />
                )}
              </div>
            </div>
            <div className={styles.styleFactorTableContainer}>
              <StyleFactor styleFactorDate={styleFactorDate} />
            </div>
          </div>
        </div>
        {currentIndexSelected?.data?.index_type !== 'rebalancing_module' && (
          <div className={styles.dateContainer}>
            {datesButtons.map(date => (
              <BitaButton
                {...(date.period === pointInTime ? { primary: true } : { primaryWhite: true })}
                onClick={() => setPointInTime(date.period)}
              >
                {date.name}
              </BitaButton>
            ))}
          </div>
        )}
        {currentIndexSelected?.data?.index_type !== 'rebalancing_module' && (
          <div className={styles.bottomHalfContainer}>
            <div className={styles.sectorAndWeightsContainer}>
              <div className={styles.performanceButtonsContainer}>
                <div className={styles.performanceTabs}>
                  <button
                    onClick={() => setCurrentAttributionTab('RISK FACTOR ATTRIBUTION')}
                    className={classNames(
                      currentAttributionTab === 'RISK FACTOR ATTRIBUTION'
                        ? styles.tabStyleActive
                        : styles.tabStyle,
                    )}
                  >
                    {'Risk factor attribution'.toUpperCase()}
                  </button>
                  <button
                    onClick={() => setCurrentAttributionTab('PERFORMANCE FACTOR ATTRIBUTION')}
                    className={classNames(
                      currentAttributionTab === 'PERFORMANCE FACTOR ATTRIBUTION'
                        ? styles.tabStyleActive
                        : styles.tabStyle,
                    )}
                  >
                    {'Performance Factor attribution'.toUpperCase()}
                  </button>
                </div>
              </div>
              <RadioButtonTab
                currentTab={currentAttributionTab}
                indexStats={indexStats}
                period={pointInTime}
              />
            </div>

            <div className={styles.performanceStatsContainer}>
              <div className={styles.performanceTitleContainer}>
                <span className={styles.performanceTitle}>Performance Attribution (GICS)</span>
              </div>
              <div className={styles.performanceChartContainer}>
                <div className={styles.performanceChart}>
                  <AttributionPerformance pointInTime={pointInTime} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AttributionAnalyzer;
