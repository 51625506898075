/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect, useRef } from 'react';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { fromJS } from 'immutable';
import { useParams } from 'react-router';
import { versionIndexName, hasValidBitaDomain } from '../../../utils/utils';

import styles from './EmissionsWaste.module.scss';
import '../AnalyzerComponents.scss';
import BitaSelect from '../../BitaSelect/BitaSelect';
import BitaCharts from '../../../bitaComponents/BitaCharts/BitaCharts';
import BarGraphWithDropdown from './charts/BarGraphWithDropdown';
import RunRebalancingReconstitution from '../../../pages/runRebalancingReconstitution';
import ModalBasketCalculation from '../ModalBasketCalculation/ModalBasketCalculation';

import LinearChartWithDropdown from './charts/LinearChartWithDropdown';
import TableIndexBenchmarkByYear from './charts/TableIndexBenchmarkByYear';
import ConfigYAML from '../../../config/ConfigYAML';
import TopConstituentTable from './charts/TopConstituentTable';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaSpinner from '../../BitaSpinner/BitaSpinner';
import BitaSearchBar from '../../../bitaComponents/BitaSearchBar/BitaSearchBar';
import { selectUniverse } from '../../../state/actions/universe-actions';
import { updateUniverse, updateMethodology } from '../../../state/actions/module-selection-actions';
import { getIndexBenchmark } from '../../../state/actions/benchmark-actions';
import * as Action from '../../../state/actions/api-data-actions';
import RunBacktestModal from '../../../pages/runBacktestModal';
import { DownloadCenterModal } from '../../BitaModal/DownloadCenterModal';
import { excludeUser } from '../../../constants/exclude-modules-user';
import { IconsModules } from '../../../constants/icons-module';
import IndexDescription from '../IndexDescription';
import DownloadCenter from '../DownloadCenter';
import MultipleTypeChart from './charts/MultipleTypeChart';
import MetricTonChar from './charts/MetricTonChar';

const yearDropdownOptions = [
  { id: '1Y', value: '1-Year' },
  { id: '3Y', value: '3-Year' },
];

const wasteGrowthOptions = [
  { id: 'totalRecycled', value: 'Total Recycled' },
  { id: 'totalWaste', value: 'Total Waste' },
  { id: 'waste', value: 'Waste' },
];

const PerformanceAndRisk = ({ benchmarkList }) => {
  const [indexStats, setIndexStats] = useState(fromJS({}));
  const [pseudoTimeout, setPseudoTimeout] = useState(false);
  const [benchmarkStats, setBenchmarkStats] = useState(fromJS({}));
  const [lastYearDateList, setLastYearDateList] = useState([]);
  const [contributorsList, setContributorsList] = useState({});
  const [contributorsDate, setConstributorDate] = useState({});
  const [wasteGrowthData, setWasteGrowth] = useState({});
  const [wasteGrowthYear, setWasteGrothYear] = useState(yearDropdownOptions[0]);
  const [wasteGeneration, setWasteGeneration] = useState({});
  const [dataCoEq, setDataCoEq] = useState({});
  const [wasteIntensity, setWasteIntensity] = useState({});
  const optionsBaseDefault = benchmarkList || [];
  const userData = useSelector(state => state.auth.userdata);
  const [riskModel, setRiskModel] = useState(11);
  const [optionsBase, setOptionsBase] = React.useState(optionsBaseDefault[0]);
  const apiData = useSelector(state => state.apiData);
  const company = useSelector(state => state.auth.company);
  const backtestData = useSelector(state => state.backtest);
  const [indexBenchmark, setIndexBenchmark] = useState();
  const [normalizedWaste, setNormalizedWaste] = useState({});
  const [normalizedType, setNormalizedType] = useState('zscore');
  const [recyclingType, setRecyclingType] = useState('zscore');
  const [normalizedRecycling, setNormalizedRecycling] = useState({});
  const [recyclingRatio, setRecyclingRatio] = useState({});
  const [totalRecycled, setTotalRecycled] = useState({});
  const [selectedTimeFrameIntensity, setSelectedTimeFrameIntensity] = useState(
    yearDropdownOptions[0],
  );

  const [selectedIndexBenchmark, setSelectedIndexBenchmark] = useState({});

  const dispatch = useDispatch();
  const ModalBCRef = useRef();
  const modalRef = useRef();
  const ModalRRRef = useRef();
  const index_list = _.get(apiData, ['apiData', 'Indexes', 'data'], []);
  const currentIndexSelected = useSelector(
    state => state.apiData.apiData['Current Index Selected'],
  );
  const token = useSelector(state => state.auth.token);

  const { indexId } = useParams();

  const isShowActivateCalculation =
    hasValidBitaDomain(userData?.email) || userData?.company?.name === 'demo_company';
  const isRegularOrRebalancingIndex =
    currentIndexSelected?.data?.index_type === 'regular' ||
    currentIndexSelected?.data?.index_type === 'rebalancing_module';
  const roundValues = arr => {
    const tempArr = arr;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < tempArr.length; i++) {
      if (tempArr[i].value !== null) {
        tempArr[i] = {
          timestamp: tempArr[i].timestamp,
          value: Math.round(tempArr[i].value),
        };
      }
    }
    return tempArr;
  };

  useEffect(() => {
    if (apiData.apiData && apiData.apiData['Index Stats']) {
      setIndexStats(fromJS(apiData.apiData['Index Stats']));
    }
  }, [apiData.apiData['Index Stats']]);

  useEffect(() => {
    if (apiData.apiData && apiData.apiData['Benchmark Card Stats']) {
      setBenchmarkStats(fromJS(apiData.apiData['Benchmark Card Stats']));
    }
  }, [apiData.apiData['Benchmark Card Stats']]);

  useEffect(() => {
    // Generate Year Dropdown options
    const dateList = _.get(currentIndexSelected, ['data', 'dates_constitutions_real'], false);
    if (dateList) {
      const yearList = [];
      Object.values(dateList)
        .filter(ddate => new Date(ddate) >= new Date('2014-01-01'))
        .forEach(dcr => {
          const year = new Date(dcr).getFullYear();
          if (yearList.filter(yl => yl.id === year).length === 0) {
            yearList.push({ id: year, value: year });
          }
        });
      yearList.sort((a, b) => Number(b.value) - Number(a.value));
      setLastYearDateList(yearList);
      setConstributorDate(yearList[0]);
      setSelectedIndexBenchmark(yearList[0]);
    }
  }, [currentIndexSelected]);

  let ImageI;
  if (currentIndexSelected?.data?.index_type === 'regular') {
    ImageI = IconsModules.IndexModule;
  } else if (currentIndexSelected?.data?.index_type === 'optimized_index') {
    ImageI = IconsModules.OptimizedModule;
  } else if (currentIndexSelected?.data?.index_type === 'basket_index') {
    ImageI = IconsModules.PortfolioBasketModule;
  } else {
    ImageI = IconsModules.StructureIModule;
  }

  useEffect(() => {
    if (currentIndexSelected.data && currentIndexSelected.data.benchmark_id) {
      setIndexBenchmark(currentIndexSelected.data.benchmark_id);

      const usedBenchmark = optionsBaseDefault.filter(
        bench => bench.id === currentIndexSelected.data.benchmark_id,
      );
      if (usedBenchmark.length > 0) setOptionsBase(usedBenchmark[0]);
      const riskModelList = _.get(
        apiData,
        ['apiData', 'risk_models', 'data', 'Risk Model Bita'],
        [],
      );
      const risk = riskModelList.filter(r => r.name === currentIndexSelected.data.risk_name);
      if (risk.length > 0) {
        setRiskModel(risk[0]);
      }
    }
  }, [currentIndexSelected, benchmarkList]);

  useEffect(() => {
    if (indexId && indexBenchmark) {
      getIndexBenchmark(indexId, indexBenchmark, token).then(resp => {
        dispatch(
          Action.setApiData({
            title: 'Benchmark Card Stats',
            data: resp.data,
          }),
        );
      });
    }
  }, [indexBenchmark]);

  useEffect(() => {
    if (indexId) {
      dispatch(Action.setCurrentIndex(token, indexId));
      dispatch(Action.setIndexStats(token, indexId));
    }
  }, [indexId]);

  useEffect(() => {
    const constributorData = indexStats.getIn(['data', '103', 'value'])?.toJS();
    if (constributorData) {
      const contibutorObject = {};
      const assets = _.get(currentIndexSelected, ['data', 'assets_descriptors'], {});
      constributorData.forEach(tc => {
        const year = new Date(tc.timestamp).getFullYear();

        const yearValues = Object.entries(tc.value).map(([isin, values]) => {
          return {
            'Security Name': assets[isin]?.name || '',
            waste_intensity: Number(values['Carbon Intensity']?.toFixed(4)) || '0',
            'Relative Intensity': (Number(values['Relative Intensity']) * 100).toFixed(2),
          };
        });
        yearValues.sort((a, b) => Number(b.waste_intensity) - Number(a.waste_intensity));
        Object.assign(contibutorObject, { [year]: yearValues });
      });
      setContributorsList(contibutorObject);
    }
  }, [indexStats]);

  useEffect(() => {
    // Effect to prepare data Waste Growth
    if (!_.isEmpty(indexStats?.toJS() || {}) && benchmarkStats) {
      const _wasteGrowth = {
        '1Y': {
          'Total Waste': { ...indexStats.getIn(['data', '206', 'value'])?.toJS() } || {},
          'Total Waste - Benchmark':
            { ...benchmarkStats.getIn(['data', '206', 'value'])?.toJS() } || {},
          'Total Recycled': { ...indexStats.getIn(['data', '210', 'value'])?.toJS() } || {},
          'Total Recycled - Benchmark':
            { ...benchmarkStats.getIn(['data', '210', 'value'])?.toJS() } || {},
          Hazardous: { ...indexStats.getIn(['data', '207', 'value'])?.toJS() } || {},
          'Non-Hazardous': { ...indexStats.getIn(['data', '208', 'value'])?.toJS() } || {},
          'Waste Disposal': { ...indexStats.getIn(['data', '209', 'value'])?.toJS() } || {},
        },
        '3Y': {
          Hazardous: { ...indexStats.getIn(['data', '213', 'value'])?.toJS() } || {},
          'Non-Hazardous': { ...indexStats.getIn(['data', '214', 'value'])?.toJS() } || {},
          'Waste Disposal': { ...indexStats.getIn(['data', '215', 'value'])?.toJS() } || {},
          'Total Waste': { ...indexStats.getIn(['data', '212', 'value'])?.toJS() } || {},
          'Total Waste - Benchmark':
            { ...benchmarkStats.getIn(['data', '212', 'value'])?.toJS() } || {},
          'Total Recycled': { ...indexStats.getIn(['data', '216', 'value'])?.toJS() } || {},
          'Total Recycled - Benchmark':
            { ...benchmarkStats.getIn(['data', '216', 'value'])?.toJS() } || {},
        },
      };
      setWasteGrowth(_wasteGrowth);
    }
  }, [indexStats, benchmarkStats]);

  useEffect(() => {
    // Effect to prepare data Waste Intensity Growth Chart
    if (indexStats && benchmarkStats) {
      const _wasteIntensity = {
        '1Y': {
          Hazardous: { ...indexStats.getIn(['data', '247', 'value'])?.toJS() } || {},
          'Non-Hazardous': { ...indexStats.getIn(['data', '249', 'value'])?.toJS() } || {},
          'Total Waste': { ...indexStats.getIn(['data', '245', 'value'])?.toJS() } || {},
          // 'Waste Disposal': { ...indexStats.getIn(['data', '221', 'value'])?.toJS() } || {},
          // 'Total Recycled': { ...indexStats.getIn(['data', '222', 'value'])?.toJS() } || {},
        },
        '3Y': {
          Hazardous: { ...indexStats.getIn(['data', '248', 'value'])?.toJS() } || {},
          'Non-Hazardous': { ...indexStats.getIn(['data', '250', 'value'])?.toJS() } || {},
          'Total Waste': { ...indexStats.getIn(['data', '246', 'value'])?.toJS() } || {},
          // 'Waste Disposal': { ...indexStats.getIn(['data', '226', 'value'])?.toJS() } || {},
          // 'Total Recycled': { ...indexStats.getIn(['data', '227', 'value'])?.toJS() } || {},
        },
      };
      setWasteIntensity(_wasteIntensity);
    }
  }, [indexStats]);

  useEffect(() => {
    // Effect to prepare data Sector Normalized Waste Intensity
    if (indexStats) {
      const _normalizedIntensity = {
        zscore: {
          Hazardous: { ...indexStats.getIn(['data', '229', 'value'])?.toJS() } || {},
          'Non-Hazardous': { ...indexStats.getIn(['data', '230', 'value'])?.toJS() } || {},
          'Total Waste': { ...indexStats.getIn(['data', '228', 'value'])?.toJS() } || {},
          'Waste Disposal': { ...indexStats.getIn(['data', '231', 'value'])?.toJS() } || {},
          'Total Recycled': { ...indexStats.getIn(['data', '232', 'value'])?.toJS() } || {},
        },
        growth: {
          Hazardous: { ...indexStats.getIn(['data', '234', 'value'])?.toJS() } || {},
          'Non-Hazardous': { ...indexStats.getIn(['data', '235', 'value'])?.toJS() } || {},
          'Total Waste': { ...indexStats.getIn(['data', '233', 'value'])?.toJS() } || {},
          'Waste Disposal': { ...indexStats.getIn(['data', '236', 'value'])?.toJS() } || {},
          'Total Recycled': { ...indexStats.getIn(['data', '237', 'value'])?.toJS() } || {},
        },
      };
      setNormalizedWaste(_normalizedIntensity);
    }
  }, [indexStats]);

  useEffect(() => {
    // Effect to prepare data Sector Normalized Recycling Ratio
    if (indexStats) {
      const _normalizedRecycling = {
        zscore: {
          'Recycling Ratio': { ...indexStats.getIn(['data', '238', 'value'])?.toJS() } || {},
        },
        growth: {
          'Recycling Ratio': { ...indexStats.getIn(['data', '239', 'value'])?.toJS() } || {},
        },
      };
      setNormalizedRecycling(_normalizedRecycling);
    }
  }, [indexStats]);

  useEffect(() => {
    // Effect to prepare data Waste Generation
    if (indexStats) {
      const _wasteGeneration = {
        index: {
          Hazardous: { ...indexStats.getIn(['data', '241', 'value'])?.toJS() } || {},
          'Non-Hazardous': { ...indexStats.getIn(['data', '242', 'value'])?.toJS() } || {},
          'Total Waste *': { ...indexStats.getIn(['data', '240', 'value'])?.toJS() } || {},
        },
        benchmark: {
          Hazardous: { ...benchmarkStats.getIn(['data', '241', 'value'])?.toJS() } || {},
          'Non-Hazardous': { ...benchmarkStats.getIn(['data', '242', 'value'])?.toJS() } || {},
          'Total Waste *': { ...benchmarkStats.getIn(['data', '240', 'value'])?.toJS() } || {},
        },
      };
      setWasteGeneration(_wasteGeneration);
    }
  }, [indexStats]);

  useEffect(() => {
    let total_waste;
    let total_recycled;
    if (indexStats) {
      if (
        indexStats.getIn(['data', '199', 'value']) !== undefined &&
        indexStats.getIn(['data', '200', 'value']) !== undefined
      ) {
        total_waste = roundValues(indexStats.getIn(['data', '199', 'value']).toJS());
        total_recycled = roundValues(indexStats.getIn(['data', '200', 'value']).toJS());
      }
      const _recyclingRatio = {
        'Total Waste': { ...total_waste } || {},
        'Total Recycled': { ...total_recycled } || {},
        'Recycling Ratio* (RHS)': { ...indexStats.getIn(['data', '201', 'value'])?.toJS() } || {},
      };
      setRecyclingRatio(_recyclingRatio);
    }
  }, [indexStats]);

  useEffect(() => {
    if (indexStats) {
      const _totalRecycled = {
        'Waste-to-Energy': { ...indexStats.getIn(['data', '205', 'value'])?.toJS() } || {},
        Composting: { ...indexStats.getIn(['data', '204', 'value'])?.toJS() } || {},
        Reused: { ...indexStats.getIn(['data', '203', 'value'])?.toJS() } || {},
        // Recycled: { ...indexStats.getIn(['data', '202', 'value'])?.toJS() } || {},
      };
      setTotalRecycled(_totalRecycled);
    }
  }, [indexStats]);

  const buildObjectCoTwoEQ = (valuesArray, subcategory, accumulativeObject) => {
    valuesArray.forEach(tc => {
      const year = new Date(tc.timestamp).getFullYear();
      if (accumulativeObject[year]) {
        const auxObj = accumulativeObject[year];
        Object.assign(auxObj, { [subcategory]: tc.value });
        accumulativeObject[year] = auxObj;
      } else {
        Object.assign(accumulativeObject, { [year]: { [subcategory]: tc.value } });
      }
    });
    return accumulativeObject;
  };

  useEffect(() => {
    // Table Waste Generation
    const totalWaste = indexStats.getIn(['data', '240', 'value'])?.toJS();
    const totalHazarous = indexStats.getIn(['data', '241', 'value'])?.toJS();
    const totalNonHazarous = indexStats.getIn(['data', '242', 'value'])?.toJS();
    const benchmarkWaste = benchmarkStats.getIn(['data', '240', 'value'])?.toJS();
    const benchmarkHazarous = benchmarkStats.getIn(['data', '241', 'value'])?.toJS();
    const benchmarkNonHazarous = benchmarkStats.getIn(['data', '242', 'value'])?.toJS();
    let coTwoEq = {};
    if (totalHazarous) {
      coTwoEq = buildObjectCoTwoEQ(totalHazarous, 'INDEX HAZARDOUS', coTwoEq);
    }
    if (totalNonHazarous) {
      coTwoEq = buildObjectCoTwoEQ(totalNonHazarous, 'INDEX NON-HAZARDOUS', coTwoEq);
    }
    if (totalWaste) {
      coTwoEq = buildObjectCoTwoEQ(totalWaste, 'INDEX WASTE', coTwoEq);
    }
    if (benchmarkHazarous) {
      coTwoEq = buildObjectCoTwoEQ(benchmarkHazarous, 'BENCHMARK HAZARDOUS', coTwoEq);
    }
    if (benchmarkNonHazarous) {
      coTwoEq = buildObjectCoTwoEQ(benchmarkNonHazarous, 'BENCHMARK NON-HAZARDOUS', coTwoEq);
    }
    if (benchmarkWaste) {
      coTwoEq = buildObjectCoTwoEQ(benchmarkWaste, 'BENCHMARK WASTE', coTwoEq);
    }
    setDataCoEq(coTwoEq);
  }, [indexStats, benchmarkStats]);

  const isSameAsBacktest = () => {
    if (
      backtestData &&
      currentIndexSelected &&
      currentIndexSelected.data &&
      backtestData.id === currentIndexSelected.data.id
    ) {
      return true;
    }
    return false;
  };

  const filesDownloadCenter = isSameAsBacktest()
    ? _.get(backtestData, 'pdfs', {
        status: 'loading',
      })
    : _.get(apiData, 'apiData.[Download Center]', {
        status: 'loading',
      });

  const timer = ConfigYAML.jsonPath(['analyzer', 'timeout_files']);
  const filesSpinnerMessage = ConfigYAML.jsonPath(['analyzer', 'timeout_text']);
  useEffect(() => {
    if (indexStats.size > 0) {
      setTimeout(() => {
        setPseudoTimeout(true);
      }, timer);
    }
  }, [indexStats]);

  const getMethodology = idMeth => {
    const allMethodologies = _.get(apiData, 'apiData.[Methodologies]', {});
    const selectedMeth = allMethodologies.data.filter(m => m.id === idMeth)[0];
    if (selectedMeth) {
      dispatch(updateMethodology(selectedMeth));
      dispatch(
        Action.setApiData({
          title: 'methodologySelected',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
      dispatch(
        Action.setApiData({
          title: 'methodologySelectedCard',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
    }
  };

  const getUniverse = idUniverse => {
    const allUniverses = _.get(apiData, 'apiData.[AllUniverses]', {});
    const selectedUni = allUniverses.data.filter(u => u.id === idUniverse)[0];
    if (selectedUni) {
      dispatch(selectUniverse(selectedUni));
      dispatch(updateUniverse(selectedUni));
      dispatch(
        Action.setApiData({
          title: 'universeSelected',
          data: selectedUni,
        }),
      );
      dispatch(
        Action.setApiData({
          title: 'universeSelectedCard',
          data: selectedUni,
        }),
      );
    }
  };

  const activeRunBackTest = () => {
    if (currentIndexSelected && currentIndexSelected.data) {
      const idUniverse = currentIndexSelected.data.universe_id;
      const idMethodology = currentIndexSelected.data.methodology_id;
      getUniverse(idUniverse);
      getMethodology(idMethodology);
      dispatch(
        Action.setApiData({
          title: 'indexBacktest',
          data: {
            'Index Backtest': {
              'INDEX NAME': versionIndexName(currentIndexSelected.data.name, index_list),
              'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
              'Start Date': currentIndexSelected.data.start_date.split('T')[0],
              'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
              Benchmark: optionsBase,
              risk_model_id: riskModel,
              RiskOption: riskModel,
            },
          },
        }),
      );
      modalRef.current.runModal();
    }
    return {};
  };

  const activeRebalanceReconstitution = () => {
    dispatch(
      Action.setApiData({
        title: 'indexBacktest',
        data: {
          'Index Backtest': {
            'PORTFOLIO NAME': versionIndexName(currentIndexSelected.data.name, index_list),
            'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
            'Start Date': currentIndexSelected.data.start_date.split('T')[0],
            'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
            Benchmark: optionsBase,
            risk_model_id: riskModel,
            RiskOption: riskModel,
          },
        },
      }),
    );
    const idUniverse = currentIndexSelected.data.universe_id;
    const idMethodology = currentIndexSelected.data.methodology_id;
    getUniverse(idUniverse);
    getMethodology(idMethodology);
    ModalRRRef.current.runModal();
  };

  const getIndexName = () => {
    if (currentIndexSelected && currentIndexSelected.data) {
      return currentIndexSelected.data.name;
    }
    return '';
  };

  const styleContent = {
    width: 'calc(100% - 5px)',
    display: 'flex',
    alignItems: 'center',
    margin: '5px 10px 5px 0px',
    borderRadius: '5px',
    backgroundColor: '#3b3f44',
    height: '60px',
  };

  return (
    <div className={styles.base}>
      <RunBacktestModal ubication="analyzer" showData ref={modalRef} disable_title />
      <ModalBasketCalculation ubication="analyzer" showData ref={ModalBCRef} disable_title />
      <RunRebalancingReconstitution ubication="analyzer" showData ref={ModalRRRef} disable_title />
      <DownloadCenterModal />
      <div className={styles.contentContainer}>
        <div className={styles.firstHalfContainer}>
          <div className={styles.halfWidthContainer}>
            <div className={styles.baseInfoContainer} style={{ marginRight: '0px', width: '100%' }}>
              <div className={styles.baseInfoTitleContainer}>
                <span className={styles.baseInfoTitle}>
                  {' '}
                  <ImageI /> {getIndexName()}
                </span>
              </div>
              <IndexDescription
                indexType={currentIndexSelected?.data?.index_type}
                universe={currentIndexSelected?.data?.universe || ''}
                methodology={currentIndexSelected?.data?.methodology || ''}
                start={currentIndexSelected?.data?.start_date.split('T')[0] || ''}
                end={currentIndexSelected?.data?.end_date?.split('T')[0] || ''}
                author={currentIndexSelected?.data?.author || ''}
                benchmark={currentIndexSelected?.data?.benchmark || ''}
                risk={currentIndexSelected?.data?.risk_name || ''}
                parentIndex={currentIndexSelected?.data?.parent_index}
                frontParameters={currentIndexSelected?.data?.front_parameters || {}}
              />
              {currentIndexSelected?.data?.index_type === 'regular' && (
                <div
                  className={styles.benchmarkContainer}
                  style={{
                    marginTop:
                      currentIndexSelected?.data?.index_type === 'optimized_index'
                        ? '-15px'
                        : '0px',
                  }}
                >
                  <span className={styles.benchmarkTitle}>Select New Benchmark</span>
                  <div className={styles.benchmarkSearch}>
                    <div className={styles.benchmarkSearchDropdown}>
                      <BitaSearchBar
                        searchOptions={optionsBaseDefault}
                        onChange={selected => {
                          setOptionsBase(selected);
                        }}
                        value={optionsBase?.value}
                      />
                    </div>
                    <BitaButton
                      primaryWhite
                      onClick={activeRunBackTest}
                      disabled={currentIndexSelected?.data?.index_type === 'structured_index'}
                    >
                      Recalculate
                    </BitaButton>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.downloadContainer} style={{ marginRight: '0px', width: '100%' }}>
              <span className={styles.downloadTitle}>Download Center</span>
              {(filesDownloadCenter.status === 'done' || filesDownloadCenter?.files) &&
              pseudoTimeout ? (
                <DownloadCenter
                  files={Object.assign(
                    {
                      constituents: currentIndexSelected?.data?.constituents_url,
                      machine_readable_constituents_url:
                        currentIndexSelected?.data?.machine_readable_constituents_url,
                      machine_readable_constituents_summary_url:
                        currentIndexSelected?.data?.machine_readable_constituents_summary_url,
                      price: currentIndexSelected?.data?.price_series_url,
                      guidebook: currentIndexSelected?.data?.guidebook_url,
                    },
                    filesDownloadCenter.files,
                  )}
                  company={company}
                  indexType={currentIndexSelected?.data?.index_type}
                  frontParameters={currentIndexSelected?.data?.front_parameters || {}}
                />
              ) : (
                <div>
                  <BitaSpinner />
                  {filesSpinnerMessage}
                </div>
              )}
            </div>
          </div>
          <div className={styles.generalStatsContainer} style={{ margin: '3px 10px 0px 30px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                justifyContent: 'space-between',
              }}
            >
              <span
                className={styles.styleFactorTitle}
                style={{ fontSize: '14px', fontWeight: 'bold' }}
              >
                WASTE INTENSITY
              </span>
              {isShowActivateCalculation && !isRegularOrRebalancingIndex && (
                <div
                  style={{
                    position: 'relative',
                    top: isShowActivateCalculation ? '-93px' : '-85px',
                    left: '20px',
                    display: 'flex',
                  }}
                >
                  <BitaButton
                    primaryWhite
                    style={{
                      whiteSpace: 'nowrap',
                      width: '200px',
                      marginRight: '10px',
                      padding: '5px',
                      display: excludeUser[userData?.company?.name] ? 'none' : '',
                    }}
                    onClick={() => {
                      ModalBCRef.current.runModal();
                    }}
                  >
                    Activate in Calculation
                  </BitaButton>
                </div>
              )}
              {isRegularOrRebalancingIndex && (
                <div
                  style={{
                    position: 'relative',
                    top: isShowActivateCalculation ? '-100px' : '-85px',
                    left: '20px',
                    display: 'flex',
                  }}
                >
                  {isShowActivateCalculation && (
                    <BitaButton
                      primaryWhite
                      style={{
                        whiteSpace: 'nowrap',
                        width: '200px',
                        marginRight: '10px',
                        padding: '5px',
                        display: excludeUser[userData?.company?.name] ? 'none' : '',
                      }}
                      onClick={() => {
                        ModalBCRef.current.runModal();
                      }}
                    >
                      Activate in Calculation
                    </BitaButton>
                  )}
                  <BitaButton
                    primaryWhite
                    style={{ width: '200px', marginRight: '10px' }}
                    onClick={activeRebalanceReconstitution}
                  >
                    Rebalance Portfolio/Index
                  </BitaButton>
                </div>
              )}
            </div>
            <div className={styles.tableContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '10px',
                }}
              >
                <span
                  className={styles.styleFactorTitle}
                  style={{ fontSize: '14px', fontWeight: 'bold' }}
                >
                  Waste Intensity Index vs. Benchmark
                </span>
                <BitaSelect
                  data={lastYearDateList}
                  value={selectedIndexBenchmark}
                  clearable={false}
                  handleSelection={selected => {
                    setSelectedIndexBenchmark(selected);
                  }}
                  width="140px"
                />
              </div>
              <TableIndexBenchmarkByYear
                data={dataCoEq}
                columns={[
                  currentIndexSelected?.data?.index_type === 'rebalancing_module'
                    ? 'Portfolio'
                    : 'Index',
                  'Benchmark',
                ]}
                year={selectedIndexBenchmark.value}
                rows={['HAZARDOUS', 'NON-HAZARDOUS', 'TOTAL WASTE']}
              />
            </div>
            <LinearChartWithDropdown
              title="Waste Intensity Evolution Graph"
              stats={wasteGeneration}
              dropdownOptions={[
                { id: 'Hazardous', value: 'Hazardous' },
                { id: 'Non-Hazardous', value: 'Non-Hazardous' },
                { id: 'Total Waste *', value: 'Total Waste *' },
              ]}
              hideTitleDropdown
              tableTitle="Index Historical Waste Intensity"
              yAxisLabel=""
              chartName="generation"
              disclaimer="*Companies comprised in the database sometimes don’t provide the Hazardous and
              Non-Hazardous data, meaning that Total Waste sum might not be exact or might include
              more data categories."
            />
          </div>
        </div>
        <div
          className={styles.halfWidthContainer2}
          style={{ width: '100%', height: '50%', display: 'flex', marginTop: '10px' }}
        >
          <div
            className={classNames(styles.halfWidthContainer, styles.generalStatsContainer)}
            style={{ margin: '3px 10px', width: 'calc(50% - 20px)', justifyContent: 'flex-start' }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span
                className={styles.styleFactorTitle}
                style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '15px' }}
              >
                TOP 10 MOST WASTE INTENSE HOLDINGS
              </span>
              <BitaSelect
                data={lastYearDateList}
                value={contributorsDate}
                clearable={false}
                handleSelection={selected => {
                  setConstributorDate(selected);
                }}
                width="140px"
                style={{ marginBottom: '20px' }}
              />
            </div>

            <TopConstituentTable
              data={contributorsList}
              year={contributorsDate.value}
              labels={['Security Name', 'waste_intensity', 'Relative Intensity']}
            />
            <span style={{ fontSize: '8px' }}>*Metric Tons per USD 1M of Revenue.</span>
          </div>
          <div className={styles.generalStatsContainer} style={{ margin: '3px 10px 3px 10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <span
                className={styles.styleFactorTitle}
                style={{ fontSize: '14px', fontWeight: 'bold' }}
              >
                RECYCLING RATIO
              </span>
            </div>
            <MultipleTypeChart
              title="Historical Recycling Ratio"
              stats={recyclingRatio}
              tableTitle="Index Historical Recycling Ratio"
            />
          </div>
        </div>
        <div
          className={styles.halfWidthContainer2}
          style={{ width: '100%', height: '50%', display: 'flex', paddingRight: '10px' }}
        >
          <div
            className={styles.generalStatsContainer}
            style={{ margin: '3px 0px 3px 10px', width: '100%' }}
          >
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <span
                className={styles.styleFactorTitle}
                style={{ fontSize: '14px', fontWeight: 'bold' }}
              >
                TOTAL RECYCLED BREAKDOWN (IN METRIC TONS)
              </span>
            </div>
            <MetricTonChar
              title="Historical Breakdown"
              stats={totalRecycled}
              tableTitle="Index Historical Breakdown"
            />
          </div>
        </div>

        <div
          className={styles.halfWidthContainer2}
          style={{ width: '100%', height: '50%', display: 'flex' }}
        >
          {/*  
          <div
            className={classNames(styles.halfWidthContainer, styles.generalStatsContainer)}
            style={{ margin: '3px 10px', width: 'calc(50% - 20px)', justifyContent: 'flex-start' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                justifyContent: 'space-between',
              }}
            >
              <span
                className={styles.styleFactorTitle}
                style={{ fontSize: '14px', fontWeight: 'bold' }}
              >
                WASTE GROWTH 
              </span>
              <BitaSelect
                data={yearDropdownOptions}
                value={wasteGrowthYear}
                clearable={false}
                handleSelection={selected => {
                  setWasteGrothYear(selected);
                }}
              />
            </div>
            <BarGraphWithDropdown
              title="Performance Graph (%)"
              stats={wasteGrowthData}
              timeframe={wasteGrowthYear.id}
              tableTitle="Annual Compounded Rate"
              dropdownOptions={wasteGrowthOptions}
              dropdownDefault={2}
              dropdownClearable={false}
            />
          </div>
           */}
          <div
            className={styles.generalStatsContainer}
            style={{ margin: '3px 10px 3px 10px', width: 'calc(100% - 20px)' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                justifyContent: 'space-between',
              }}
            >
              <span
                className={styles.styleFactorTitle}
                style={{ fontSize: '14px', fontWeight: 'bold' }}
              >
                WASTE INTENSITY GROWTH RATE
              </span>
              <BitaSelect
                data={yearDropdownOptions}
                value={selectedTimeFrameIntensity}
                clearable={false}
                handleSelection={selected => {
                  setSelectedTimeFrameIntensity(selected);
                }}
              />
            </div>
            <LinearChartWithDropdown
              title="Compound Annual Growth Rate"
              stats={wasteIntensity}
              hideDropdown
              timeframe={selectedTimeFrameIntensity.id}
              tableTitle={`Index Compound Annual Growth Rate (${selectedTimeFrameIntensity.value.replace(
                ' ',
                '-',
              )})`}
              yAxisLabel="%"
              chartName="generation"
            />
          </div>
        </div>
        {/*  
        <div
          className={styles.halfWidthContainer2}
          style={{ width: '100%', height: '50%', display: 'flex' }}
        >
          <div
            className={styles.halfWidthContainer}
            style={{
              width: 'calc(50% - 20px)',
              margin: '3px 10px',
              padding: '20px',
              borderRadius: '5px',
              backgroundColor: '#3b3f44',
              justifyContent: 'flex-start',
            }}
          >
            <div style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 'bold' }}>
              SECTOR NORMALIZED RECYCLING RATIO 
              <div style={styleContent}>
                <BitaButton
                  primaryWhite={recyclingType === 'growth'}
                  primary={recyclingType === 'zscore'}
                  onClick={() => setRecyclingType('zscore')}
                  style={{
                    background: recyclingType === 'growth' ? '#4b5158' : '',
                    color: recyclingType === 'growth' ? '#9a9a9a' : '',
                    marginRight: '10px',
                    width: '50%',
                    textTransform: 'uppercase',
                  }}
                >
                  Z-Score
                </BitaButton>
                <BitaButton
                  primaryWhite={recyclingType === 'zscore'}
                  primary={recyclingType === 'growth'}
                  onClick={() => setRecyclingType('growth')}
                  style={{
                    background: recyclingType === 'zscore' ? '#4b5158' : '',
                    color: recyclingType === 'zscore' ? '#9a9a9a' : '',
                    width: '50%',
                    textTransform: 'uppercase',
                  }}
                >
                  Growth %
                </BitaButton>
              </div>
              <LinearChartWithDropdown
                title={recyclingType === 'zscore' ? 'Stats (Z-Score)' : 'Annual Performance'}
                stats={normalizedRecycling}
                timeframe={recyclingType}
                yAxisLabel={recyclingType === 'zscore' ? '' : '%'}
                hideDropdown
                tableTitle="Annual Compounded Rate"
              />
            </div>
          </div>

          <div
            className={styles.halfWidthContainer}
            style={{
              width: 'calc(50% - 20px)',
              margin: '3px 10px',
              padding: '20px',
              borderRadius: '5px',
              backgroundColor: '#3b3f44',
              justifyContent: 'flex-start',
            }}
          >
            <div style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 'bold' }}>
              SECTOR NORMALIZED WASTE INTENSITY 
              <div style={styleContent}>
                <BitaButton
                  primaryWhite={normalizedType === 'growth'}
                  primary={normalizedType === 'zscore'}
                  onClick={() => setNormalizedType('zscore')}
                  style={{
                    background: normalizedType === 'growth' ? '#4b5158' : '',
                    color: normalizedType === 'growth' ? '#9a9a9a' : '',
                    marginRight: '10px',
                    width: '50%',
                    textTransform: 'uppercase',
                  }}
                >
                  Z-Score
                </BitaButton>
                <BitaButton
                  primaryWhite={normalizedType === 'zscore'}
                  primary={normalizedType === 'growth'}
                  onClick={() => setNormalizedType('growth')}
                  style={{
                    background: normalizedType === 'zscore' ? '#4b5158' : '',
                    color: normalizedType === 'zscore' ? '#9a9a9a' : '',
                    width: '50%',
                    textTransform: 'uppercase',
                  }}
                >
                  Growth %
                </BitaButton>
              </div>
              <LinearChartWithDropdown
                title={normalizedType === 'zscore' ? 'Stats (Z-Score)' : 'Annual Performance'}
                stats={normalizedWaste}
                timeframe={normalizedType}
                yAxisLabel={normalizedType === 'zscore' ? '' : '%'}
                hideDropdown
                tableTitle="Annual Compounded Rate"
              />
            </div>
          </div>
        </div>
          */}
      </div>
    </div>
  );
};
export default PerformanceAndRisk;
