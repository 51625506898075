import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import { Progress } from 'react-sweet-progress';
import Papa from 'papaparse';

import FileSaver from 'file-saver';
import { FaCheckSquare } from 'react-icons/fa';
import { BiSolidError, BiX, BiCheck } from 'react-icons/bi';
import BitaCheckbox from '../../components/BitaCheckbox/BitaCheckbox';
import DragAndDropFile from '../../components/DragAndDropFiles/DragAndDropFile';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import BitaSelect from '../../components/BitaSelect/BitaSelect';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import SaveSuccessBasketModal from './Modals/SaveSuccessBasketModal';
import { toastInterceptor } from '../../utils/utils';
import { BULK_BASKET_DATA } from './utils/dataDummie';
import Table from './Components/EditTable';
import { ReactComponent as Logo } from '../../static/img/Icons/BITA.svg';

import { DATA_LIST_DROPDOWN, BASKET_BUTTON_BULK, CURRENCIES_ARRAY } from './utils/constants';
import { createBasket, uploadFileSettings, coverageRequest } from './utils/api';
import { BulkPrevieBaskets } from './Components/BulkComponents';
import BitaTable from '../../bitaComponents/BitaTable/BitaTable';
import styles from './index-builder.module.css';
import style from './PortfolioBasketCalculation.module.scss';
import BitaSearchBar from '../../bitaComponents/BitaSearchBar/BitaSearchBar';
import './Portfolio.css';

const BASKETS_TABS_DATA = {
  BDELUT: {},
  BDELUN: {},
  BDELUP: {},
  BDELET: {},
};

const styleClose = {
  position: 'absolute',
  fontSize: '30px',
  fontWeight: 'bold',
  top: '-10px',
  right: '0',
  cursor: 'pointer',
  padding: '10px',
  boxSizing: 'border-box',
};

const INITIAL_PAGINATION = {
  pageIndex: 0,
  pageSize: 10,
};

const CALCULATION_ZONE_MAPPING = {
  us_only: 'US / Eastern',
  us_europe: 'US / Europe',
  global: 'Global',
  europe_only: 'Europe only',
};

function truncateString(str) {
  if (str.length > 80) {
    return `${str.substring(0, 80)}...`;
  }
  return str;
}

// TODO Function to format date to yyyy-mm-dd (improve this because is the same function of UpdateBasket)
const formatDateString = dateString => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

const validatedFieldsValues = {
  currency: CURRENCIES_ARRAY,
  calculation_setup: ['EOD', 'EOD_INTRADAY'],
  calculation_zone: ['us_only', 'us_europe', 'global', 'europe_only'],
  deployment_setup: ['EOD', 'IMMEDIATELY'],
  calculation_type: ['NET_RETURN', 'TOTAL_RETURN', 'PRICE_RETURN'],
  cash_distribution: [
    'DISTRIBUTION_ACROSS_BASKET',
    'DISTRIBUTION_ON_PAYING_SECURITY',
    'ADD_DISTRIBUTION_TO_CASH',
  ],
  application_point: ['AT_MARKET_CLOSE', 'AT_MARKET_OPEN'],
  dissemination: ['BITA Only'],
};

function isValidDateString(dateString) {
  // Check if the date string matches the format yyyy-mm-dd
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  // Parse the date string into a Date object
  const date = new Date(`${dateString}T00:00:00`); // Treat as local midnight
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today's date to local midnight

  // Check if the date is today or in the future
  return date >= today;
}

const BulkBasketSubmission = () => {
  // const ListBasket = Object.keys(BASKETS_TABS_DATA).map(key => {
  //   return { label: key, value: key };
  // });
  const user = useSelector(state => state.auth);
  const token = useSelector(state => state.auth.token);
  const authOrgPrefix = useSelector(state => state.auth?.org_prefix);
  const [type, setType] = useState('Long only Basket');
  const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalConfirmBacktest, setShowModalConfirmBacktest] = useState(false);
  const [showModalBasketInProgress, setShowModalBasketInProgress] = useState(false);
  const [showConfirmSubmittingBasketModal, setShowConfirmSubmittingBasketModal] = useState(false);
  const [showBasketsTabs, setShowBasketsTabs] = useState(false);
  const [showMessageRepeat, setShowMessageRepeat] = useState(false);
  const [showModalRequestCoverageSuccessful, setShowModalRequestCoverageSuccessful] = useState(
    false,
  );
  const [dataArray, setDataArray] = useState([]); // These are the baskets
  const [dataArrayRepeat, setDataArrayRepeat] = useState([]);
  const [dataSendBasket, setDataSendBasket] = useState([]);
  const [listFiles, setListFiles] = useState([]);
  // const { CSVReader } = useCSVReader();
  const [constituentsDelete, setConstituentsDelete] = useState([]);
  const [files, setFiles] = React.useState('');
  const [filesBacktest, setFilesBacktest] = React.useState('');
  const [dataBacktest, setDataBacktest] = useState([]);
  const [showModalRequestCoverage, setShowModalRequestCoverage] = useState(false);
  const [selectOptionPreview, setSelectOptionPreview] = useState();
  const [orgPrefix, setOrgPrefix] = useState(authOrgPrefix || '');
  const [errorReferenceData, setErrorReferenceData] = useState(false);
  const [listMIC, setListMIC] = useState([]);
  const [successCount, setSuccessCount] = useState(0);
  const [failureCount, setFailureCount] = useState(0);
  const [isLoadingSubmittingBaskets, setIsLoadingSubmittingBaskets] = useState(false);
  const [pagination, setPagination] = useState(INITIAL_PAGINATION);
  // const [totalBaskets, setTotalBaskets] = useState(0);

  const onDrop = useCallback(acceptedFiles => {
    const allowedFiles = acceptedFiles.filter(
      file =>
        file.type === 'text/csv' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
    setListFiles(allowedFiles);
  }, []);

  const ListBasket = useMemo(() => {
    return (dataArray.length > 0 ? dataArray : data?.meta_reference_data ? [data] : []).map(
      item => ({
        label: item?.symbol ?? 'label',
        // value: item?.id ?? item?.name,
        value: item?.symbol,
      }),
    );
  }, [dataArray, data]);

  const filesList = () => {
    if (listFiles?.length > 3) {
      return (
        <div style={{ overflowWrap: 'break-word' }}>
          {listFiles
            .slice(0, 3)
            .map(item => `${item?.name?.replace('.csv', '').replace('.excel', '')}, `)}{' '}
          and {listFiles.length - 3} more ...
        </div>
      );
    }
    return <div>{listFiles.map(item => `${item.name}, `)}</div>;
  };
  const BacktestTable = () => {
    const renderTable = () => {
      const columns = Object.keys(data?.dataBacktest?.[0]);
      const rows = data?.dataBacktest.slice(1);

      return (
        <>
          <div style={{ border: 'none', marginBottom: '0px' }}>Backtest Preview</div>
          <BitaTable
            data={rows}
            search={false}
            showFields={columns}
            height="300px"
            showOnly={10}
            pagination
            selectAction={() => {}}
            CustomRowComponent={({ value }) => (
              <div>
                <p style={{ paddingRight: '20px' }}>{value}</p>
              </div>
            )}
          />
        </>
      );
    };
    return <div className={style.contentWidget}>{renderTable()}</div>;
  };

  // eslint-disable-next-line no-shadow
  const updateAndFormatData = (data, shouldShowToast) => {
    if (data?.divisor && data?.starting_value) {
      const { starting_value: _, ...updatedData } = data;
      setData({ ...updatedData });
      if (shouldShowToast) toast('Your file has been processed successfully');
    } else if (!data?.divisor && !data?.starting_value) {
      toast.error(
        'Error: Invalid input file. Please make sure you have "divisor" or "starting_value" field and try again.',
      );
    } else {
      setData(data);
      if (shouldShowToast) toast('Your file has been processed successfully');
    }
    setPagination(INITIAL_PAGINATION);
  };

  useEffect(() => {
    if (showMessageRepeat && dataArrayRepeat.length > 0) {
      const updateData = [...dataArray].filter(item => !dataArrayRepeat.includes(item.symbol));
      const showDataRepeat = [...new Set(dataArrayRepeat)];
      setDataArray(updateData);
      if (updateData.length > 0) {
        updateAndFormatData(updateData[0], true);
        setSelectOptionPreview({ label: updateData[0]?.symbol, value: updateData[0]?.symbol });
      }
      setShowMessageRepeat(false);
      toast.error(
        `The following symbol(s) are repeated in one or more of the uploaded files: ${showDataRepeat.join(
          ', ',
        )}`,
      );
    }
    if (showMessageRepeat && dataArrayRepeat.length === 0) {
      updateAndFormatData(dataArray[0], true);
      setSelectOptionPreview({ label: dataArray[0]?.symbol, value: dataArray[0]?.symbol });
      setShowMessageRepeat(false);
    }
  }, [showMessageRepeat]);

  const handleFileParse = (listBasket, basketSelect) => {
    Papa.parse(filesBacktest, {
      complete: results => {
        if (
          results.data.length > 1 &&
          results.data[0][0].toUpperCase() === 'DATE' &&
          results.data[0][1].toUpperCase() === 'VALUE'
        ) {
          setDataBacktest(results.data);
          basketSelect.dataBacktest = results.data;
          basketSelect.fileBacktest = filesBacktest;
          const updatedArray = listBasket.map(item =>
            item.symbol === selectOptionPreview.value ? basketSelect : item,
          );
          setDataArray(updatedArray);
          setData(basketSelect);
          toast(`Uploaded backtest is linked to ${basketSelect.symbol} Basket`);
        } else {
          toast.error(
            'Invalid file type structure. Please upload a file that matches the required format',
          );
        }
      },
      header: false,
    });
  };

  const sendBasket = async dataBasket => {
    const formData = new FormData();
    const updatedData = {
      ...dataBasket,
      deployment_setup:
        data?.calculation_setup !== 'EOD_INTRADAY' || dataBasket.deployment_setup === 'EOD'
          ? 'PREVIOUS_EOD'
          : dataBasket.deployment_setup,
      metadata: dataBasket?.meta_reference_data,
      launch_date: dataBasket?.launch_date,
      type: 'basket',
      // type: type.includes('Bottom') ? 'global' : type.toLowerCase().replace('/', '_'),
    }; // Add the 'type' property to the data object

    delete updatedData.region;
    delete updatedData.request_coverage;
    delete updatedData.full_coverage;
    delete updatedData.org_prefix;
    delete updatedData.dataBacktest;
    delete updatedData.fileBacktest;
    if (dataBasket?.calculation_setup !== 'EOD_INTRADAY') {
      updatedData.calculation_setup = 'EOD';
    }
    if (dataBasket?.cash_constituent === null) {
      delete dataBasket?.cash_constituent;
    }
    const jsonString = JSON.stringify(updatedData);

    formData.append('data', jsonString);
    formData.append('org_prefix', orgPrefix);

    if (dataBasket.fileBacktest) {
      formData.append('template', dataBasket.fileBacktest);
    }

    try {
      const rsp = await createBasket(token, formData);

      if (rsp.status === 200) {
        setDataSendBasket(prev => [...prev, { symbol: dataBasket.symbol, send: 'success' }]);
      } else {
        setDataSendBasket(prev => [...prev, { symbol: dataBasket.symbol, send: 'fail' }]);
      }
    } catch (error) {
      setDataSendBasket(prev => [...prev, { symbol: dataBasket.symbol, send: 'fail' }]);
    }
  };

  const processBaskets = async () => {
    setIsLoadingSubmittingBaskets(true);
    setShowModalBasketInProgress(true);
    setSuccessCount(0); // Reset success baskets count
    setFailureCount(0); // Reset failure baskets count
    setDataSendBasket([]); // Reset the dataSendBasket array
    const batchSize = 1;

    const processBatch = async batch => {
      await Promise.all(batch.map(dataBasket => sendBasket(dataBasket)));
    };

    const processAllBatches = async () => {
      await dataArray.reduce(async (previousPromise, _, index) => {
        await previousPromise;

        const batch = dataArray.slice(index, index + batchSize);
        await processBatch(batch);

        await new Promise(resolve => setTimeout(resolve, 1000));

        return Promise.resolve();
      }, Promise.resolve());
    };

    try {
      await processAllBatches();
    } catch (error) {
      console.error('Error processing baskets:', error);
    } finally {
      setIsLoadingSubmittingBaskets(false);

      // Use a callback to ensure we're working with the latest state
      setDataSendBasket(prevDataSendBasket => {
        const successfulBaskets = prevDataSendBasket.filter(item => item.send === 'success');
        const failedBaskets = prevDataSendBasket.filter(item => item.send === 'fail');

        const localSuccessCount = successfulBaskets.length;
        const localFailureCount = failedBaskets.length;

        setSuccessCount(localSuccessCount);
        setFailureCount(localFailureCount);

        return prevDataSendBasket; // Return the current state to avoid unnecessary re-render
      });
    }
  };

  const cleanState = () => {
    setShowModalBasketInProgress(false);
    setShowConfirmSubmittingBasketModal(false);
    setDataArray([]);
    setDataArrayRepeat([]);
    setDataSendBasket([]);
    setData({});
    setListFiles([]);

    setSuccessCount(0);
    setFailureCount(0);
  };

  const BasketTable = ({
    titleBasketTable,
    deleteBasketTable,
    buttonsBasketTable,
    basketsData,
    scroll,
  }) => {
    const renderTable = () => {
      if (data) {
        const dataRows = data?.meta_reference_data ?? data.metadata ?? [];
        const columns = Object.keys(dataRows?.[0] ?? {});
        return (
          <div className="contentTableBasketPreview">
            {scroll ? (
              <Table
                rows={dataRows}
                setMetadata={setData}
                listMic={listMIC}
                setConstituentsDelete={setConstituentsDelete}
                constituentsDelete={constituentsDelete}
                setErrorReferenceData={setErrorReferenceData}
                pagination={pagination}
                setPagination={setPagination}
              />
            ) : (
              <BitaTable
                id="basket"
                data={dataRows ?? []}
                height="100%"
                pagination
                style={{ padding: '10px !important' }} // here
                search={false}
                scroll={!scroll}
                showFields={deleteBasketTable ? [...(columns ?? [])] : columns ?? []}
                selectAction={() => {}}
                editButton={false}
                CustomRowComponent={({ data: dataValue, entryKey, value }) => (
                  <div
                    className="custom-row-container"
                    style={{ width: entryKey === 'ticker' ? '100px' : '' }}
                  >
                    <p className={`${entryKey === 'type' ? 'info-icon-live' : ''}`}>
                      {entryKey === 'type' && deleteBasketTable ? (
                        value === 'with_coverage' ? (
                          <FaCheckSquare className="icon-info" />
                        ) : (
                          <BiSolidError className="icon-error" />
                        )
                      ) : entryKey === 'isin' && deleteBasketTable ? (
                        <div style={{ display: 'flex' }}>
                          <BitaCheckbox
                            checked={constituentsDelete
                              .map(item => item?.isin ?? item?.sedol)
                              .includes(dataValue?.isin ?? dataValue?.sedol)}
                            handleChange={checked => {
                              if (checked) {
                                setConstituentsDelete([
                                  ...constituentsDelete,
                                  dataValue,
                                  // dataValue?.isin ?? dataValue?.sedol,
                                ]);
                              } else {
                                setConstituentsDelete(
                                  constituentsDelete.filter(
                                    item =>
                                      (item?.isin ?? item?.sedol) !==
                                      (dataValue?.isin ?? dataValue?.sedol),
                                  ),
                                );
                              }
                            }}
                          />
                          {value}
                        </div>
                      ) : (
                        value ?? '-'
                      )}
                    </p>
                    {entryKey === 'type' && value !== 'with_coverage' && (
                      <div className="tooltip error-tooltip">
                        <div>
                          <BiX className="tooltip-icon icon-error" />
                        </div>
                        <span>
                          This instrument is not under the BITA Calculation platform pricing
                          coverage. You can request the coverage using the button below &quot;
                          Request Coverage &quot;. We will receive the request, process it, and
                          communicate with you via e-mail as soon as possible.
                        </span>
                      </div>
                    )}
                    {entryKey === 'type' && value === 'with_coverage' && (
                      <div className="tooltip success-tooltip">
                        <div>
                          <BiCheck className="tooltip-icon icon-info" />
                        </div>
                        <span>The input has been successfully validated</span>
                      </div>
                    )}
                  </div>
                )}
                // eslint-disable-next-line no-shadow
              />
            )}
          </div>
        );
      }

      // Hide this for now
      // if (type === 'Bottom-Up Basket' && data.children_symbols) {
      //   const rows = data.children_symbols.map(symbol => ({
      //     children_symbols: symbol,
      //   }));

      //   return (
      //     <>
      //       <div style={{ border: 'none', marginBottom: '0px' }}>Baskets</div>
      //       <BitaTable
      //         data={rows}
      //         search={false}
      //         height="300px"
      //         showFields={['children_symbols']}
      //         selectAction={() => {}}
      //         CustomRowComponent={({ value }) => (
      //           <div>
      //             <p>{value}</p>
      //           </div>
      //         )}
      //       />
      //     </>
      //   );
      // }

      // Hide this for now
      // if (type === 'Long/Short' && data.global_baskets) {
      //   const rows = Object.values(data.global_baskets).map(symbol => ({
      //     basket_symbol: symbol,
      //   }));

      //   return (
      //     <>
      //       <div style={{ border: 'none', marginBottom: '0px' }}>Baskets</div>

      //       <BitaTable
      //         data={rows}
      //         search={false}
      //         height="300px"
      //         showFields={['basket_symbol']}
      //         selectAction={() => {}}
      //         CustomRowComponent={({ value }) => (
      //           <div>
      //             <p>{value}</p>
      //           </div>
      //         )}
      //       />
      //     </>
      //   );
      // }

      if (type === 'Decrement Index') {
        return <></>;
      }
      return null;
    };

    return <div className={!titleBasketTable ? '' : style.contentWidget}>{renderTable()}</div>;
  };

  const renderTopDescription = () => {
    if (isLoadingSubmittingBaskets) {
      return (
        <div style={{ margin: '20px', textAlign: 'center' }}>
          <div>Submitting baskets is currently in progress.</div>
          <div>You can follow the progress below.</div>
        </div>
      );
    }

    // All success
    if (successCount > 0 && failureCount === 0) {
      return (
        <div style={{ margin: '20px', textAlign: 'center' }}>
          <div>All baskets have been submitted successfully.</div>
          <div>The status of each submitted basket is as follows.</div>
        </div>
      );
    }

    if (failureCount > 0 && successCount === 0) {
      // All error
      return (
        <div style={{ margin: '20px', textAlign: 'center' }}>
          <div>Error: No baskets were submitted.</div>
          <div>Please check your data and try again.</div>
        </div>
      );
    }

    // Some success and some errors
    return (
      <div style={{ margin: '20px', textAlign: 'center' }}>
        <div>{`${successCount}/${dataSendBasket.length} Baskets were submitted successfully.`}</div>
        <div>{`For remaining Baskets (${failureCount}/${dataSendBasket.length}), please review the provided data and try again.`}</div>
      </div>
    );
  };

  const validateFields = (key, field) => {
    if (!key || !field) return false;
    const acceptedValues = validatedFieldsValues[key];
    return !!acceptedValues?.includes(field);
  };

  // Validation for divisor and starting value to be greater than 0
  const divisorAndStartingValueValidations =
    !dataArray.filter(
      itemData => itemData?.starting_value <= 0 || itemData?.meta_reference_data?.divisor <= 0,
    ).length > 0;

  const existRequestCoverage = dataBasket => {
    let coverage = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < dataBasket?.meta_reference_data?.length; i++) {
      if (dataBasket?.meta_reference_data[i]?.type !== 'with_coverage') {
        coverage = true;
        break;
      }
    }
    return coverage;
  };

  // Validation for request coverage length to be zero
  const requestCoverageAndMetadataValidation =
    !dataArray.filter(itemData => existRequestCoverage(itemData)).length > 0;

  // Validation for launch date to be greater than today's date
  const launchDateValidation =
    !dataArray.filter(itemData => !isValidDateString(itemData?.launch_date)).length > 0;

  // Validation for all required fields to be valid
  const fieldsValidations =
    !dataArray.filter(
      itemData =>
        !(
          itemData?.meta_reference_data &&
          validateFields('cash_distribution', itemData?.cash_distribution) &&
          validateFields('application_point', itemData?.application_point) &&
          validateFields('calculation_type', itemData?.calculation_type)
        ),
    ).length > 0;

  const hasMetadataExists =
    !dataArray.filter(itemData => data?.meta_reference_data?.length === 0).length > 0;

  const applyButtonValidations =
    divisorAndStartingValueValidations &&
    launchDateValidation &&
    hasMetadataExists &&
    requestCoverageAndMetadataValidation &&
    fieldsValidations;

  return (
    <div>
      <div className={styles.compositionPorfolio}>
        <div className={styles.compositionPorfolioTitle}>Basket Composition/Portfolio</div>
        <div className={style.contentDataUpload}>
          <div className={style.dataUpload}>
            <div>
              <div className={style.subtitle}>BASKET TYPE</div>
              <div style={{ marginTop: '10px' }}>
                <BitaSelect
                  warningIcon
                  selected={type}
                  value={{ value: type, id: type, name: type }}
                  label="level"
                  data={DATA_LIST_DROPDOWN}
                  handleSelection={value => setType(value.value)}
                  style={{ fontSize: 13 }}
                />
              </div>
            </div>
            <div className={style.contentTemplate}>
              <div className={style.subtitle}>TEMPLATES</div>
              <div style={{ display: 'flex', gap: '20px', marginTop: '10px', flexWrap: 'wrap' }}>
                {Object.values(BASKET_BUTTON_BULK[type] ?? {}).map(button => (
                  <BitaButton
                    primaryWhite
                    width="160px"
                    height="35px"
                    onClick={() => {
                      FileSaver.saveAs(
                        `${process.env.PUBLIC_URL}/files/${button?.url}.csv`,
                        `${button?.url}.csv`,
                      );
                    }}
                  >
                    {button?.label}
                  </BitaButton>
                ))}
              </div>
            </div>
            <div>
              <div className={style.subtitle}>Portfolio Uploader</div>
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  alignItems: 'center',
                  gap: '20px',
                  flexWrap: 'wrap',
                }}
              >
                <input
                  type="file"
                  onChange={event => {
                    const newFiles = event.target.files;
                    if (newFiles instanceof FileList) {
                      delete newFiles.length;
                    }
                    onDrop(Object.values(newFiles));
                  }}
                  name="file"
                  id="file"
                  multiple
                  className={style.inputfile}
                />
                <label htmlFor="file">Select File</label>
                <BitaButton
                  disabled={!(listFiles && listFiles.length > 0)}
                  onClick={() => {
                    setDataArray([]);
                    setDataArrayRepeat([]);
                    setData({});

                    if (!listFiles || listFiles.length === 0) return;

                    const uploadPromises = listFiles.map((file, index) => {
                      const formData = new FormData();
                      formData.append('template', file);

                      if (type === 'Long only Basket') {
                        formData.append('type', 'basket');
                      } else {
                        formData.append('type', type.replace('/', '_'));
                      }

                      return uploadFileSettings(token, formData)
                        .then(rsp => {
                          if (rsp.data.data.symbol) {
                            setDataArray(prevDataArray => {
                              if (
                                prevDataArray.find(item => item.symbol === rsp?.data?.data?.symbol)
                                  ?.symbol
                              ) {
                                setDataArrayRepeat(prev => [...prev, rsp?.data?.data?.symbol]);
                              }
                              const updatedArray = [
                                { ...rsp.data.data, id: index },
                                ...(prevDataArray ?? []),
                              ];
                              return updatedArray;
                            });
                            setShowBasketsTabs(true);
                          } else {
                            toast.error(
                              `The file ${file?.name} could not be loaded because it does not have a Symbol`,
                            );
                          }
                        })
                        .catch(error => {
                          console.info(error);
                          toastInterceptor(error);
                        });
                    });

                    Promise.all(uploadPromises)
                      .then(() => {
                        // setDataArrayRepeat([]);
                        setShowMessageRepeat(true);
                      })
                      .catch(error => {
                        console.error('Error en alguna de las llamadas a la API:', error);
                      });
                  }}
                  primary
                  width="160px"
                  height="35px"
                >
                  Upload File
                </BitaButton>
                <div style={{ width: '100%', maxWidth: '360px' }}>
                  <DragAndDropFile onChange={onDrop} />
                  <div style={{ fontSize: '16px', marginTop: '10px', color: '#a7a7aa' }}>
                    {listFiles && listFiles?.length > 0 && filesList()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: '0px 20px' }}>
            {data?.meta_reference_data && (
              <div
                className="contentTableBasketPreview"
                style={{ marginTop: '0px', paddingTop: '0px' }}
              >
                <div className="headerTable">
                  <div style={{ border: 'none', marginBottom: '0px' }}>Basket Data Preview</div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h1>General file information section</h1>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                  >
                    <div style={{ marginBottom: '10px', paddingRight: '20px' }}>
                      SELECTED BASKET
                    </div>
                    <div style={{ width: '200px' }}>
                      <BitaSearchBar
                        searchOptions={ListBasket}
                        style={{ with: '200px', float: 'right' }}
                        value={selectOptionPreview?.label}
                        onChange={value => {
                          setSelectOptionPreview(value);
                          if (dataArray.length > 0) {
                            updateAndFormatData(
                              dataArray.find(item => item.symbol === value?.value),
                              false,
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                {data && (
                  <div data-simplebar style={{ height: '360px' }}>
                    <div>
                      <Table
                        basket={selectOptionPreview?.label}
                        rows={data.meta_reference_data}
                        listMic={listMIC}
                        setMetadata={setData}
                        constituentsDelete={constituentsDelete}
                        setConstituentsDelete={value => {
                          if (constituentsDelete.find(item => item.isin === value.isin)) {
                            setConstituentsDelete(prev => {
                              return prev.filter(item => item.isin !== value.isin);
                            });
                          } else {
                            setConstituentsDelete(prev => {
                              return [...prev, value];
                            });
                          }
                        }}
                        allDelete={value => {
                          setConstituentsDelete(value);
                        }}
                        setErrorReferenceData={setErrorReferenceData}
                      />
                    </div>
                  </div>
                )}
                {data && data.meta_reference_data && (
                  <BitaButton
                    disabled={
                      data.meta_reference_data.filter(item => item.type !== 'with_coverage')
                        .length === 0
                    }
                    primary
                    width="160px"
                    style={{ marginRight: 'auto', marginTop: '20px' }}
                    height="35px"
                    onClick={() => setShowModalRequestCoverage(true)}
                  >
                    Request Coverage
                  </BitaButton>
                )}

                {data && (
                  <BitaButton
                    // disabled
                    disabled={constituentsDelete.length === 0}
                    primary
                    width="160px"
                    style={{ marginLeft: '20px', marginTop: '20px' }}
                    height="35px"
                    onClick={() => {
                      setShowModalDelete(true);
                    }}
                  >
                    Delete Constituent
                  </BitaButton>
                )}
              </div>
            )}
          </div>
          {/* <div className="content-tabs-bulksbasket">
            {data?.metadata && (
              <BasketTable
                titleBasketTable
                deleteBasketTable
                buttonsBasketTable
                ListBasket={ListBasket}
                basketsData={BULK_BASKET_DATA}
              />
            )}

            {/*  {showBasketsTabs && (
              <BulkPrevieBaskets ListBasket={ListBasket} basketsData={BULK_BASKET_DATA} />
            )} 
          </div> */}
        </div>
      </div>

      {/* Remove this for now */}
      <div className={styles.compositionPorfolio}>
        <div className={styles.compositionPorfolioTitle}>Backtest</div>
        <div className={style.contentDataUpload}>
          <div className={style.dataUpload}>
            <div>
              <div>
                <div className={style.subtitle}>TEMPLATES</div>
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    marginTop: '10px',
                    marginBottom: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <BitaButton
                    primaryWhite
                    width="160px"
                    height="35px"
                    onClick={() => {
                      FileSaver.saveAs(
                        `${process.env.PUBLIC_URL}/files/backtest_template.csv`,
                        `backtest_template.csv`,
                      );
                    }}
                  >
                    Backtest Template
                  </BitaButton>
                </div>
              </div>
              <div className={style.subtitle}>Backtest Uploader</div>
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  pointerEvents: files?.name || listFiles?.length > 0 ? 'auto' : 'none',
                  opacity: files?.name || listFiles?.length > 0 ? 1 : 0.5,
                }}
              >
                <div style={{ marginBottom: '10px' }}>
                  <input
                    type="file"
                    accept=".csv"
                    onChange={event => {
                      const file = event.target.files[0];
                      setFilesBacktest(file);
                      // setDataBacktest({});
                    }}
                    style={{ display: 'none' }}
                    id="file-upload"
                  />
                  <label htmlFor="file-upload" className={styles.buttonUpload}>
                    Select File
                  </label>
                  <div style={styles.acceptedFile} />
                </div>
                <BitaButton
                  disabled={!filesBacktest?.name}
                  width="160px"
                  onClick={() => {
                    const listBasket = [...dataArray];
                    const basketSelect = {
                      ...listBasket.find(item => item.symbol === selectOptionPreview.value),
                    };
                    if (basketSelect?.dataBacktest?.length > 2) {
                      setShowModalConfirmBacktest(true);
                    } else {
                      handleFileParse(listBasket, basketSelect);
                      // Papa.parse(filesBacktest, {
                      //   complete: results => {
                      //     if (
                      //       results.data.length > 1 &&
                      //       results.data[0][0].toUpperCase() === 'DATE' &&
                      //       results.data[0][1].toUpperCase() === 'VALUE'
                      //     ) {
                      //       setDataBacktest(results.data);
                      //       basketSelect.dataBacktest = results.data
                      //       const updatedArray = listBasket.map(item =>
                      //         item.id === selectOptionPreview.value ? basketSelect: item
                      //       );
                      //       setDataArray(updatedArray);
                      //       setData(basketSelect);
                      //       toast(`Uploaded backtest is linked to ${basketSelect.symbol} Basket`)
                      //     } else {
                      //       toast.error(
                      //         'Invalid file type structure. Please upload a file that matches the required format',
                      //       );
                      //     }
                      //   },
                      //   header: false,
                      // });
                    }
                  }}
                  primary
                  height="35px"
                >
                  Upload File
                </BitaButton>
              </div>
              <p className={style.nameFile}>{filesBacktest && `(${filesBacktest?.name})`}</p>
            </div>
          </div>

          <div>{data?.dataBacktest?.length > 0 && <BacktestTable />}</div>
        </div>
      </div>

      <div
        style={{
          width: '100%',
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <BitaButton
          onClick={() => setShowConfirmSubmittingBasketModal(true)}
          disabled={!(dataArray.length > 0 && applyButtonValidations)}
          primary
        >
          Apply
        </BitaButton>
        {dataArray.length > 0 && !applyButtonValidations && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '10px',
            }}
          >
            <BiSolidError className="icon-error" />
            <div>Please review the provided data and try again</div>
          </div>
        )}
      </div>
      {/* This modal is not being activated after apply */}
      <SaveSuccessBasketModal
        showModal={showModal}
        setShowModal={setShowModal}
        data={data}
        setData={setData}
      />

      {/* confirm upload backtest modal Modal */}
      <BitaModal
        isModalOpen={showModalConfirmBacktest}
        setModalState={() => setShowModalConfirmBacktest(false)}
      >
        <div style={{ width: '600px ', minHeight: '200px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              Upload Backtest
            </h2>
            <span onClick={() => setShowModalConfirmBacktest(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              This basket already has backtest attached to it. Do you want to overwrite with new
              backtest?
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  Papa.parse(filesBacktest, {
                    complete: results => {
                      if (
                        results.data.length > 1 &&
                        results.data[0][0].toUpperCase() === 'DATE' &&
                        results.data[0][1].toUpperCase() === 'VALUE'
                      ) {
                        setDataBacktest(results.data);
                        const listBasket = [...dataArray];
                        const basketSelect = {
                          ...listBasket.find(item => item.symbol === selectOptionPreview.value),
                        };
                        handleFileParse(listBasket, basketSelect);
                        setShowModalConfirmBacktest(false);
                      } else {
                        toast.error(
                          'Invalid file type structure. Please upload a file that matches the required format',
                        );
                      }
                    },
                    header: false,
                  });
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Yes
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalConfirmBacktest(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* Submitting Basket Confirm Modal */}
      <BitaModal
        isModalOpen={showConfirmSubmittingBasketModal}
        setModalState={() => setShowConfirmSubmittingBasketModal(false)}
      >
        <div
          style={{
            width: '1000px',
            minHeight: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              SUBMIT BASKET
            </h2>
            <span onClick={() => setShowConfirmSubmittingBasketModal(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px 0', textAlign: 'center' }}>
              {' '}
              Are you sure you want to submit these parameters?{' '}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingRight: '40px',
              }}
            >
              <div style={{ marginBottom: '10px', marginRight: '20px' }}>Selected Basket</div>
              <div style={{ width: '200px' }}>
                <BitaSearchBar
                  searchOptions={ListBasket}
                  style={{ minHeightWith: '50px', float: 'right' }}
                  value={selectOptionPreview?.label}
                  onChange={value => {
                    setSelectOptionPreview(value);
                    if (dataArray.length > 0) {
                      updateAndFormatData(
                        dataArray.find(item => item.symbol === value?.value),
                        false,
                      );
                    }
                  }}
                />
              </div>
            </div>

            {/* <div data-simplebar style={{ height: '360px' }}> */}
            <div data-simplebar style={{ height: '460px' }}>
              <div
              // className={tableStyles.tableTitle}
              >
                Basket Data Preview
              </div>
              <div
                // className={tableStyles.tableContainer}
                style={{ width: '100%', padding: '0 10px' }}
              >
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    {Object.keys(data ?? {})
                      .filter(key => typeof data[key] === 'string' || typeof data[key] === 'number')
                      ?.map(item => {
                        let label = item;
                        let displayValue = data?.[item];
                        if (item === 'start_week_day') {
                          displayValue = 'Monday';
                        } else if (item === 'end_week_day') {
                          displayValue = 'Friday';
                        }

                        if (item === 'description') {
                          displayValue = truncateString(data?.[item]);
                        }

                        if (item === 'calculation_zone') {
                          displayValue = CALCULATION_ZONE_MAPPING[data?.[item]] ?? '';
                        }

                        if (item === 'launch_date') {
                          // displayValue = formatDateString(launchDate);
                          displayValue = 'launch date';
                        }

                        if (item === 'symbol') {
                          label = 'Ticker';
                          displayValue = orgPrefix + displayValue;
                        }

                        return (
                          <div style={{ display: 'flex' }} key={item}>
                            <div>{`${label}`?.replace(/_/g, ' ')} </div>{' '}
                            <div>{`${displayValue}`?.replace(/_/g, ' ')}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div
                  // className={tableStyles.tableTitle}
                  style={{ marginTop: '3%' }}
                >
                  Constituents
                </div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <BasketTable
                    height="100%"
                    titleBasketTable={false}
                    deleteBasketTable={false}
                    buttonsBasketTable={false}
                  />
                  {/* <div className={style.tableNoMetadata}>
                    {Object.keys(data?.metadata?.awf ?? {}).map(item => (
                      <div>{item}</div>
                    ))}
                  </div> */}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={processBaskets}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Submit
              </BitaButton>
              <BitaButton
                onClick={() => setShowConfirmSubmittingBasketModal(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* Submitting Basket Modal */}
      <BitaModal
        isModalOpen={showModalBasketInProgress}
        setModalState={() => setShowModalBasketInProgress(false)}
      >
        <div style={{ width: '600px', minHeight: '200px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              Basket Submission
            </h2>
          </div>
          <div>
            {renderTopDescription()}

            <div
              style={{
                border: '1px solid white',
                margin: '30px 10px', // Reduced horizontal margin
                padding: '10px',
                minHeight: '200px',
                maxHeight: '300px', // Set a maximum height
                width: 'calc(100% - 20px)', // Adjust width to account for padding
              }}
            >
              <div
                data-simplebar
                className={styles.customScrollbarBulkBasketSubmission}
                style={{
                  maxHeight: '200px',
                  overflow: 'auto',
                  overflowX: 'hidden', // Hide horizontal scroll
                }}
              >
                {/* At least there are some baskets created */}
                {successCount > 0 && <div>Baskets created:</div>}

                {/* No basket was created */}
                {failureCount > 0 && successCount === 0 && (
                  <div>The following baskets were not created:</div>
                )}

                {dataSendBasket.map(item => (
                  <div
                    key={item.symbol}
                    style={{
                      display: 'flex',
                      padding: '5px 0',
                    }}
                  >
                    Ticker: {item.symbol}{' '}
                    {item.send === 'success' ? (
                      <BiCheck className="tooltip-icon icon-info" />
                    ) : (
                      <BiSolidError className="icon-error" />
                    )}
                  </div>
                ))}
              </div>
            </div>

            {isLoadingSubmittingBaskets ? (
              <>
                <div
                  className="no__progress__value__indicator"
                  style={{ width: '200px', margin: 'auto' }}
                >
                  <Progress
                    percent={99}
                    // percent={((successCount + failureCount) / dataArray?.length) * 100}
                    className="builderProgressBar"
                  />
                </div>

                <div style={{ margin: '20px', textAlign: 'center' }}>
                  Submitting {dataSendBasket.length}/{dataArray.length} Baskets ...
                </div>
              </>
            ) : (
              <>
                <div style={{ margin: '20px', textAlign: 'center' }}>Process Finished.</div>
              </>
            )}

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => cleanState()}
                disabled={dataSendBasket.length !== dataArray.length}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Close
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>
      {/* showModalRequestCoverage Modal */}

      <BitaModal
        isModalOpen={showModalRequestCoverage}
        setModalState={() => setShowModalRequestCoverage(false)}
      >
        <div style={{ width: '600px ', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              REQUEST COVERAGE
            </h2>
            <span onClick={() => setShowModalRequestCoverage(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              We will send to the organization&apos;s e-mail the request for the following
              constituents
            </div>

            <div data-simplebar style={{ height: '360px' }}>
              <div style={{ width: '100%' }}>
                <div>Constituents</div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                    <div className={style.tableNoMetadata}>
                      <div className={style.requestCoverageEntry}>
                        <>
                          {authOrgPrefix === 'UB' && <div>SEDOL</div>}
                          <div>ISIN</div>
                          <div>MIC</div>
                        </>
                      </div>
                      {(data?.meta_reference_data || []).map(item => {
                        if (item?.type !== 'with_coverage') {
                          return (
                            <div className={style.requestCoverageEntry}>
                              <>
                                {authOrgPrefix === 'UB' && <div>{item?.sedol ?? '-'}</div>}
                                <div>{item?.isin}</div>
                                <div>{item?.exchange}</div>
                              </>
                            </div>
                          );
                        }
                        return '';
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  let body;

                  if (data?.identifier_type === 'sedol') {
                    const sedolsArray = data?.meta_reference_data?.filter(
                      item => item?.type !== 'with_coverage',
                    );
                    const sedols = sedolsArray?.map(coverage => {
                      return {
                        sedol: coverage?.sedol ?? null,
                        isin: coverage?.isin ?? null,
                        mic: coverage?.exchange ?? null,
                        type: coverage?.type ?? null,
                      };
                    });
                    body = {
                      user_email: user?.userdata?.email,
                      user_name: user.username,
                      company_name: user.company,
                      request_coverage: sedols,
                    };
                  }

                  if (data?.identifier_type === 'isin') {
                    const isinArray = data?.meta_reference_data?.filter(
                      item => item?.type !== 'with_coverage',
                    );
                    const isins = isinArray?.map(coverage => {
                      return {
                        sedol: coverage?.sedol ?? null,
                        isin: coverage?.isin ?? null,
                        mic: coverage?.exchange ?? null,
                        type: coverage?.type ?? null,
                      };
                    });
                    body = {
                      user_email: user?.userdata?.email,
                      user_name: user.username,
                      company_name: user.company,
                      request_coverage: isins,
                    };
                  }

                  setShowModalRequestCoverage(false);
                  coverageRequest(token, body)
                    .then(rsp => {
                      if (rsp.data.data.email_sent) {
                        setShowModalRequestCoverageSuccessful(true);
                      } else {
                        toast.error('We had a problem processing your request');
                      }
                      setShowModalRequestCoverage(false);
                    })
                    .catch(error => {
                      toast.error('We had a problem processing your request');
                    });
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Submit
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalRequestCoverage(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* sucessful coverage request modal */}
      <BitaModal
        isModalOpen={showModalRequestCoverageSuccessful}
        setModalState={() => setShowModalRequestCoverageSuccessful(false)}
      >
        <div style={{ width: '600px ', minHeight: '130px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              REQUEST COVERAGE
            </h2>
            <span onClick={() => setShowModalRequestCoverageSuccessful(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Your request is being processed, we will contact you shortly.
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => setShowModalRequestCoverageSuccessful(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Close
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* delete constituents modal Modal */}
      <BitaModal isModalOpen={showModalDelete} setModalState={() => setShowModalDelete(false)}>
        <div style={{ width: '600px ', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              Delete Constituents
            </h2>
            <span onClick={() => setShowModalDelete(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Do you want to remove these constituents?
            </div>

            <div data-simplebar style={{ height: '360px' }}>
              <div style={{ width: '100%' }}>
                <div>Constituents</div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    <div className={style.requestCoverageEntry}>
                      <>
                        {authOrgPrefix === 'UB' && <div>SEDOL</div>}
                        <div>ISIN</div>
                        <div>MIC</div>
                        {authOrgPrefix !== 'UB' && <div>SEDOL</div>}
                        <div>COMPANY NAME</div>
                      </>
                    </div>
                    {(constituentsDelete || []).map(item => (
                      <div className={style.requestCoverageEntry}>
                        <>
                          {authOrgPrefix === 'UB' && <div>{item?.sedol ?? '-'}</div>}
                          <div>{item?.isin ?? '-'}</div>
                          <div>{item?.exchange ?? '-'}</div>
                          {authOrgPrefix !== 'UB' && <div>{item?.sedol ?? '-'}</div>}
                          <div>{item?.company_name ?? '-'}</div>
                        </>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  setShowModalDelete(false);
                  const arrayIdentifier = constituentsDelete.map(item => item.isin ?? item.sedol);
                  setData({
                    ...data,
                    meta_reference_data: [
                      ...data?.meta_reference_data?.filter(
                        item => !arrayIdentifier?.includes(item?.isin ?? item?.sedol),
                      ),
                    ],
                  });
                  setConstituentsDelete([]);
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Delete
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalDelete(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>
    </div>
  );
};

export default BulkBasketSubmission;
