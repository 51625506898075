import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import axios from '../../../Axios';
import { getDataComponents } from '../../../api-data-mapping';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import styles from '../../builder.module.scss';
import { setApiData } from '../../../state/actions/api-data-actions';
import {
  setBacktest,
  setFormData,
  addBacktestDetails,
} from '../../../state/actions/backtest-actions';
import { useLoadStatus, loaderDataObjectDecorator } from '../../../utils/loading_status_tools';

export const BuilderButtonComponent = ({
  title,
  path = '',
  redirect = null,
  onclick,
  dispatch_action,
  popupHandleOnClick = null,
  stepData = null,
  loading = false,
  id = null,
  handlePopupClose = null,
  hide_on_rdx_state,
  autofocus,
  Ref,
  onChange,
  ...props
}) => {
  const dispatch = useDispatch();
  const apiData = useSelector(state => state.apiData.apiData);
  const getMethodologyName = () => {
    let baseMethodology = '';
    if (_.get(apiData, 'methodologySelected.id')) {
      baseMethodology = _.get(apiData, 'Methodologies.data', [{}]).find(
        methodology => methodology.id === apiData.methodologySelected.id,
      );
    }
    return baseMethodology?.name;
  };
  const backtests = useSelector(state => state?.backtest?.backtests);
  const backtestTimestamp = useSelector(state => state?.backtest?.timestamp);
  const universeSelected = useSelector(state => state.summary);
  const _universeSelectedDetails = useSelector(state => state.apiData.apiData.universeSelected);
  const _methodologySelectedId = useSelector(
    state => state.apiData.apiData.methodologySelected?.id,
  );
  const backtestDetails = {
    name: '',
    universeSelected: {
      name: _universeSelectedDetails?.name || '',
      id: _universeSelectedDetails?.id || null,
    },
    methodologySelected: {
      name: getMethodologyName() || '',
      id: _methodologySelectedId || null,
    },
  };
  const auth = useSelector(state => state.auth);
  const [wsDisabled, setWsDisabled] = useState(false);
  const history = useHistory();
  const componentID = title.replace(' ', '');
  const isLoading = useLoadStatus(componentID);

  let idUniverse = false;
  if (
    universeSelected &&
    universeSelected.universeBuilderOperations &&
    universeSelected.universeBuilderOperations.universe.id
  ) {
    idUniverse = universeSelected.universeBuilderOperations.universe.id;
  }

  const disabledList = useSelector(state => state.backtest.backtestsWError);

  // if (disabledList.length > 0) debugger;

  const idDisplayedRunningBacktest = useSelector(
    state => state?.backtest?.idDisplayedRunningBacktest,
  );
  const infoMessage = useSelector(
    state =>
      state?.backtests?.backtests?.find(backtest => backtest.id === idDisplayedRunningBacktest)
        ?.infoMessage,
  );

  let disabled = wsDisabled || props.disabled;

  if (disabledList?.includes(idDisplayedRunningBacktest)) {
    disabled = true;
  }

  useEffect(() => {
    if (title === 'Run backtest in Background' && backtestTimestamp && !infoMessage) {
      const current = new Date().getTime();
      const rest = current - backtestTimestamp;
      const timeout = 180000 - rest;
      if (timeout > 0) {
        setTimeout(() => {
          if (!infoMessage) {
            setWsDisabled(true);
          }
        }, timeout);
      } else {
        setWsDisabled(true);
      }
    }
  }, [backtestTimestamp]);

  return (
    <BitaButton
      primary
      autofocus={autofocus}
      Ref={Ref}
      key={id}
      disabled={
        (title === 'Run backtest in Background' && disabled) ||
        (title === 'Download Factsheet' &&
          backtests[idDisplayedRunningBacktest].infoMessage !== 'Backtest completed.') ||
        (props?.action === 'getGeography' &&
          (!props?.historyAddibleData?.value ||
            props.historyAddibleData?.selection?.length === 0 ||
            !props.historyAddibleData?.selection))
      }
      width={title === 'Download Factsheet' ? '120px !important' : ''}
      loadingColor={title === 'Download Factsheet' ? '#0234b3ef' : ''}
      loading={loading === id || isLoading}
      className={`${styles.builderButtonComponent} builderButtonComponent ${
        title === 'Download Factsheet' ? 'whiteButton' : ''
      }`}
      // eslint-disable-next-line consistent-return
      onClick={() => {
        const source = axios.CancelToken.source();

        if (props.action && props.action === 'getGeography') {
          // eslint-disable-next-line func-names
          (async function() {
            let res = {};
            const dataComponent = getDataComponents.filter(
              v => v.key === 'Geography Dropdown' && v.title === 'Classification',
            )[0];

            res = loaderDataObjectDecorator(dataComponent, dispatch);
            if (props.historyAddibleData.classification?.value === 'Country of Primary Listing') {
              res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
                props.historyAddibleData.group_by.value === 'Liquidity' ? '7' : '8',
                idUniverse,
                auth.token,
                props.historyAddibleData.indicator.id,
                props.historyAddibleData.value / 100,
                props.historyAddibleData.selection.map(item => item.id),
              );

              dispatch(
                setApiData({
                  title: `Geography Dropdown_Country of Primary Listing_${path}`,
                  data: dataComponent.formatData(res.data || []),
                }),
              );
              const localPath = `${path}ApplyAccumulativeCutOff`;
              onChange(localPath, true);
            } else if (props.historyAddibleData.classification?.value === 'BITA Assigned Country') {
              res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
                props.historyAddibleData.group_by.value === 'Liquidity' ? '5' : '6',
                idUniverse,
                auth.token,
                props.historyAddibleData.indicator.id,
                props.historyAddibleData.value / 100,
                props.historyAddibleData.selection.map(item => item.id),
              );

              dispatch(
                setApiData({
                  title: `Geography Dropdown_BITA Assigned Country_${path}`,
                  data: dataComponent.formatData(res.data || []),
                }),
              );
              const localPath = `${path}ApplyAccumulativeCutOff`;
              onChange(localPath, true);
            } else if (
              props.historyAddibleData.classification?.value === 'Country of Incorporation'
            ) {
              res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
                props.historyAddibleData.group_by.value === 'Liquidity' ? '9' : '10',
                idUniverse,
                auth.token,
                props.historyAddibleData.indicator.id,
                props.historyAddibleData.value / 100,
                props.historyAddibleData.selection.map(item => item.id),
              );

              dispatch(
                setApiData({
                  title: `Geography Dropdown_Country of Incorporation_${path}`,
                  data: dataComponent.formatData(res.data || []),
                }),
              );
              const localPath = `${path}ApplyAccumulativeCutOff`;
              onChange(localPath, true);
            } else if (
              props.historyAddibleData.classification?.value === 'Country of Headquarters'
            ) {
              res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
                props.historyAddibleData.group_by.value === 'Liquidity' ? '11' : '12',
                idUniverse,
                auth.token,
                props.historyAddibleData.indicator.id,
                props.historyAddibleData.value / 100,
                props.historyAddibleData.selection.map(item => item.id),
              );

              dispatch(
                setApiData({
                  title: `Geography Dropdown_Country of Headquarters_${path}`,
                  data: dataComponent.formatData(res.data || []),
                }),
              );
              const localPath = `${path}ApplyAccumulativeCutOff`;
              onChange(localPath, true);
            }
          })();
          return () => {
            source.cancel();
          };
        }

        if (props.action && props.action === 'cleanGeography') {
          // eslint-disable-next-line func-names
          (async function() {
            let res = {};
            const dataComponent = getDataComponents.filter(
              v => v.key === 'Geography Dropdown' && v.title === 'Classification',
            )[0];
            if (props.historyAddibleData.classification.value === 'BITA Assigned Country') {
              res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
                '3',
                idUniverse,
                auth.token,
              );
              dispatch(
                setApiData({
                  title: `Geography Dropdown_BITA Assigned Country_${path}`,
                  data: dataComponent.formatData(res.data || []),
                }),
              );
              const localPath = `${path}ApplyAccumulativeCutOff`;
              onChange(localPath, true);
            } else if (
              props.historyAddibleData.classification.value === 'Country of Primary Listing'
            ) {
              res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
                '0',
                idUniverse,
                auth.token,
              );
              dispatch(
                setApiData({
                  title: `Geography Dropdown_Country of Primary Listing_${path}`,
                  data: dataComponent.formatData(res.data || []),
                }),
              );
              const localPath = `${path}ApplyAccumulativeCutOff`;
              onChange(localPath, true);
            } else if (
              props.historyAddibleData.classification.value === 'Country of Incorporation'
            ) {
              res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
                '1',
                idUniverse,
                auth.token,
              );
              dispatch(
                setApiData({
                  title: `Geography Dropdown_Country of Incorporation_${path}`,
                  data: dataComponent.formatData(res.data || []),
                }),
              );
              const localPath = `${path}ApplyAccumulativeCutOff`;
              onChange(localPath, true);
            } else if (
              props.historyAddibleData.classification.value === 'Country of Headquarters'
            ) {
              res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
                '2',
                idUniverse,
                auth.token,
              );
              dispatch(
                setApiData({
                  title: `Geography Dropdown_Country of Headquarters_${path}`,
                  data: dataComponent.formatData(res.data || []),
                }),
              );
              const localPath = `${path}ApplyAccumulativeCutOff`;
              onChange(localPath, true);
            }
          })();
          return () => {
            source.cancel();
          };
        }

        if (dispatch_action) {
          dispatch(dispatch_action);
        }

        if (handlePopupClose && (title === 'Cancel Backtest' || title === 'Cancel Rebalancing')) {
          handlePopupClose();
          // eslint-disable-next-line consistent-return
          return;
        }

        if (popupHandleOnClick)
          // eslint-disable-next-line no-unused-expressions
          popupHandleOnClick(onclick);

        if (redirect) {
          history.push(redirect);
        }

        if (onclick) {
          if (props.runBacktest) {
            props.runBacktest(
              stepData && (stepData['Index Backtest'] || stepData['REBALANCING & RECONSTITUTION']),
              onclick,
            );
          }
          if (onclick.title === 'Backtest in Progress') {
            if (stepData && stepData['Index Backtest']) {
              // debugger;

              // set details for Backtest
              dispatch(
                addBacktestDetails({
                  ...backtestDetails,
                  name: stepData['Index Backtest']['INDEX NAME'],
                }),
              );

              dispatch(setFormData(stepData['Index Backtest']));
            }
          } else if (onclick && typeof onclick === 'function') {
            if (id) onclick(id);
            else {
              onclick();
            }
          } else if (redirect) history.push(redirect);
        }

        // onclick();

        // eslint-disable-next-line no-unused-expressions
      }}
    >
      {title}
    </BitaButton>
  );
};
