import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import * as AuthActions from '../../../state/actions/auth-actions';
import customStyles from './ForgotPasswordModal.module.scss';
import BitaModal from '../../../bitaComponents/BitaModal/BitaModal';
import styles from '../../../bitaComponents/BitaModal/BitaModal.module.scss';
import { Icons } from '../../../constants/icons';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaSpinner from '../../../components/BitaSpinner/BitaSpinner';

const styleClose = {
  position: 'absolute',
  fontSize: '30px',
  fontWeight: 'bold',
  top: '-10px',
  right: '0',
  cursor: 'pointer',
  padding: '10px',
  boxSizing: 'border-box',
};

const ForgotPasswordModal = withRouter(props => {
  const [dataToSend, setDataToSend] = useState({ email: '' });
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const apiData = useSelector(state => state?.apiData?.apiData);

  const handleChange = ({ target }) => {
    setDataToSend({ ...dataToSend, [target?.name]: target?.value });
  };

  useEffect(() => {
    setloading(false);
    if (apiData?.verify_email_id) {
      props.close();
    }
  }, [apiData]);

  const handleSubmit = async () => {
    dispatch(AuthActions.verifyEmailId(dataToSend?.email));
    setloading(true);
    setDataToSend({ email: '' });
  };

  return (
    <BitaModal
      isModalOpen={props.visibility}
      setModalState={() => {
        props.close();
      }}
    >
      <div className={customStyles.modalPadding}>
        <div className={(styles.pupUpTitleContainer, customStyles.pupUpTitleContainer)}>
          <Icons.Avanti style={{ marginRight: '20px' }} />
          <h2 style={{ margin: '0', padding: '0', fontSize: '14px', textTransform: 'uppercase' }}>
            Recover Password
          </h2>
          <span onClick={() => props.close()} style={styleClose}>
            &times;
          </span>
        </div>
        <div className={customStyles.subHeadingText}>
          {' '}
          <p>
            Please enter your registered email associated with BITACore <br /> to receive a
            temporary password.
          </p>
        </div>
        <div className={customStyles.forgotPasswordFlexForm}>
          <input
            type="text"
            name="email"
            onChange={handleChange}
            value={dataToSend.email}
            placeholder="Email"
            className={customStyles.input}
          />
          {loading && <BitaSpinner loading />}
          <div className={customStyles.buttonsContainer}>
            <BitaButton
              onClick={handleSubmit}
              width="90px"
              height="30px"
              className={
                dataToSend?.email?.length && dataToSend?.email?.includes('@')
                  ? ''
                  : customStyles.buttonInactive
              }
              primary
            >
              Send
            </BitaButton>
            <BitaButton
              width="90px"
              height="30px"
              onClick={props.close}
              className={customStyles.cancelButton}
            >
              Cancel
            </BitaButton>
          </div>
        </div>
        <div className={customStyles.aditionalInfo}>
          <p className={customStyles.aditionalText}>
            If you do not remember your registered email ID, please contact our team at:
          </p>
          <p className={customStyles.link} href="bitacore@bita.io">
            bitacore@bita.io
          </p>
        </div>
      </div>
    </BitaModal>
  );
});

export default ForgotPasswordModal;
