import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './Topbar.module.scss';
import { useCurrentModule } from '../../bitaHooks';
import TopbarButton from './TopbarButton';
import SideUserbar from '../MainLeftSidebar/SideUserbar';
import { Icons } from '../../constants/icons';
import ModalAbandoneProject from '../../bitaComponents/BitaModal/ModalabandoneProject';
import Bitabacktestbanner from '../../bitaComponents/BitaBacktestBanner/BitaBacktestBanner';
import BitaWSConnectionWidget from '../../bitaComponents/BitaWSConnectionWidget/BitaWSConnectionWidget';
import { hasValidBitaDomain } from '../../utils/utils';

const Topbar = props => {
  const [visibleConfirmation, setVisibleConfirmation] = React.useState(null);
  const [currentModule] = useCurrentModule();
  const [go, setGo] = React.useState(null);
  const summaryProyect = Object.keys(useSelector(state => state.summary));
  const apiData = useSelector(state => state.apiData.apiData);
  const userData = useSelector(state => state.auth?.userdata);

  return (
    <div className={styles.topbarMain}>
      <div className="topbar__banner_area" style={{ width: '50%' }}>
        <Bitabacktestbanner />
      </div>
      <div className={styles.topbarMain__right_options}>
        {summaryProyect.length > 0 ||
        (apiData?.['Optimized Index'] && Object.keys(apiData?.['Optimized Index'])?.length > 0) ? (
          <div className={styles.topbarButtonContainer}>
            <BitaWSConnectionWidget />
            <SideUserbar topBar />
            <div
              onClick={() => {
                setGo('/settings');
                if (
                  (summaryProyect.length > 0 && currentModule) ||
                  (apiData?.['Optimized Index'] &&
                    Object.keys(apiData?.['Optimized Index'])?.length > 0)
                )
                  setVisibleConfirmation(true);
              }}
            >
              <TopbarButton label="Settings" callback={() => props.preferencesCB()}>
                <Icons.parameters />
              </TopbarButton>
            </div>
            <div
              onClick={() => {
                setGo('/customer-service');
                if (
                  (summaryProyect.length > 0 && currentModule) ||
                  (apiData?.['Optimized Index'] &&
                    Object.keys(apiData?.['Optimized Index'])?.length > 0)
                )
                  setVisibleConfirmation(true);
              }}
            >
              <TopbarButton label="customer service" callback={() => props.preferencesCB()}>
                <Icons.CustomerService />
              </TopbarButton>
            </div>
            <TopbarButton
              label="Log Out"
              callback={() => {
                props.logoutCB();
                window.location.reload(true);
              }}
            >
              <Icons.logout />
            </TopbarButton>
          </div>
        ) : (
          <div className={styles.topbarButtonContainer}>
            <BitaWSConnectionWidget />
            <SideUserbar topBar />
            <Link to="/settings">
              <TopbarButton label="Settings" callback={() => props.preferencesCB()}>
                <Icons.parameters />
              </TopbarButton>
            </Link>
            <Link to="/customer-service">
              <TopbarButton label="customer service" callback={() => props.preferencesCB()}>
                <Icons.CustomerService />
              </TopbarButton>
            </Link>

            <TopbarButton
              label="Log Out"
              callback={() => {
                props.logoutCB();
                window.location.reload(true);
              }}
            >
              <Icons.logout />
            </TopbarButton>
          </div>
        )}
      </div>
      <ModalAbandoneProject
        visibility={visibleConfirmation}
        to={go}
        close={() => setVisibleConfirmation(false)}
      />
    </div>
  );
};

Topbar.defaultProps = {
  preferencesCB: () => null,
  settingsCB: () => null,
  logoutCB: () => null,
};

export default Topbar;
