import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import lodash from 'lodash';
import { toast } from 'react-toastify';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import { useSummary } from '../../../bitaHooks';
import * as SummaryConstants from '../../../constants/summary-constants';
import BitaModal from '../../../bitaComponents/BitaModal/BitaModal';
import BitaCard from '../../../components/BitaCard/BitaCard';
import Builder from '../../../builder/Builder';
import { runBacktest as runBacktestFunction } from './runBacktest';
import { IconsModules } from '../../../constants/icons-module';
import { Icons } from '../../../constants/icons';
import './workflow-modal.css';
import styles from './workflow-modal.module.css';
import './workflow.scss';
import { defaultTemplate } from '../../../constants/methodologies-constants';
import { GenerateTable } from './WorkflowComponents/GenerateTable';
import { BitaCardSelectedContent } from './WorkflowComponents/BitaCardSelectedContent';
import { BitaContentWidget } from './WorkflowComponents/BitaContentWidget';
import { BitaContentWidgetBuilder } from './WorkflowComponents/BitaContentWidgetBuilder';
// eslint-disable-next-line import/no-cycle
import { BitaInstrumentSelectOnly } from './WorkflowComponents/BitaInstrumentSelectOnly';
import { BitaInstrumentSelect } from './WorkflowComponents/BitaInstrumentSelect';
import { FactsheetBuilderComponent } from './WorkflowComponents/FactsheetBuilderComponent';
import { RebalacingReconstitutionSelectUniverses } from './WorkflowComponents/RebalacingReconstitutionSelectUniverses';
import { cleanProject } from '../../../state/actions/project-actions';
import {
  discardBacktest,
  runBacktestInBackground,
  setRunningBacktestModalMustDisplay,
} from '../../../state/actions/backtest-actions';

import * as ApiDataActions from '../../../state/actions/api-data-actions';
import BitaExitWorkflowModal from '../../../bitaComponents/BitaModal/BitaExitWorkflowModal';
import { cleanCache } from '../../../state/actions/summary-actions';
import { defaultTemplateStructure } from '../../../constants/structured-index-constants';

function setParams({ query = '' }) {
  const searchParams = new URLSearchParams();
  searchParams.set('step', query);
  return searchParams.toString();
}

//= ==================================================================================================

const WorkflowModal = props => {
  const [applyToSummary] = useSummary('universeBuilderOperations', 'universe', 'universeSelected');
  const { onInstrumentSelect = Function.prototype, instrumentApply } = props;
  const [component, _setComponent] = useState(props);
  const [disabledOnapply, setDisabledOnapply] = useState(false);
  const [pastComponents, setPastComponents] = useState({});
  const [popUpData, setPopUpData] = useState(null);
  const stateApiData = useSelector(state => state.apiData.apiData);
  const stateSummary = useSelector(state => state.summary);
  const statebacktestBackground = useSelector(state => state.backtest.backtests);
  const displayBacktestModal = useSelector(state => state.backtest.displayBacktestModal);
  const displayRunningBacktestModal = useSelector(state => state.backtest.displayRunningBacktest);
  const backtestCompleted = useSelector(state => state.backtest.backtestCompleted);
  const token = useSelector(state => state.auth.token);
  const userData = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [customModal, setCustomModal] = useState(null);
  const history = useHistory();
  const popUpOpen = !displayBacktestModal && !!popUpData;
  const location = useLocation();
  const optimizedState = lodash.get(stateApiData, 'Optimized Index', {});
  const [showModalExit, setShowModalExit] = useState(false);
  const [visibleModal, setVisibleModal] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const applyRef = React.useRef('applyRef');

  const closeModal = value => {
    setPopUpData(value);
    if (!popUpData?.title || popUpData?.title !== 'Index Backtest') {
      history.push(`/`);
      return;
    }
    if (popUpData?.runBacktestUbication === 'analyzer') {
      props.setModalState(false);
      if (backtestCompleted) {
        history.push(`/index-analyzer/analyzer`);
      } else {
        history.push(`/index-analyzer/performance`);
      }
    }
    if (
      popUpData?.runBacktestUbication === 'optimized' ||
      popUpData?.runBacktestUbication === 'structured'
    ) {
      props.setModalState(false);
    }
  };

  // ESTE BLOQUE MANEJA LA URL Y EL PODER IR HACIA ATRAS ===============================================================
  // este useEffect maneja cuando se abre el popup de run backtest y se da hacia atras
  // cuando se abre cambia la url y cuando se presiona atras cierra el popup

  // cuando se setea un componente al darle click a un boton seteamos la url con history.push
  const setComponent = _component => {
    const componentName = setParams({
      query: lodash.last(Object.values(_component.header_path)),
    });
    const newPastComponents = { ...pastComponents, [componentName]: _component };
    setPastComponents(newPastComponents);
    const path = location.pathname;
    const newURL = path.includes('/step=') ? path.split('/step=')[0] : path;
    history.push(`${newURL}/${componentName}`);

    _setComponent(_component);
  };

  // retorna la ruta sin step=....
  const generateRootPath = () => {
    const path = location.pathname;
    return path.includes('/step=') ? path.split('/step=')[0] : path;
  };

  const setModalState = state => {
    if (!state) {
      history.push(`${generateRootPath()}`);
    }

    props.setModalState(state);
  };

  useEffect(() => {
    if (!props.isModalOpen) {
      history.push(`${generateRootPath()}`);
    }
  }, [props.isModalOpen]);

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === ' ') {
      setPopUpData(null);
      setShowModal(false);
    }
    if (
      popUpData?.runBacktestUbication === 'structured' ||
      popUpData?.runBacktestUbication === 'optimized' ||
      popUpData?.runBacktestUbication === 'analyzer' ||
      (popUpData?.title === 'Backtest in Progress' &&
        (location.pathname === '/optimized-index/optimized-index' ||
          location.pathname === '/structures-product/structures-product' ||
          location.pathname.split('/')[1] === 'index-analyzer' ||
          location.pathname === '/' ||
          location.pathname === ' '))
    ) {
      setVisibleModal(false);
      setShowModal(false);
    }
  }, [popUpData, location.pathname]);

  // si usuario clickea hacia atrás buscamos el componente que corresponde a la nueva
  // url y lo colocamos
  useEffect(() => {
    const setComponentByURLMatch = matchParameter => {
      const componentByURL = pastComponents[matchParameter];

      if (componentByURL) _setComponent(componentByURL);
    };

    history.listen((newLocation, action) => {
      if (action !== 'PUSH') {
        // Send user back if they try to navigate back
        const path = newLocation.pathname;
        const match = path.match(/step=.+$/g);

        if (!path.includes('step')) {
          closeModal(null);
        }

        if (match && match.length > 0) {
          setComponentByURLMatch(match[0]);
        }
      }
    });
  }, [pastComponents]);

  // FIN DE USEEFFECT PARA RETROCEDER=================================================================================

  const runBacktest = runBacktestFunction({
    dispatch,
    popUpData,
    setPopUpData,
    stateApiData,
    stateSummary,
    token,
    userData,
  });

  function getBackComponentsForSlider({ headerPath, index, onlyStepBack = false }) {
    const stepsBack = onlyStepBack ? 1 : headerPath.length - (index + 1);
    return Array.from(Array(stepsBack)).reduce(
      currentComponent => currentComponent.parent || currentComponent,
      component,
    );
  }

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    props.type === 'PopUp' ? setPopUpData(props) : setComponent(props);
  }, [props.components]);

  useEffect(() => {
    if (displayBacktestModal) {
      props.setModalState(false);
      setPopUpData(false);
    }
    // eslint-disable-next-line
  }, [displayBacktestModal]);

  const child = useMemo(() => {
    const result = {};
    const componentCopy = Object.values(component?.components || {});
    if (
      (stateApiData.methodologySelected?.author_id || stateApiData.methodologySelected?.id) &&
      componentCopy.length > 0
    ) {
      componentCopy.map(item => {
        if (
          item.title === 'Base Methodology' &&
          (!item.selected || item?.selected?.[1] !== stateApiData.methodologySelected?.id) &&
          (stateApiData.methodologySelected?.author_id || stateApiData.methodologySelected?.id) &&
          stateApiData.methodologySelectedCard?.id
        ) {
          item.selected = stateApiData.methodologySelected;
        }
        return item;
      });
    }
    if (stateApiData.universeSelected?.id && componentCopy.length > 0) {
      componentCopy.map(item => {
        if (
          item.title === 'Base Universe' &&
          !item.selected &&
          stateApiData.universeSelected?.id &&
          stateApiData.universeSelectedCard?.id
        ) {
          item.selected = stateApiData.universeSelected;
        }
        return item;
      });
    }
    if (
      location.pathname.includes('rebalancing-reconstitution') &&
      !stateApiData.methodologySelected?.id &&
      !stateApiData.universeSelected?.id &&
      !stateApiData.universeSelected?.File_name
    ) {
      componentCopy.map(item => {
        if (item.title === 'Base Methodology' || item.title === 'Base Universe') {
          item.selected = null;
        }
        return item;
      });
    }
    result.components = Object.values(componentCopy || {});
    result.widgets = Object.values(component.widgets || {});
    result.cards = result.components.filter(comp => ['Card', 'ExpansiveCard'].includes(comp.type));
    result.buttons = result.components.filter(comp => comp.type === 'Button');
    result.images = result.components.filter(comp => comp.type === 'Image');
    return result;
  }, [component]);

  const headerPath = useMemo(
    () => (component.header_path ? Object.values(component.header_path) : ['Home']),
    [component],
  );

  useEffect(() => {
    if (component?.widgets?.[0].type === 'SearchBar' && instrumentApply.length === 0) {
      setDisabledOnapply(true);
    }
    if (component?.widgets?.[0].type === 'SearchBar' && instrumentApply.length > 0) {
      setDisabledOnapply(false);
    }
  }, [component, instrumentApply]);

  const hitApply = (url, auxcomponent) => {
    props.setModalState(false, true);
    if (url && url.length) {
      history.push(url);
    }
    if (auxcomponent.parentWidget.title === 'Structured Index') {
      if (auxcomponent.widgets[0].selected) {
        let widget = 'Instrument Selected';
        dispatch({
          type: SummaryConstants.ADD_ENTRY,
          payload: { module: 'Structured Index', widget, data: auxcomponent.widgets[0].selected },
        });
        const templateStructured = defaultTemplateStructure();
        widget = 'New Structured Product';
        dispatch({
          type: SummaryConstants.ADD_ENTRY,
          payload: {
            module: 'Structured Index',
            widget,
            data: templateStructured,
          },
        });
        dispatch(
          ApiDataActions.setApiData({
            title: 'Structured Index Backtest',
            data: auxcomponent.widgets[0].selected,
          }),
        );
        dispatch(
          ApiDataActions.setApiData({
            title: 'indexBacktest',
            data: {
              'Index Backtest': {
                'BASE VALUE': 1000,
                Benchmark: auxcomponent.widgets[0].selected,
              },
            },
          }),
        );
      }
    }
  };

  const popupHandleOnClick = useCallback(
    _props => {
      if (!_props.onclick || !_props.onclick.type) return;
      if (_props.onclick.type === 'PopUp') setPopUpData(_props.onclick);
      if (_props.onclick.type === 'Modal') {
        setCustomModal(_props.onclick.name);
        setPopUpData(null);
      }
      if (_props.onclick.type === 'Slides') {
        props.replaceWorkflow(_props.onclick);
        // eslint-disable-next-line no-unused-expressions
        !props.isModalOpen && props.setModalState(true);
        setPopUpData(null);
      }
    },
    // eslint-disable-next-line
    [setPopUpData, props],
  );

  const getRunBacktestComponent = () => {
    const optimizedRiskModel = Object.entries(optimizedState).find(([step, value]) =>
      step.endsWith('Risk Model'),
    );
    const structured = lodash.get(stateSummary, 'Structured Index', {});
    const auxPopUpData = popUpData;
    if (
      auxPopUpData.components &&
      props.type === 'PopUp' &&
      Array.isArray(auxPopUpData.components)
    ) {
      auxPopUpData.components.forEach(sec => {
        if (sec.display_none !== undefined) {
          sec.display_none = Object.keys(structured).length > 0 || !!optimizedRiskModel;
          // sec.text = optimizedRiskModel ? '' : 'RISK MODEL';
          sec.disabled = !!optimizedRiskModel;
        }
        return sec;
      });
    }
    return auxPopUpData;
  };

  const runStructureBacktest = (data, onclick) => {
    const preloadBacktest = lodash.get(stateSummary, 'Structured Index', {});
    toast.error('Structured backtest popup ');
    setPopUpData(onclick);
    return true;
  };

  let Imagen = IconsModules.BitaUniverseModule;
  let ImagenPopUp = IconsModules.IndexModule;
  if (props.runBacktestUbication === 'structured') {
    ImagenPopUp = IconsModules.StructureIModule;
  }
  if (props.runBacktestUbication === 'optimized') {
    ImagenPopUp = IconsModules.OptimizedModule;
  }
  if (props.runBacktestUbication === 'portfolio') {
    ImagenPopUp = IconsModules.PortfolioBasketModule;
  }

  if (props.header_icon === 'UniverseModule') {
    Imagen = IconsModules.UniverseModule;
  }
  if (props.header_icon === 'ActivateCalculation') {
    Imagen = IconsModules.ACIcon;
  }
  if (props.header_icon === 'IndexModule') {
    Imagen = IconsModules.IndexModule;
  }
  if (props.header_icon === 'StructureIModule') {
    Imagen = IconsModules.StructureIModule;
  }
  if (props.header_icon === 'RebalancingReconstitution') {
    Imagen = IconsModules.RebalancingReconstitutionModule;
    ImagenPopUp = IconsModules.RebalancingReconstitutionModule;
  }

  if (props.header_icon === 'FactsheetModule') {
    Imagen = IconsModules.FactsheetModule;
  }

  useEffect(() => {
    const data = displayRunningBacktestModal?.popUpData;

    if (!displayRunningBacktestModal?.backtestinbackground && data) {
      setPopUpData(data);
    }
  }, [displayRunningBacktestModal?.backtestinbackground, displayRunningBacktestModal?.popUpData]);

  useEffect(() => {
    if (popUpData) dispatch(runBacktestInBackground({ popUpData }));
  }, [popUpData]);

  useEffect(() => {
    if (backtestCompleted) setPopUpData(null);
  }, [backtestCompleted]);

  // =============================================================================================== EXIT WORKFLOW MODAL BUTTON HANDLER
  const handleOptionsMenuCloseModal = ({ id }) => {
    setShowModalExit(false);

    if (id === 'cancel_project') {
      dispatch(cleanProject(token));
      if (location.pathname.includes('rebalancing-reconstitution') && component?.components) {
        const componentCopy = [...component.components];
        componentCopy.map(item => {
          if (item.selected) {
            item.selected = false;
          }
          return item;
        });
        setComponent({ ...component, selected: '', components: componentCopy });
        dispatch(
          ApiDataActions.setApiData({
            title: 'universeSelected',
            data: {},
          }),
        );
        dispatch(
          ApiDataActions.setApiData({
            title: 'methodologySelected',
            data: {},
          }),
        );
      }
      dispatch(
        ApiDataActions.setApiData({
          title: 'universeSelectedCard',
          data: {},
        }),
      );
      dispatch(
        ApiDataActions.setApiData({
          title: 'methodologySelectedCard',
          data: {},
        }),
      );
      setPopUpData(null);
      setShowModal(false);
      if (
        location?.pathname?.includes('Setup') ||
        location?.pathname?.includes('Activate+in+calculation')
      ) {
        history.push(location.pathname.split('/step')?.[0]);
        props.setModalState(false);
      } else {
        history.push('/');
      }
    }

    if (id === 'cancel_backtest') {
      dispatch(cleanProject(token, true));
      setPopUpData(null);
      setShowModal(false);
      dispatch(cleanCache(token));
      dispatch(
        ApiDataActions.setApiData({
          title: 'universeSelectedCard',
          data: {},
        }),
      );
      dispatch(
        ApiDataActions.setApiData({
          title: 'methodologySelectedCard',
          data: {},
        }),
      );
      dispatch(
        ApiDataActions.setApiData({
          title: 'universeSelected',
          data: {},
        }),
      );
      dispatch(
        ApiDataActions.setApiData({
          title: 'methodologySelected',
          data: {},
        }),
      );
      dispatch(discardBacktest());
      if (location.pathname === '/optimized-index/optimized-index') {
        history.push('/optimized-index/optimized-index');
      } else if (!location.pathname.includes('analyzer')) {
        history.push('/');
      }
    }
    if (id === 'continue') {
      history.push('/');
      dispatch(runBacktestInBackground({ backtestinbackground: true, popUpData }));
    }
  };

  const popupCloseOptions = lodash.compact([
    ...(popUpData && popUpData.title === 'Backtest in Progress'
      ? [
          { value: 'Continue in Background', id: 'continue' },
          { value: 'Cancel Backtest', id: 'cancel_backtest' },
        ]
      : [
          { value: 'Yes', id: 'cancel_project' },
          { value: 'No', id: 'return' },
        ]),
  ]);

  const popupCancelOptions = lodash.compact([
    ...(popUpData && popUpData.title === 'Backtest in Progress'
      ? [
          { value: 'Yes', id: 'cancel_backtest' },
          { value: 'No', id: 'return' },
        ]
      : [
          { value: 'Yes', id: 'cancel_project' },
          { value: 'No', id: 'return' },
        ]),
  ]);

  // al salir del popup si es el de run backtest se muestra el modal
  // si es el del formulario previo a correr backtest simplemente se cierra
  const handlePopupClose = () => {
    if (
      popUpData?.title === 'Backtest in Progress' ||
      popUpData?.runBacktestUbication === 'optimized'
    ) {
      dispatch(cleanProject(token, true));
      setPopUpData(null);
      setShowModal(false);
      dispatch(cleanCache(token));
      dispatch(discardBacktest());
      if (location.pathname === '/optimized-index/optimized-index') {
        history.push('/optimized-index/optimized-index');
      } else if (!location.pathname.includes('analyzer')) {
        history.push('/');
      }
    }

    if (
      location.pathname.includes('step=Leveraged+Index') ||
      location.pathname.includes('step=Decrement+Index') ||
      location.pathname.includes('step=Risk+Control+Index')
    ) {
      setShowModalExit(true);
    }
    if (popUpData?.title === 'Index Backtest') {
      setShowModalExit(true);
    } else if (
      popUpOpen &&
      (popUpData?.runBacktestUbication === 'structured' ||
        popUpData?.runBacktestUbication === 'optimized' ||
        popUpData?.runBacktestUbication === 'analyzer')
    ) {
      dispatch(discardBacktest());
      setPopUpData(null);
      setShowModal(false);
    } else {
      setPopUpData(null);
    }
  };

  // Validar que el nombre del index no este ya guardado
  const validateNameIndex = data => {
    // check if the index name contains "/" and if it does, throw an toast error
    if (data['INDEX NAME'] && data['INDEX NAME'].includes('/')) {
      toast.error('The index name cannot contain "/"');
      return false;
    }

    if (data.name === '') {
      toast.error('Please, enter the name of your methodology.', { toastId: 'emptyName' });
      return false;
    }
    if (
      data?.['INDEX NAME'] &&
      statebacktestBackground &&
      Object.values(statebacktestBackground).filter(
        m =>
          m.data?.['INDEX NAME'] !== null &&
          m.data?.['INDEX NAME']?.toUpperCase() === data?.['INDEX NAME']?.toUpperCase(),
      ).length > 0
    ) {
      toast.error('The name is already in use. Please choose another one', {
        toastId: 'duplicatedName',
      });
      return false;
    }
    if (
      data?.['INDEX NAME'] &&
      stateApiData.Indexes?.data?.filter(
        m => m.name !== null && m.name?.toUpperCase() === data?.['INDEX NAME']?.toUpperCase(),
      ).length > 0
    ) {
      toast.error('The name is already in use. Please choose another one', {
        toastId: 'duplicatedName',
      });
      return false;
    }
    if (
      data?.['PORTFOLIO NAME'] &&
      stateApiData.Indexes?.data?.filter(
        m => m.name !== null && m.name?.toUpperCase() === data?.['PORTFOLIO NAME']?.toUpperCase(),
      ).length > 0
    ) {
      toast.error('The name is already in use. Please choose another one', {
        toastId: 'duplicatedName',
      });
      return false;
    }
    if (
      data?.['PORTFOLIO NAME'] &&
      statebacktestBackground &&
      Object.values(statebacktestBackground).filter(
        m =>
          m.data?.['INDEX NAME'] !== null &&
          m.status !== 'BACKTEST_CANCELLED' &&
          (m.data?.['INDEX NAME']?.toUpperCase() === data?.['PORTFOLIO NAME']?.toUpperCase() ||
            m.data?.['PORTFOLIO NAME']?.toUpperCase() === data?.['PORTFOLIO NAME']?.toUpperCase()),
      ).length > 0
    ) {
      toast.error('The name is already in use. Please choose another one', {
        toastId: 'duplicatedName',
      });
      return false;
    }
    return true;
  };

  const showmodalAbandoneProject = () => {
    if (
      location.pathname !== '/universe-builder/universe-builder/step=Universe+builder' &&
      !location.pathname.includes(
        '/structures-product/structures-product/step=STRUCTURED+INDEX+BUILDER',
      ) &&
      !location.pathname.includes('index-builders/index-builders/step') &&
      !(
        location.pathname.includes('/structures-product/structures-product/step') &&
        instrumentApply.length === 0
      )
    ) {
      setShowModalExit(true);
    } else if (location.pathname === '/index-builders/index-builders/step=Create+new+index') {
      setShowModalExit(true);
    } else if (
      location?.pathname?.includes('Setup') ||
      location?.pathname?.includes('Activate+in+calculation')
    ) {
      setShowModalExit(true);
      props.setModalState(false);
      history.push(location.pathname.split('/step')?.[0]);
    } else {
      setShowModal(false);
      history.push('/');
    }
  };

  return (
    <>
      <BitaModal
        isModalOpen={!displayBacktestModal && props.isModalOpen && showModal && !popUpData}
        setModalState={() => {
          showmodalAbandoneProject();
        }}
        style={{ display: visibleModal ? ' ' : 'none' }}
      >
        <div
          className={`${styles.modalContent} ${props.modalsmall &&
            styles.modalSmall} workflowModal closable_box ${props.header_icon}`}
        >
          {!props.modalsmall && (
            <>
              <div className="close_modal_x">
                <span
                  className={styles.closeModalButton}
                  onClick={() => {
                    showmodalAbandoneProject();
                  }}
                >
                  &times;
                </span>
              </div>

              <header
                className={styles.headerpaths}
                style={{ alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {props.header_icontext && <Imagen />}
                  {headerPath.map((path, index) => (
                    <span
                      key={path}
                      onClick={() => {
                        // al darle click a un elemento del breadcrumb se calcula cuantos pasos para
                        // atras hay que retroceder del componente actual, en base a ese nro de pasos
                        // el nuevo componente (la vista) viene a ser la propiedad  parent del componente
                        // actual tantas veces como pasos se retrocedan
                        if (props.header_icon === 'IndexModule') {
                          dispatch(
                            ApiDataActions.setApiData({
                              title: 'universeSelectedCard',
                              data: {},
                            }),
                          );
                          dispatch(
                            ApiDataActions.setApiData({
                              title: 'methodologySelectedCard',
                              data: {},
                            }),
                          );
                        }
                        const newComponent = getBackComponentsForSlider({ headerPath, index });
                        setComponent(newComponent);
                      }}
                    >
                      {path}
                    </span>
                  ))}
                </div>
                <Icons.Avanti style={{ marginRight: '40px', transform: 'scale(1.5)' }} />
              </header>
            </>
          )}
          <section
            className={`${styles.workflowCurrentComponent} ${
              component.text ? 'otherClass' : 'sectionnotText'
            }`}
          >
            {component.text && <h2 className={styles.componentText}>{component.text}</h2>}

            <div
              className={`${styles.cardsContainer} ${child.cards.length > 2 &&
                styles.cardsContainerBig} cardsContainerBig`}
            >
              {child.cards.map((comp, index) => (
                <Fragment key={`child_card_${comp.title}`}>
                  <BitaCard
                    className={styles.cardComponent}
                    cardType={BitaCard.UNIVERSE}
                    {...comp}
                    disabled={comp.title === 'Optimized Structured Index'}
                    withSelected={
                      comp.selected ? (
                        <BitaCardSelectedContent
                          icon={comp.icon}
                          {...comp.selected}
                          rebalancingMode={location.pathname.includes('rebalancing-reconstitution')}
                        />
                      ) : null
                    }
                    onClick={() => {
                      if (comp.onclick) {
                        const newComponent = comp.onclick;

                        if (newComponent.components) {
                          newComponent.components = Object.values(newComponent.components).map(
                            compChild => ({
                              ...compChild,
                              selected: null,
                            }),
                          );
                        }
                        setComponent({
                          ...newComponent,
                          parent: component,
                          parentWidget: comp,
                        });
                      }
                      if (comp.title === 'White Canvas')
                        applyToSummary({
                          'Universe Name': 'WHITE CANVAS',
                          'Nº of constituents': 1234,
                        });
                      if (comp.redirect) {
                        props.setModalState(false, true);
                        if (comp.template === 'Methodologies') {
                          const summaryData = defaultTemplate();
                          Object.keys(summaryData).forEach(widget => {
                            dispatch({
                              type: SummaryConstants.ADD_ENTRY,
                              payload: {
                                module: 'Index Builder',
                                widget,
                                data: summaryData[widget],
                              },
                            });
                          });
                        }
                        if (props.replaceWorkflow) props.replaceWorkflow(null);
                        history.push(comp.redirect);
                      }
                    }}
                  />
                  {index + 1 < child.cards.length && props.components_divisor ? (
                    <span className={styles.cardSeparator}>
                      {comp.title === 'Base Universe' ? 'and' : props.components_divisor.text}
                    </span>
                  ) : null}
                </Fragment>
              ))}
            </div>

            {/** renderiza componente imagen que pueda haber * */}
            {child.images.length ? (
              <div>
                {child.images.map(img => (
                  <div
                    onClick={() => {
                      if (img.redirect) {
                        props.setModalState(false);
                        history.push(img.redirect);
                        return;
                      }

                      // eslint-disable-next-line no-unused-expressions
                      props.onclick && popupHandleOnClick(props);
                    }}
                  >
                    <img
                      style={{ width: img.width, margin: 'auto' }}
                      src={`${process.env.PUBLIC_URL}/modal_images/${img.source}`}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            ) : null}
            {!!child.buttons.length && child.buttons.length > 0 && (
              <div className={styles.actionButtonsContainer}>
                {child.buttons.map(comp => (
                  <Fragment key={comp.title}>
                    <BitaButton
                      primary
                      autofocus={comp.title === 'Run Backtest'}
                      style={{
                        opacity:
                          comp.onclick.title === 'Index Backtest' ||
                          comp.onclick.title === 'REBALANCING & RECONSTITUTION'
                            ? (stateApiData.methodologySelected?.author_id ||
                                stateApiData.methodologySelected?.id) &&
                              (stateApiData.universeSelected?.File_name ||
                                stateApiData.universeSelected?.id)
                              ? '1'
                              : '0.5'
                            : '1',
                      }}
                      disabled={
                        comp.title === 'Run Rebalancing' &&
                        !(
                          (stateApiData.methodologySelected?.author_id ||
                            stateApiData.methodologySelected?.id) &&
                          (stateApiData.universeSelected?.File_name ||
                            stateApiData.universeSelected?.id)
                        )
                      }
                      className={styles.actionButton}
                      onClick={() => {
                        if (comp.onclick && comp.onclick.type === 'PopUp') {
                          if (comp.onclick.title === 'Index Backtest') {
                            if (
                              stateApiData.methodologySelectedCard?.id &&
                              stateApiData.universeSelectedCard?.id
                            ) {
                              dispatch(
                                ApiDataActions.setApiData({
                                  title: 'Optimized Index',
                                  data: {},
                                }),
                              );
                              setPopUpData(comp.onclick);
                            } else {
                              toast.error(
                                'Select any universe and methodology in order to quickly run your backtest.',
                              );
                            }
                          } else {
                            setPopUpData(comp.onclick);
                          }
                        }
                      }}
                    >
                      {comp.title}
                    </BitaButton>
                  </Fragment>
                ))}
              </div>
            )}
            {child.widgets.length ? (
              <div
                className="content content-fix"
                style={{ minHeight: !component.onapply ? ' 600px ' : '400px' }}
              >
                {child.widgets.map(widget =>
                  widget.type === 'SearchBarOnly' ? (
                    <BitaInstrumentSelectOnly
                      widget={widget}
                      key={widget.title}
                      onChange={onInstrumentSelect}
                    />
                  ) : widget.type === 'FactsheetBuilderComponent' ? (
                    <FactsheetBuilderComponent setPopUpData={setPopUpData} data={widget} />
                  ) : widget.type === 'Content' ? (
                    <BitaContentWidgetBuilder
                      widget={widget}
                      key={widget.title}
                      onChange={onInstrumentSelect}
                    />
                  ) : widget.type === 'ContentWidget' ? (
                    <BitaContentWidget
                      widget={widget}
                      key={widget.title}
                      onChange={onInstrumentSelect}
                    />
                  ) : // eslint-disable-next-line no-nested-ternary
                  widget.type === 'SearchBar' ? (
                    <BitaInstrumentSelect
                      widget={widget}
                      key={widget.title}
                      onChange={onInstrumentSelect}
                    />
                  ) : // eslint-disable-next-line no-nested-ternary
                  widget.type === 'Table' ? (
                    widget.data_api ? (
                      <GenerateTable
                        key={widget.data_api}
                        apiDataKey={widget.data_api}
                        headers={widget.data_api_headers}
                        onSelect={row => {
                          if (
                            widget.onselect &&
                            widget.onselect &&
                            widget.onselect.action === 'closeModal'
                          ) {
                            props.setModalState(false, true);
                            if (props.setSelectedRow) {
                              props.setSelectedRow(row);
                              history.push(`${generateRootPath()}`);
                            }
                            return;
                          }
                          if (component.parent && component.parentWidget) {
                            component.parentWidget.selected = row;
                            setComponent({ ...component.parent, selected: row });
                          }
                        }}
                        filters={widget?.filters}
                      />
                    ) : (
                      <GenerateTable
                        yamlData={{ ...widget.data }}
                        onSelect={row => {
                          if (
                            widget.onselect &&
                            widget.onselect &&
                            widget.onselect.action === 'closeModal'
                          ) {
                            props.setModalState(false, true);
                            if (props.setSelectedRow) {
                              props.setSelectedRow(row);
                            }
                            return;
                          }
                          if (component.parent && component.parentWidget) {
                            component.parentWidget.selected = row;
                            setComponent({ ...component.parent, selected: row });
                          }
                        }}
                      />
                    )
                  ) : widget.type === 'RebalancingReconstitutionSelectUniverse' ||
                    widget.type === 'RebalancingReconstitutionSelectMethodology' ? (
                    <RebalacingReconstitutionSelectUniverses
                      type={widget.type.includes('Universe') ? 'universe' : 'methodology'}
                      onclickMethodology={() => {
                        const summaryData = defaultTemplate();
                        dispatch({
                          type: SummaryConstants.ADD_ENTRY,
                          payload: {
                            module: 'Index Builder',
                            widget: 'Type Methodology',
                            data: 'Rebalancing and Reconstitution',
                          },
                        });
                        Object.keys(summaryData).forEach(item => {
                          if (item !== 'Rebalancing & Reconstitution') {
                            dispatch({
                              type: SummaryConstants.ADD_ENTRY,
                              payload: {
                                module: 'Index Builder',
                                widget: item,
                                data: summaryData[item],
                              },
                            });
                          }
                        });
                      }}
                      onSelect={row => {
                        if (
                          widget.onselect &&
                          widget.onselect &&
                          widget.onselect.action === 'closeModal'
                        ) {
                          props.setModalState(false, true);
                          if (props.setSelectedRow) {
                            props.setSelectedRow(row);
                            history.push(`${generateRootPath()}`);
                          }
                          return;
                        }
                        if (component.parent && component.parentWidget) {
                          component.parentWidget.selected = row;
                          setComponent({ ...component.parent, selected: row });
                        }
                        if (row.File_name) {
                          dispatch(
                            ApiDataActions.setApiData({
                              title: 'universeSelected',
                              data: row,
                            }),
                          );
                        }
                      }}
                    />
                  ) : (
                    `Unimplemented widget "${widget.type}"`
                  ),
                )}
              </div>
            ) : null}
          </section>
          <footer>
            {component.onapply ? (
              <BitaButton
                className={styles.applyButton}
                disabled={disabledOnapply}
                onClick={() => {
                  hitApply(component.onapply, component);
                }}
                primary
                autofocus={disabledOnapply}
                Ref={applyRef}
              >
                Apply
              </BitaButton>
            ) : null}
          </footer>
        </div>
      </BitaModal>

      {/** POPUP ==================================================================================================== * */}

      <BitaModal
        isModalOpen={popUpData && popUpData?.title !== 'Backtest in Progress'}
        setModalState={handlePopupClose}
        customStyle={{
          backgroundColor:
            popUpData?.title !== 'PREPARING FACTSHEET FOR DOWNLOAD' ? 'rgb(42, 45, 49)' : '#2962ff',
        }}
      >
        {popUpData && (
          <div
            data-simplebar
            style={{
              height: popUpData.title !== 'PREPARING FACTSHEET FOR DOWNLOAD' ? '95vh' : '100%',
              maxHeight: popUpData.title === 'REBALANCING & RECONSTITUTION' ? '600px' : '',
              width: '589px',
            }}
          >
            <div
              style={{ width: '589px' }}
              className={`popup-${
                props.runBacktestUbication === 'structured' ||
                location.pathname.includes('step=Leveraged+Index') ||
                location.pathname.includes('step=Decrement+Index') ||
                location.pathname.includes('step=Risk+Control+Index')
                  ? 'structured'
                  : ''
              }`}
            >
              <div
                className={`${styles.popUpContainer} popUpContainer`}
                style={{
                  height:
                    popUpData.title === 'Index Backtest'
                      ? props.runBacktestUbication === 'structured' ||
                        location.pathname.includes('step=Leveraged+Index') ||
                        location.pathname.includes('step=Decrement+Index') ||
                        location.pathname.includes('step=Risk+Control+Index')
                        ? '540px'
                        : '640px'
                      : '',
                }}
              >
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div className="close_modal_x">
                  <span
                    className={`${styles.closeModalButton} closebutton`}
                    onClick={handlePopupClose}
                    // rol="button"
                  >
                    &times;
                  </span>
                </div>
                <header className={styles.headerpaths}>
                  <div>
                    {popUpData.title !== 'PREPARING FACTSHEET FOR DOWNLOAD' && <ImagenPopUp />}
                    <span style={{ fontSize: '20px', marginLeft: '0px' }}>
                      {!(popUpData.title === 'Backtest in Progress')
                        ? props.runBacktestUbication
                        : ' '}{' '}
                      {popUpData.title}
                    </span>
                  </div>
                  <Icons.Avanti style={{ marginRight: '20px', transform: 'scale(1.3)' }} />
                </header>
                <Builder
                  runBacktest={(data, onclick) => {
                    if (validateNameIndex(data)) {
                      if (popUpData.runBacktestUbication === 'structured') {
                        const structureIndexBacktest =
                          stateSummary?.['Structured Index']?.['Instrument Selected'];
                        dispatch(
                          ApiDataActions.setApiData({
                            title: 'Structured Index Backtest',
                            data: structureIndexBacktest,
                          }),
                        );
                        dispatch(
                          ApiDataActions.setApiData({
                            title: 'indexBacktest',
                            data: {
                              'Index Backtest': {
                                'BASE VALUE': 1000,
                                Benchmark: structureIndexBacktest,
                              },
                            },
                          }),
                        );
                        // runStructureBacktest(data, onclick);
                        runBacktest(data, onclick);
                      } else if (!(popUpData.title === 'Backtest in Progress')) {
                        runBacktest(data, onclick);
                        dispatch(
                          ApiDataActions.setApiData({
                            title: 'universeSelectedCard',
                            data: {},
                          }),
                        );
                        dispatch(
                          ApiDataActions.setApiData({
                            title: 'methodologySelectedCard',
                            data: {},
                          }),
                        );
                      }
                    }
                  }}
                  handlePopupClose={handlePopupClose}
                  popupHandleOnClick={popupHandleOnClick}
                  {...getRunBacktestComponent()}
                />
              </div>
            </div>
          </div>
        )}
      </BitaModal>

      {/* <BacktestInProgressModal
        handlePopupClose={handlePopupClose}
        ImagenPopUp={ImagenPopUp}
        runBacktest={runBacktest}
      /> */}

      {/*      <RunningBacktestPopupOLD
        popUpData={popUpData}
        setPopUpData={setPopUpData}
        handlePopupClose={}
        ImagenPopUp={ImagenPopUp}
        runBacktestUbication={props.runBacktestUbication}
      /> */}
      <BitaExitWorkflowModal
        isModalOpen={showModalExit}
        popupCloseOptions={popupCloseOptions}
        workflowSection={popUpData?.title !== 'Index Backtest' ? 'running' : 'before_run'}
        handleOptionsMenuCloseModal={handleOptionsMenuCloseModal}
      />
    </>
  );
};

export default WorkflowModal;
