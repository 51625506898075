import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
// eslint-disable-next-line import/no-unresolved
import CsvDownloader from 'react-csv-downloader';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import axios from 'axios';
import ConfigYAML from '../../config/ConfigYAML';
import * as CommonActions from '../../state/actions/common-actions';
import * as ApiDataActions from '../../state/actions/api-data-actions';
import * as SummaryConstants from '../../constants/summary-constants';
import PageLayout from '../../components/Layout/PageLayout';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import BitaSelect from '../../components/BitaSelect/BitaSelect';
import Builder from '../../builder/Builder';
import style from './PortfolioBasketCalculation.module.scss';
import { IconsModules } from '../../constants/icons-module';
import { Icons } from '../../constants/icons';
import RunBacktestModal from '../runBacktestModal';
import { toastInterceptor } from '../../utils/utils';
import { setExcludeIsin } from '../../state/actions/module-upload-actions';
import './Portfolio.css';

const uploadFileSettings = (tokenUser, file) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/upload-file`, file, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${tokenUser}` },
  });
};

const PortfolioBasketCalculatio = () => {
  const modalRef = React.useRef([]);
  const [modalOpen, setModalOpen] = React.useState(true);
  const [exludeIsinsModalOpen, setExludeIsinsModalOpen] = React.useState(false);
  const [files, setFiles] = React.useState('');
  const token = useSelector(state => state.auth.token);
  const excludesIsin = useSelector(state => state.upload.exclude_isins);
  const apidDataBaskets = useSelector(state => state.apiData.apiData.Baskets);
  const summary = useSelector(state => state.summary);
  const [dataPortfolio, setDataPortfolio] = React.useState({});
  const [basketSelected, setBasketSelected] = React.useState(false);
  const [disabledRunBacktest, setDisabledRunBacktest] = React.useState(true);
  const portfolioBasketCalculation = ConfigYAML.jsonPath('$.portfolio_basket_calculation');
  const [cachedBaskets, setCachedBaskets] = React.useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  dispatch(CommonActions.resetSubheader());
  dispatch(CommonActions.appendSubheader('Portfolio / Basket Backtesting'));

  React.useEffect(() => {
    document.title = `${process.env.REACT_APP_SITE_TITLE} | Portfolio / Basket Backtesting`;
    dispatch(ApiDataActions.setBaskets(token));
  }, []);

  React.useEffect(() => {
    if (excludesIsin.length > 0) {
      setExludeIsinsModalOpen(true);
    }
  }, [excludesIsin]);

  React.useEffect(() => {
    if (
      summary?.Portfolio?.Portfolio?.Currency &&
      summary?.Portfolio?.Portfolio?.['Return calculation']
    ) {
      setDisabledRunBacktest(false);
    } else {
      setDisabledRunBacktest(true);
    }
  }, [summary]);

  React.useEffect(() => {
    if (excludesIsin.length > 0) {
      setExludeIsinsModalOpen(true);
    }
  }, [excludesIsin]);

  const sendSummary = state => {
    setDataPortfolio(old => ({ ...old, ...state }));
  };

  // check if the file name is in the array
  const isInArray = (basketArr, fileInfo) => {
    if (basketArr.some(item => item.name === fileInfo.name.split('.')[0])) {
      return true;
    }
    return false;
  };
  // check if the file name was uploaded in the same modal session
  const isCached = fileInfo => {
    if (cachedBaskets.some(item => item === fileInfo.name)) {
      return true;
    }
    return false;
  };

  const columns = [
    {
      id: 'isin',
      displayName: 'ISIN',
    },
  ];

  return (
    <PageLayout>
      <div>
        {(basketSelected?.id || summary?.Portfolio?.PortfolioSelected?.id) &&
          Object.values(portfolioBasketCalculation.parameters).map(parameter => (
            <Builder
              key={parameter.title}
              universe="Portfolio"
              currentView="Portfolio"
              onApply={sendSummary}
              onChange={sendSummary}
              widgetData={dataPortfolio}
              {...parameter}
            />
          ))}
      </div>
      <BitaModal
        isModalOpen={modalOpen}
        setModalState={() => {
          history.push('/');
          dispatch(setExcludeIsin([]));
        }}
        className="contentPortfolio"
      >
        <div className={`${style.modalPortfolio} contentPortfolio`}>
          <div className="close" onClick={() => history.push('/')}>
            &times;
          </div>
          <div className="titleModal" style={{ marginTop: '-10px' }}>
            <IconsModules.PortfolioBasketModule />
            <span className={style.headerpath}> Portfolio / Basket Backtesting</span>
          </div>
          <div className="contentModalExcludeIsin " style={{ height: 'calc(100% - 110px' }}>
            <div className={style.content}>
              <h2 className="instrument_selection_titles">
                <div>
                  <Icons.parameters />
                  Templates & Data Upload
                </div>
              </h2>
              <div className={style.dataUpload}>
                <div className={style.contentTemplate}>
                  <div className={style.subtitle}>TEMPLATES</div>
                  <BitaButton
                    primaryWhite
                    width="140px"
                    height="35px"
                    onClick={() => {
                      FileSaver.saveAs(
                        `${process.env.PUBLIC_URL}/files/Portfolio_Template.xlsx`,
                        `Portfolio_Template.xlsx`,
                      );
                    }}
                  >
                    Portfolio Template
                  </BitaButton>
                </div>
                <div>
                  <div className={style.subtitle}>
                    DATA UPLOADER <p className={style.nameFile}>{files && `(${files.name})`}</p>
                  </div>
                  <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
                    <BitaSelect
                      key="Portfolio"
                      label="Portfolio"
                      width="140px"
                      style={{ marginRight: '15px' }}
                      data={[{ id: 'Portfolio', value: 'Portfolio' }]}
                      handleSelection={selected => {}}
                    />
                    <input
                      type="file"
                      onChange={event => {
                        setFiles(event.target.files[0]);
                      }}
                      name="file"
                      id="file"
                      className={style.inputfile}
                    />
                    <label htmlFor="file">Select</label>
                    <BitaButton
                      disabled={!files}
                      onClick={() => {
                        const formData = new FormData();
                        formData.append('template', files);
                        formData.append('type', 'basket');
                        if (files && !isInArray(apidDataBaskets.data, files) && !isCached(files)) {
                          uploadFileSettings(token, formData)
                            .then(rsp => {
                              toast(`${rsp.data.message}`);
                              if (rsp.data.excludes_isin) {
                                dispatch(setExcludeIsin(rsp.data.excludes_isin));
                              }
                              if (rsp.data.portfolio_id) {
                                const widget = 'PortfolioSelected';
                                const dataPortfoliocopy = {
                                  id: rsp.data.portfolio_id,
                                  value: rsp.data.portfolio_name,
                                  first_date: rsp.data.first_date,
                                };
                                dispatch(
                                  ApiDataActions.setApiData({
                                    title: 'PortfolioSelected',
                                    data: dataPortfoliocopy,
                                  }),
                                );
                                dispatch({
                                  type: SummaryConstants.ADD_ENTRY,
                                  payload: { module: 'Portfolio', widget, data: dataPortfoliocopy },
                                });
                                setBasketSelected(dataPortfoliocopy);
                                setCachedBaskets(prevArray => [...prevArray, files.name]);
                              }
                            })
                            .catch(error => {
                              toastInterceptor(error);
                            });
                        } else {
                          toast.error(
                            'Please upload a portfolio template with different file name.',
                          );
                        }
                      }}
                      primary
                      width="140px"
                      height="35px"
                      style={{ margin: 'auto' }}
                    >
                      Upload
                    </BitaButton>
                    <BitaSelect
                      key="Baskets"
                      label="Uploaded Portfolios"
                      width="200px"
                      style={{ marginLeft: '15px' }}
                      data={
                        apidDataBaskets?.data?.map(field => ({
                          id: field.id,
                          value: field.name,
                          date: field.first_date,
                          ...field,
                        })) || []
                      }
                      value={basketSelected}
                      handleSelection={selected => {
                        setBasketSelected({
                          ...selected,
                          first_date: selected?.date?.split('T')[0],
                        });
                        const widget = 'PortfolioSelected';
                        dispatch(
                          ApiDataActions.setApiData({
                            title: 'PortfolioSelected',
                            data: { ...selected, first_date: selected?.date?.split('T')[0] },
                          }),
                        );
                        dispatch({
                          type: SummaryConstants.ADD_ENTRY,
                          payload: {
                            module: 'Portfolio',
                            widget,
                            data: { ...selected, first_date: selected?.date?.split('T')[0] },
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              {basketSelected?.value && (
                <div className={style.viewPortfolio}>
                  <div className={style.searchResultColumn}>
                    <div className={style.searchResultHeadColumn}>PORTFOLIO Name</div>
                    <div>{basketSelected?.value}</div>
                  </div>
                  <BitaButton
                    onClick={() => {
                      setBasketSelected('');
                      const widget = 'PortfolioSelected';
                      dispatch(
                        ApiDataActions.setApiData({
                          title: 'PortfolioSelected',
                          data: '',
                        }),
                      );
                      dispatch({
                        type: SummaryConstants.ADD_ENTRY,
                        payload: { module: 'Portfolio', widget, data: '' },
                      });
                    }}
                    primaryWhite
                    className={style.deleteResult}
                  >
                    Delete
                  </BitaButton>
                </div>
              )}
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <BitaButton
                onClick={() => {
                  setModalOpen(false);
                  // modalRef.current.runModal();
                }}
                disabled={!basketSelected?.value}
                primary
                style={{ margin: 'auto' }}
              >
                Apply
              </BitaButton>
              <RunBacktestModal
                ref={modalRef}
                ubication="portfolio"
                disable_title={false}
                showData
              />
            </div>
          </div>
        </div>
      </BitaModal>
      <BitaModal
        isModalOpen={exludeIsinsModalOpen}
        setModalState={() => {
          dispatch(setExcludeIsin([]));
          setExludeIsinsModalOpen(false);
        }}
      >
        <div className="modalExcludeIsin " style={{ height: '440px' }}>
          <div
            className="close"
            onClick={() => {
              dispatch(setExcludeIsin([]));
              setExludeIsinsModalOpen(false);
            }}
          >
            &times;
          </div>
          <div className="titleModal">
            {' '}
            <Icons.Avanti />
            ISIN VALIDATION
          </div>
          <div className="contentModalExcludeIsin ">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {excludesIsin.length} ISINs were not found in the database:
              <CsvDownloader
                filename="ISINs-No-found"
                separator=";"
                datas={
                  excludesIsin &&
                  excludesIsin?.map(item => {
                    return { isin: item };
                  })
                }
                columns={columns}
              >
                <Icons.download />
              </CsvDownloader>
            </div>
            <div className="isinContent" data-simplebar>
              <div className="isins" style={{ display: 'flex', flexWrap: 'wrap' }}>
                {excludesIsin && excludesIsin.map(isin => <p>{isin}</p>)}
              </div>
            </div>
            <div style={{ width: '100%', alignItems: 'left' }}>
              Do you agree to continue with this selection?{' '}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <BitaButton
                width="260px"
                height="35px"
                primary
                style={{ marginRight: '10px' }}
                onClick={() => {
                  setExludeIsinsModalOpen(false);
                  dispatch(setExcludeIsin([]));
                }}
              >
                Confirm
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>
    </PageLayout>
  );
};

export default PortfolioBasketCalculatio;
