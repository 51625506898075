import * as ACTIONS from '../../constants/sidebar-constants';

export const activateModule = moduleName => {
  return { type: ACTIONS.ACTIVATE_MODULE, payload: { moduleName } };
};

export const deactivateModule = moduleName => {
  return { type: ACTIONS.DEACTIVATE_MODULE, payload: { moduleName } };
};

export const setModuleState = (moduleName, moduleState) => {
  return { type: ACTIONS.SET_MODULE_STATE, payload: { moduleName, moduleState } };
};

export const showChildren = moduleName => {
  return { type: ACTIONS.SHOW_CHILDREN, payload: { moduleName } };
};

export const hideChildren = moduleName => ({
  type: ACTIONS.HIDE_CHILDREN,
  payload: { moduleName },
});

export const setSelectedModule = moduleName => {
  return { type: ACTIONS.SET_SELECTED_MODULE, payload: { moduleName } };
};
