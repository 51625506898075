import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
// import { NavContext } from '../../react-sidenav-bita-custom/index';
import { useCurrentModule } from '../../../bitaHooks';
import styles from '../SideNavbar.module.scss';
import { excludeUser } from '../../../constants/exclude-modules-user';
import ModalAbandoneProject from '../../BitaModal/ModalabandoneProject';

const NavItem = withRouter(props => {
  // const context = React.useContext(NavContext);
  const { to, title, banner } = props;
  const [visibleConfirmation, setVisibleConfirmation] = React.useState(null);
  let status = 'ACTIVE';

  const summaryProyect = Object.keys(useSelector(state => state.summary));
  const summary = useSelector(state => state.summary?.['Universe Builder']?.['Filter Group 1']);
  const userPlan = {};
  // eslint-disable-next-line array-callback-return
  const moduls = useSelector(state => state.auth.plan.modules);
  const company = useSelector(state => state.auth.company);
  const apiData = useSelector(state => state.apiData.apiData);
  const userdata = useSelector(state => state.auth.userdata);
  const typeMethodology = useSelector(state => state.common.subheader);

  if (moduls) {
    moduls.forEach(module => {
      userPlan[module.name] = module;
    });
  }

  const location = useLocation();
  const currentModuleUrl = location.pathname.split('/')[1];

  if (currentModuleUrl && Object.values(userPlan).length > 0) {
    // eslint-disable-next-line no-param-reassign
    const historyPlan = {};
    historyPlan.module = currentModuleUrl.replace('-', ' ').toUpperCase();
    if (userPlan?.[historyPlan.module]?.functionalities?.length > 0) {
      userPlan[historyPlan.module].functionalities.forEach(functionality => {
        if (functionality?.name?.toUpperCase() === title.toUpperCase()) {
          status = functionality.status_plan !== undefined ? functionality.status_plan : 'ACTIVE';
        }
      });
    }
    if (userPlan?.[historyPlan.module]?.sub_modules?.length > 0) {
      userPlan[historyPlan.module].sub_modules.forEach(subModule => {
        if (subModule.name.toUpperCase() === title.toUpperCase()) {
          status = subModule.status_plan !== undefined ? subModule.status_plan : 'ACTIVE';
        }
      });
    }
  }

  if (
    (title.toUpperCase() === 'ATTRIBUTION' ||
      title.toUpperCase() === 'CHARACTERISTICS' ||
      title.toUpperCase() === 'ESG ANALYTICS') &&
    _.get(apiData, ['Current Index Selected', 'data', 'index_type'], false) === 'structured_index'
  ) {
    status = company !== 'BITA GMB' ? 'HIDDEN' : 'INACTIVE';
  }

  if (
    (summary?.Themes?.operator_type && title === 'iClima Themes') ||
    (summary?.['iClima Themes']?.operator_type && title === 'Themes')
  ) {
    status = 'INACTIVE';
  }

  if (
    (title.toUpperCase() === 'PERFORMANCE AND RISK' || title.toUpperCase() === 'ESG ANALYTICS') &&
    _.get(apiData, ['Current Index Selected', 'data', 'index_type'], false) === 'rebalancing_module'
  ) {
    status = 'HIDDEN';
  }

  let selected;
  if (props.selected === true || props.selected === false) {
    selected = props.selected ? styles.selected : '';
  } else {
    selected = props.location.pathname === props.to ? styles.selected : '';
  }

  const selectedIndicator = selected ? styles.subtitleIndicator_selected : '';
  // const { isLeaf } = context;

  const item = () => {
    return (
      <div className={classNames(styles.navItem, selected)}>
        {!props.icon ? (
          title !== 'ESG Rating' &&
          title !== 'Emissions' &&
          title !== 'Waste' &&
          to !== '/index-analyzer/esg-issues' &&
          title !== 'Emissions & Waste' ? (
            <div className={classNames(styles.subtitleIndicator, selectedIndicator)} />
          ) : (
            <> </>
          )
        ) : title !== 'ESG Rating' &&
          title !== 'Emissions' &&
          title !== 'Waste' &&
          to !== '/index-analyzer/esg-issues' &&
          title !== 'Emissions & Waste' ? (
          <props.icon />
        ) : (
          <> </>
        )}
        <div className={styles.textContent}>
          {title.toUpperCase() === 'ATTRIBUTION' &&
          _.get(apiData, ['Current Index Selected', 'data', 'index_type'], false) ===
            'rebalancing_module'
            ? 'Style Factor Tilts'
            : typeMethodology !== 'INDEX BUILDER' && title === 'Rebalancing & Reconstitution'
            ? 'Caps, Floors & Buffer'
            : title}
        </div>
        {banner && <div className={styles.banner}>{banner}</div>}
      </div>
    );
  };
  if (status === 'HIDDEN') {
    return <> </>;
  }
  if (
    ((summaryProyect.length > 0 ||
      (apiData?.['Optimized Index'] && Object.keys(apiData?.['Optimized Index'])?.length > 0)) &&
      to.split('/')[1] !== currentModuleUrl) ||
    status === 'INACTIVE'
  ) {
    return (
      <div
        style={{
          pointerEvents: status === 'INACTIVE' ? 'none' : ' ',
          opacity: status === 'INACTIVE' ? '0.5' : '1',
          display:
            status === 'HIDDEN' ||
            (excludeUser[userdata?.company?.name] &&
              excludeUser[userdata?.company?.name]?.[title?.toLowerCase()])
              ? 'none'
              : ' ',
        }}
      >
        <div
          onClick={() => {
            setVisibleConfirmation(true);
          }}
        >
          {item()}
        </div>
        <ModalAbandoneProject
          visibility={visibleConfirmation}
          to={to}
          close={() => setVisibleConfirmation(false)}
        />
      </div>
    );
  }
  return (
    <Link
      className={selected && styles.selectedItem}
      to={
        typeMethodology !== 'INDEX BUILDER' && title === 'Rebalancing & Reconstitution'
          ? '/index-builders/parametrization/caps-floors-buffer'
          : to
      }
    >
      {item()}
    </Link>
  );
});

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
};

NavItem.defaultProps = {
  to: '/',
};

export default NavItem;
