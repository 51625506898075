import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lodash from 'lodash';
import { setApiData } from '../../../../state/actions/api-data-actions';
import * as SummaryConstants from '../../../../constants/summary-constants';
import { getDataComponents } from '../../../../api-data-mapping';
import BitaTable from '../../../../bitaComponents/BitaTable/BitaTable';
import { loaderDataObjectDecorator } from '../../../../utils/loading_status_tools';
import style from './table-template.module.scss';

export const GenerateTable = ({
  yamlData = null,
  apiDataKey = 'Universes',
  headers = [],
  onSelect,
  filterAuthor = true,
  search = true,
  height = false,
  rebalancing = false,
  filters,
  ...props
}) => {
  const data = yamlData && Object.values(yamlData);
  const auth = useSelector(state => state.auth);
  const stateApiData = useSelector(state => state.apiData);
  const [activeTab, setActiveTab] = React.useState('Universe');
  const apiData = lodash.get(stateApiData, `apiData[${apiDataKey}].data`, []);
  // const apiData = useSelector(state => state.apiData.apiData[apiDataKey].data);
  const dispatch = useDispatch();

  const columns = [];
  const apiColumns = Object.values(headers);

  const porcentage = nro => {
    if (nro) {
      const n = nro * 100;
      return n % 1 !== 0 ? n.toFixed(2) : n;
    }
    return false;
  };

  const results =
    data &&
    Object.values(data[0].rows).reduce((result, _, index) => {
      const row = {};
      Object.values(data).forEach(column => {
        if (column.rows) {
          row[column.column_name] = column.rows[index];
          columns.push(column.column_name);
        }
      });
      return result.concat(row);
    }, []);

  const selectAction = async row => {
    if (onSelect) {
      if (apiDataKey === 'Methodologies') {
        dispatch(
          setApiData({
            title: 'methodologySelected',
            data: { ...row.rules, id: row[1] },
          }),
        );
        dispatch(
          setApiData({
            title: 'methodologySelectedCard',
            data: { ...row.rules, id: row[1] },
          }),
        );
        const summaryData = row.rules.front_parameters;
        Object.keys(summaryData).forEach(widget => {
          dispatch({
            type: SummaryConstants.ADD_ENTRY,
            payload: { module: 'Index Builder', widget, data: summaryData[widget] },
          });
        });
      }
      if (apiDataKey === 'EditUniverses') {
        const dataComponent = getDataComponents.filter(
          v =>
            v.key === 'universeSelected' &&
            v.title === 'universeSelected' &&
            v.type === 'universeSelected',
        )[0];
        if (typeof dataComponent !== 'undefined') {
          await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(row.id, auth.token);
        }
        const SummaryUniverse = row.front_parameters;
        Object.keys(row.front_parameters).forEach(filterGroup => {
          Object.keys(SummaryUniverse[filterGroup]).forEach(filter => {
            dispatch({
              type: SummaryConstants.ADD_ENTRY,
              payload: {
                module: 'Universe Builder edit',
                widget: `${filterGroup}.${filter}`,
                data: SummaryUniverse[filterGroup][filter],
              },
            });
          });
        });
      }
      if (apiDataKey === 'Universes') {
        const dataComponent = getDataComponents.filter(
          v =>
            v.key === 'universeSelected' &&
            v.title === 'universeSelected' &&
            v.type === 'universeSelected',
        )[0];
        if (typeof dataComponent !== 'undefined') {
          await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(row.id, auth.token);
        }
      }
      if (apiDataKey === 'AllUniverses' || rebalancing) {
        dispatch(
          setApiData({
            title: 'universeSelected',
            data: row,
          }),
        );
        dispatch(
          setApiData({
            title: 'universeSelectedCard',
            data: row,
          }),
        );
      }
      onSelect(row);
    }
    if (row.name) {
      const widget = 'Methodology Name';
      dispatch({
        type: SummaryConstants.ADD_ENTRY,
        payload: { module: 'Index Builder', widget, data: row.name },
      });
    }
  };
  const formatApiData = (dataToFormat, key, typeTemplate) => {
    if (key === 'AllUniverses') {
      const rest = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const dataEntry of dataToFormat) {
        const mem = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const [fieldKey, value] of Object.entries(dataEntry)) {
          if (fieldKey === 'filter') mem.base_universe = value ? value.base_universe : '';
          else mem[fieldKey] = value;
        }
        rest.push(mem);
      }
      return auth.userdata.id_company === '647f76d5493c6d001363bede'
        ? rest.filter(universe => universe.author !== 'BITA')
        : rest;
    }
    if (key === 'Methodologies') {
      // TODO refactor for a less functional style
      return dataToFormat.map(dataEntry =>
        Object.entries(dataEntry).reduce((obj, [fieldKey, value]) => {
          if (fieldKey === 'caps') return { ...obj, caps: `${porcentage(value) || '0'}%` };
          if (fieldKey === 'floors') return { ...obj, floors: `${porcentage(value) || '0'}%` };
          return { ...obj, [fieldKey]: value };
        }),
      );
    }
    if (key === 'Universes') {
      const universeData = dataToFormat.filter(entry => entry.portfolio_type_name === typeTemplate);
      return auth.userdata.id_company === process.env.REACT_APP_SP_ID
        ? universeData.filter(
            universe =>
              universe.name === 'BITA Total Market Universe' ||
              universe.name === 'BITA_Global_Universe_A_SP',
          )
        : auth.userdata.id_company === process.env.REACT_APP_ZBK_ID ||
          auth.userdata.id_company === process.env.REACT_APP_ARCLAY_ID
        ? universeData.filter(universe => universe.id === 12500)
        : universeData.filter(
            universe =>
              universe.name !== 'BITA Total Market Universe' &&
              universe.name !== 'BITA_Global_Universe_A_SP',
          );
    }
    return dataToFormat;
  };

  return !yamlData ? (
    <div>
      {filters && (
        <div className={style.contentTab}>
          {Object.values(filters).map((tabTitle, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            // eslint-disable-next-line
            <div
              key={`generate-table-filter-${index}`}
              className={`${style.tabTitleButton} ${
                tabTitle.option === activeTab
                  ? style.tabTitleButtonActive
                  : style.tabTitleButtonInactive
              } ${tabTitle.disabled ? style.tabDisabled : ''}`}
              onClick={() => setActiveTab(tabTitle.option)}
            >
              {tabTitle.value}
            </div>
          ))}
        </div>
      )}
      <BitaTable
        applyButton
        selectAction={selectAction}
        data={formatApiData(apiData, apiDataKey, activeTab)}
        title=""
        typeElementTable={apiDataKey}
        showFields={apiColumns}
        filterAuthor={filterAuthor}
        search={search}
        orderByTable="created_at"
        key={apiDataKey}
        height={height}
      />
    </div>
  ) : (
    <BitaTable
      applyButton
      selectAction={selectAction}
      data={results}
      typeElementTable={apiDataKey}
      title=""
      showFields={columns}
      filterAuthor={filterAuthor}
      search={search}
      orderByTable="created_at"
      key={apiDataKey}
      height={height}
    />
  );
};
