/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import BitaCheckbox from './lib/MultiSelectCheckbox';
import './BitaMultiSelectArea.scss';
import Spinner from '../BitaSpinner/BitaSpinner';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';

const BitaMultiSelectArea = props => {
  let componente = false;
  const {
    data,
    handleChange,
    selectButtonsEnabled,
    allSelected,
    filterHelper,
    filterField,
    widgetData,
    currentModule,
  } = props;
  // La data que recibe puede ser un array de opciones o un objeto con propiedades y el valor de cada propiedad ser un array con opciones
  const [allChecked, setAllChecked] = useState(allSelected);
  const [checkboxUpdate, setCheckBoxUpdate] = useState(false);
  const [dataReturnArray, setDataReturnArray] = useState([]);
  const [isDisabledDeselectAllButton, setIsDisableDeselectAllButton] = useState(true);
  const [externalFilteredCountries, setExternalFilteredCountries] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const initialData = Array.isArray(data ?? []) ? data ?? [] : [].concat(...Object.values(data));

  useEffect(() => {
    handleChange([]);
  }, []);

  useEffect(() => {
    const sendData = [...(dataReturnArray ?? []).filter(item => item.value)];
    handleChange(sendData);
  }, [dataReturnArray]);

  useEffect(() => {
    if (dataReturnArray && allSelected && Array.isArray(data))
      setDataReturnArray(data.map(field => ({ value: field.option, id: field.id })));
    if (dataReturnArray && !allSelected) setDataReturnArray([]);
  }, []);
  useEffect(() => {
    if (!widgetData && dataReturnArray.length > 0) {
      setDataReturnArray([]);
      setAllChecked(false);
    }
    if (widgetData) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [widgetData]);
  const toggleAllCheckboxes = status => {
    const dataALl = [].concat(...Object.values(data ?? [])).filter(item => !item?.hidden);
    setDataReturnArray(
      dataALl.map(option => {
        return { value: option.option, id: option.id };
      }),
    );
    setCheckBoxUpdate(prev => !prev);
    if (status !== allChecked) {
      setAllChecked(status);
    }
    if (!status) {
      if (dataReturnArray) setDataReturnArray([]);
    }
  };

  useEffect(() => {
    if (allChecked) {
      const dataALl = [].concat(...Object.values(data ?? {})).filter(item => !item?.hidden);
      if (dataALl.length !== dataReturnArray.length) {
        setDataReturnArray(
          dataALl.map(option => {
            return { value: option.option, id: option.id };
          }),
        );
      }
    }
  }, [data, allChecked]);

  const isChecked = option => {
    return widgetData && widgetData.length > 0
      ? widgetData.find(val => val.id === option.id)
      : dataReturnArray.find(val => val.id === option.id);
  };

  const handleDeselectAllButton = (checked, option) => {
    if (filterHelper?.length > 0 && !filterHelper?.includes('Global')) {
      const isNotInFilteredCountries = filterHelper.every(
        appliedFilter => appliedFilter !== option?.classification,
      );

      setExternalFilteredCountries(prevArray => {
        if (isNotInFilteredCountries && checked.value) {
          return [...prevArray, option.option];
        }
        return prevArray.filter(item => item !== option.option);
      });

      const notFilteredUnselectedCheckbox = !checked.value && isNotInFilteredCountries;

      const shouldDisableDeselectAllButton =
        externalFilteredCountries.length === 0 &&
        (!isNotInFilteredCountries || notFilteredUnselectedCheckbox);

      // eslint-disable-next-line no-unused-expressions
      !allChecked
        ? setIsDisableDeselectAllButton(shouldDisableDeselectAllButton)
        : setIsDisableDeselectAllButton(false);
    } else if (dataReturnArray.length > 0 && !filterHelper?.includes('Global')) {
      setIsDisableDeselectAllButton(false);
    } else {
      setIsDisableDeselectAllButton(true);
    }
  };

  // Treatment of data to return when the initial data is an array
  const handleChangeArray = (checked, option) => {
    if (currentModule?.includes('Geography')) {
      handleDeselectAllButton(checked, option);
    }
    setDataReturnArray(prevData => {
      if (checked.value) {
        // Add the option if it's not already in the array
        if (!prevData.some(item => item.id === option.id)) {
          return [
            ...prevData.filter(item => initialData.some(optionItem => optionItem.id === item.id)),
            { value: option.option, id: option.id },
          ];
        }
      } else {
        // Remove the option if it exists in the array
        return prevData.filter(item => item.id !== option.id);
      }
      return prevData;
    });
  };

  // eslint-disable-next-line func-names
  if (Array.isArray(data)) {
    componente = [];
    // eslint-disable-next-line func-names
    componente = data.map(function(option) {
      let element;
      if (Array.isArray(option.value) && option.value.length) {
        element = (
          <div>
            <div style={{ fontWeight: '600', margin: '4px 0' }}>{option.option}</div>
            <div style={{ display: option?.hidden ? 'none' : '' }}>
              {option.value.length}
              {option.value.map((opt, k) => (
                <BitaCheckbox
                  key={
                    opt.id
                      ? `bita_checkbox_${opt.value}-${k}-${opt.id}`
                      : `bita_checkbox_${opt.value}-${k}`
                  }
                  disabled={disabled}
                  label={opt.option}
                  value={opt.value}
                  isChecked={isChecked(opt)}
                  isFiltered={
                    filterHelper.length > 0 && filterHelper && filterField
                      ? filterHelper.includes('Global')
                        ? true
                        : filterHelper.includes(opt[filterField])
                      : false
                  }
                  allChecked={allChecked && !option?.hidden}
                  handleChange={handleChangeArray}
                  checkboxUpdate={checkboxUpdate}
                />
              ))}
            </div>
          </div>
        );
      } else {
        element = (
          <div
            key={option.id ? `key-${option.id}` : option.option}
            style={{ display: option?.hidden ? 'none' : '' }}
          >
            <BitaCheckbox
              disabled={disabled}
              label={option.option}
              value={option.value}
              isChecked={isChecked(option)}
              isFiltered={
                filterHelper?.length > 0 && filterHelper && filterField
                  ? filterHelper.includes('Global')
                    ? true
                    : filterHelper.includes(option[filterField])
                  : false
              }
              allChecked={allChecked && !option?.hidden}
              handleChange={checked => {
                handleChangeArray(checked, option);
              }}
              checkboxUpdate={checkboxUpdate}
            />
          </div>
        );
      }
      return element;
    });
  } else if (typeof data === 'object') {
    componente = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const propiedad in data) {
      componente.push(
        <div key={`propiedad_${propiedad}`} className="TitleOption">
          {propiedad.toUpperCase()}
        </div>,
      );
      data[propiedad].map(function recorrerArray(option) {
        componente.push(
          <div
            key={option.id ? `option-${propiedad}-${option.id}` : option.option}
            className="itemcheckbox"
            style={{ display: option?.hidden ? 'none' : '' }}
          >
            <BitaCheckbox
              disabled={disabled || option.disabled}
              labelClassName={props.labelClassName || ''}
              label={option.option}
              value={option.value}
              isChecked={isChecked(option)}
              isFiltered={
                filterHelper && filterField
                  ? filterHelper.includes('Global')
                    ? true
                    : filterHelper.includes(option[filterField])
                  : false
              }
              allChecked={dataReturnArray.find(val => val.id === option.id) && !option?.hidden}
              checkboxUpdate={checkboxUpdate}
              handleChange={checked => {
                handleChangeArray(checked, option);
              }}
            />
          </div>,
        );
        return 0;
      });
    }
  } else {
    console.log('Data invalid');
  }

  return (
    <div className="w-full">
      <div
        className="w-full p-5 overflow-auto pb-5 MultiSelectArea "
        data-simplebar
        style={{ minHeight: '200px' }}
      >
        {' '}
        <div className={props.className}>
          {(componente.length === 0 &&
            'There are no constituents available for the filtered universe.') ||
            componente || <Spinner />}
        </div>
      </div>
      {selectButtonsEnabled && (
        <div>
          <BitaButton
            className={allChecked ? 'selectButtonsActive' : 'selectButtons'}
            width="124px"
            height="32px"
            secondary="secundary"
            onClick={() => toggleAllCheckboxes(true)}
          >
            Select All
          </BitaButton>
          <BitaButton
            className={
              ('selectButtons',
              currentModule?.includes('Geography') && isDisabledDeselectAllButton && 'disabled')
            }
            width="124px"
            height="32px"
            secondary="secundary"
            onClick={() => toggleAllCheckboxes(false)}
          >
            Deselect All
          </BitaButton>
        </div>
      )}
    </div>
  );
};

BitaMultiSelectArea.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

BitaMultiSelectArea.defaultProps = {
  className: ' ',
};

export default BitaMultiSelectArea;
