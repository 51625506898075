import { toast } from 'react-toastify';
import axios from '../../Axios';
import * as ACTIONS from '../../constants/api-data-constants';
import { getDataComponents } from '../../api-data-mapping/dataMap';
import { TYPE_CONSTANTS } from '../../api-data-mapping/type-constants';
import { loaderAxiosDecorator, loaderDataObjectDecorator } from '../../utils/loading_status_tools';

export function cleanUpIndexStats(field) {
  return { type: ACTIONS.CLEAN_API_DATA, payload: field };
}

export function emptyApiData() {
  return { type: ACTIONS.EMPTY_API_DATA };
}
export const setWaitingResponse = ({ id, status }) => {
  return { type: ACTIONS.WAITING_API_RESPONSE, payload: { id, status } };
};

export function resetApiDataAttribute(id) {
  return { type: ACTIONS.INIT_DATA, payload: id };
}

export function setApiData(data) {
  return { type: ACTIONS.SET_API_DATA, payload: { ...data } };
}

export const setCurrentIndex = (token, indexId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/index-front/${indexId}`;
  const headers = { Authorization: `Bearer ${token}` };
  const request = loaderAxiosDecorator(dispatch, 'setIndexStats')(axios.get(url, { headers }));
  request
    .then(res => {
      dispatch(setApiData({ title: 'Current Index Selected', data: res.data }));
      const values = res.data.data;
      const files = {};
      Object.keys(values).forEach(key => {
        if (key.includes('url') && values[key]) {
          files[key.split('_')[0]] = values[key];
        }
      });

      if (Object.keys(files).length > 0) {
        dispatch(
          setApiData({
            title: 'Download Center',
            data: { status: 'done', files },
          }),
        );
      } else {
        dispatch(
          setApiData({
            title: 'Download Center',
            data: { status: 'loading' },
          }),
        );
      }
    })
    .catch(() => {
      console.info('Error getting Index Statistics.');
    });
};

export const setIndexStats = (token, indexId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/stats/${indexId}`;
  const headers = { Authorization: `Bearer ${token}` };
  axios
    .get(url, { headers })
    .then(res => {
      dispatch(setApiData({ title: 'Index Stats', data: { ...res.data, index_id: indexId } }));
    })
    .catch(() => {
      console.info('Error getting Index Statistics.');
    });
};

export const setCardStats = token => dispatch => {
  const headers = { Authorization: `Bearer ${token}` };
  const request = loaderAxiosDecorator(
    dispatch,
    'setCardStats',
  )(axios.get(`${process.env.REACT_APP_API_URL}/stats-esentials?type=regular`, { headers }));

  request.then(res => dispatch(setApiData({ title: 'Index Card Stats', data: res.data })));
};

export const setCardStatsOptimized = token => dispatch => {
  const headers = { Authorization: `Bearer ${token}` };
  const request = loaderAxiosDecorator(
    dispatch,
    'setCardStatsOptimized',
  )(
    axios.get(`${process.env.REACT_APP_API_URL}/stats-esentials?type=optimized_index`, { headers }),
  );

  request.then(res =>
    dispatch(setApiData({ title: 'Index Card Stats Optimized', data: res.data })),
  );
};

export const setCardStatsStructured = token => dispatch => {
  const headers = { Authorization: `Bearer ${token}` };
  const request = loaderAxiosDecorator(
    dispatch,
    'setCardStatsStructured',
  )(
    axios.get(`${process.env.REACT_APP_API_URL}/stats-esentials?type=structured_index`, {
      headers,
    }),
  );

  request.then(res =>
    dispatch(setApiData({ title: 'Index Card Stats Structured', data: res.data })),
  );
};

export const setFirstDateDefault = (token, id) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/first-revision?universe=${id}`;
  axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(rsp => {
    dispatch(
      setApiData({
        title: 'first_revision_universe_global_default',
        data: rsp.data.data,
      }),
    );
  });
};

export const setUniversesList = token => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/universes`;
  const request = loaderAxiosDecorator(
    dispatch,
    'setUniversesList',
  )(axios.get(url, { headers: { Authorization: `Bearer ${token}` } }));
  request.then(rsp => {
    const data = rsp.data.data.map(row => {
      const newRow = {
        ...row,
        created_at: row.created_at,
        updated_at: row.updated_at,
      };
      return newRow;
    });
    const res = { ...rsp.data, data };
    dispatch(setApiData({ title: 'Universes', data: res }));
    const defaultFirstRevision = res.data.filter(
      value => value.name === 'Global All Cap Investable Universe',
    )?.[0]?.id;
    if (!defaultFirstRevision) {
      dispatch(
        setApiData({
          title: 'first_revision_universe_global_default',
          data: '2009-01-05',
        }),
      );
    }
  });
  axios.get(`${url}?type=edit`, { headers: { Authorization: `Bearer ${token}` } }).then(rsp => {
    const data = rsp.data.data.map(row => {
      const newRow = {
        ...row,
        created_at: row.created_at,
        updated_at: row.updated_at,
      };
      return newRow;
    });
    const res = { ...rsp.data, data };
    dispatch(setApiData({ title: 'EditUniverses', data: res }));
  });
  axios
    .get(`${process.env.REACT_APP_API_URL}/universes-backtest`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(rsp => {
      const data = rsp.data.data.map(row => {
        const newRow = {
          ...row,
          created_at: row.created_at,
          updated_at: row.updated_at,
        };
        return newRow;
      });
      const res = { ...rsp.data, data };
      dispatch(setApiData({ title: 'AllUniverses', data: res }));
    });
};

export const setMethodologiesList = token => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/methodologies`;
  axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(rsp => {
    dispatch(setApiData({ title: 'Methodologies', data: rsp.data }));
  });
};
export const setDataFields = token => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/data-fields`;
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(rsp => {
      const orderedData = Object.entries(rsp.data.data).map(([key, value]) => {
        if (key === 'Fundamental Data' || key === 'ESG Ratings') {
          return {
            [key]: value.sort((a, b) => {
              if (a.name > b.name) return 1;
              if (a.name < b.name) return -1;
              return 0;
            }),
          };
        }
        return { [key]: value };
      });

      const objOrdered = orderedData.reduce((obj, current) => ({ ...obj, ...current }), {});
      if (objOrdered['Fundamental Ratios - Instrument'] && objOrdered['Size - Instrument']) {
        objOrdered['Fundamental Ratios and Size Instrument'] = objOrdered[
          'Fundamental Ratios - Instrument'
        ]
          .concat(objOrdered['Size - Instrument'])
          .sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          });
      }

      dispatch(
        setApiData({
          title: 'data_fields',
          data: { type: rsp.data.type, data: { ...objOrdered } },
        }),
      );
    });
};

export const setCustomDataFields = token => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/custom-data-fields`;
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(rsp => {
      dispatch(
        setApiData({
          title: 'CustomDataFields',
          data: { CustomdataFields: rsp.data.data },
        }),
      );
    });
};

export const deleteEntity = (id, token) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/custom-data-fields/${id}`;
  return axios
    .delete(url, { headers: { Authorization: `Bearer ${token}` } })
    .then(rsp => {
      toast(`Data Fields deleted successfully`);
      dispatch(setCustomDataFields(token));
    })
    .catch(err => {
      toast.error(`Custom Data Fields ${id} not found`);
    });
};

export const setRiskModel = (token, username) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/risk-models`;
  axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(rsp => {
    const databyBita = rsp.data.data
      .filter(item => item.author === 'BITA')
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    const databyAutor = rsp.data.data
      .filter(item => item.author === username)
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    const customData = { 'Risk Model Bita': databyBita };
    const customData2 = { 'Risk Model Autor': databyAutor };
    const customGeneral = { 'Risk Model': rsp.data.data };
    dispatch(
      setApiData({
        title: 'risk_models_filter',
        data: { type: 'data fields filter', data: { ...customGeneral } },
      }),
    );
    dispatch(
      setApiData({
        title: 'risk_models',
        data: { type: 'data fields', data: { ...customData } },
      }),
    );
    dispatch(
      setApiData({
        title: 'risk_models_autor',
        data: { type: 'data fields', data: { ...customData2 } },
      }),
    );
  });
};

export const setClientDataFields = token => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/data-fields-client?by_filter=true`;
  axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(rsp => {
    const customData = { 'Custom Data Fields': rsp.data.data };
    dispatch(
      setApiData({
        title: 'client_data_fields',
        data: { type: rsp.data.type, data: { ...customData } },
      }),
    );
    dispatch(
      setApiData({
        title: 'Custom Data Fields',
        data: { type: rsp.data.type, data: rsp.data.data },
      }),
    );
  });
};

export const setAviableDataFields = token => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/data-fields-client?by_filter=byTableCalculation`;
  axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(rsp => {
    dispatch(
      setApiData({
        title: 'Available Data Fields',
        data: {
          data: {
            type: rsp.data.type,
            'Available Data Fields': rsp.data.data,
          },
        },
      }),
    );
  });
};
export const setBaskets = token => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/baskets`;
  axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(rsp => {
    dispatch(
      setApiData({
        title: 'Baskets',
        data: rsp.data || [],
      }),
    );
  });
};

export const setIndexesList = token => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/indexes`;
  axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(rsp => {
    dispatch(setApiData({ title: 'Indexes', data: rsp.data }));
    const dataStructureIndex =
      rsp.data.data?.filter(index => index.index_type === 'structured_index') || [];
    const dataOptimizedIndex =
      rsp.data.data?.filter(index => index.index_type === 'optimized_index') || [];
    const dataRegularIndex = rsp.data.data?.filter(index => index.index_type === 'regular') || [];

    dispatch(
      setApiData({
        title: 'Structured Indexes',
        data: { type: 'index builder', data: dataStructureIndex },
      }),
    );
    dispatch(
      setApiData({
        title: 'Optimized Indexes',
        data: { type: 'index builder', data: dataOptimizedIndex },
      }),
    );
    dispatch(
      setApiData({
        title: 'Regular Indexes',
        data: { type: 'index builder', data: dataRegularIndex },
      }),
    );
  });
};

export const setThemesList = () => dispatch => {
  // set apiData for Themes
  const source = axios.CancelToken.source();
  (async () => {
    const dataComponent = getDataComponents.filter(
      v => v.key === 'Themes' && v.type === 'SelectionList',
    )[0];
    if (typeof dataComponent !== 'undefined') {
      const res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData();
      const dataApi = {
        title: `Themes_${dataComponent.title}`,
        data: dataComponent.formatData(res.data),
      };
      dispatch(setApiData(dataApi));
      // setDataReturnObj(dataApi);
    }
  })();
  return () => {
    source.cancel();
  };
};

export const setListInstrumentSelection = token => dispatch => {
  (async () => {
    const dataComponent = getDataComponents.filter(
      v => v.key === 'list_of_instruments_structured_products',
      // v => v.key === data_key && v.title === title && v.type === type,
    )[0];
    if (typeof dataComponent !== 'undefined') {
      const res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(token);
      dispatch(
        setApiData({
          title: 'list_of_instruments_structured_products',
          data: res.data,
        }),
      );
    }
  })();
};

export const getUsers = (props, token) => dispatch => {
  // set apiData for UserManagement
  const source = axios.CancelToken.source();
  (async () => {
    const dataComponent = getDataComponents.filter(
      v => v.key === props.data_api_key && v.type === props.type,
    )[0];
    if (typeof dataComponent !== 'undefined') {
      const res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(token);
      if (res.data) {
        dispatch(setApiData({ title: props.data_api_key, data: res.data }));
      }
    }
  })();
  return () => {
    source.cancel();
  };
};

export const setBenchmarkList = token => dispatch => {
  (async () => {
    const dataComponent = getDataComponents.filter(
      v => v.key === 'Benchmark' && v.type === TYPE_CONSTANTS.DROPDOWN,
    )[0];
    if (typeof dataComponent !== 'undefined') {
      const res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(token);
      const dataApi = {
        title: dataComponent.title,
        data: {
          type: 'data fields',
          data: { Benchmark: dataComponent.formatData(res.data) },
        },
      };
      dispatch(setApiData(dataApi));
    }
  })();
};

// eslint-disable-next-line no-unused-vars
export const delteEntity = (data, token) => dispatch => {
  let typeDelete = 'universe';
  let showType = 'Universe(s) deleted successfully!';
  if (data.type === 'Universes') {
    typeDelete = 'universe';
    showType = 'Universe(s) deleted successfully!';
  }
  if (data.type === 'Custom Fund/Instrument') {
    typeDelete = 'data_fields';
    showType = 'Custom Fund/Instrument(s) deleted successfully!';
  }
  if (
    data.type === 'Indexes' ||
    data.type === 'Structured Indexes' ||
    data.type === 'Optimized Indexes'
  ) {
    typeDelete = 'index';
    showType = 'Index(es) deleted successfully!';
  }
  if (data.type === 'Methodologies') {
    typeDelete = 'methodology';
    showType = 'Methodology(ies) deleted successfully!';
  }
  if (data.type === 'Data Fields') {
    typeDelete = 'data_fields';
    showType = 'Data Fields deleted successfully!';
  }
  if (data.type === 'Benchmark') {
    typeDelete = 'benchmark';
    showType = 'Benchmarks deleted successfully!';
  }

  if (data.type === 'Portfolios') {
    typeDelete = 'basket';
    showType = 'Portfolios deleted successfully!';
  }

  let status = true;
  // eslint-disable-next-line array-callback-return
  Object.keys(data.data).map(item => {
    const url = `${process.env.REACT_APP_API_URL}/delete-content?type=${typeDelete}&id=${item}`;
    axios
      .delete(url, { headers: { Authorization: `Bearer ${token}` } })
      .then(rsp => {
        status = true;
      })
      .catch(err => {
        toast.error(`${data.type}  ${item} not found`);
        status = false;
      })
      .finally(rsp => {
        setTimeout(dispatch(setBaskets(token)), 30000);
        setTimeout(dispatch(setBenchmarkList(token)), 30000);
      });
  });
  if (status) toast(showType);
  dispatch(setUniversesList(token));
  dispatch(setMethodologiesList(token));
  dispatch(setIndexesList(token));
  dispatch(setCardStats(token));
  dispatch(setClientDataFields(token));
  dispatch(setAviableDataFields(token));
  dispatch(setCardStatsOptimized(token));
  dispatch(setCardStatsStructured(token));
};

export const saveField = (data, token) => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/custom-data-fields`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(rsp => {
      toast(`${rsp.data.message}`);
    })
    .catch(error => {
      console.info(error);
    });
};

export const editUniverseName = (id, newName, token) => async dispatch => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/universe-name`,
      { id, name: newName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Handle the successful response here
      // retrigger load universe list
      dispatch(setUniversesList(token));
      toast(`${response.data.result}`);
    } else {
      // Handle other response status codes if necessary
      console.error('Unexpected response status:', response.status);
    }
  } catch (error) {
    console.error(error);
  }
};

// edit index name

export const editIndexName = (id, newName, token) => async dispatch => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/index-name`,
      { id, name: newName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Handle the successful response here
      // retrigger load universe list
      dispatch(setIndexesList(token));
      toast(`${response.data.result}`);
    } else {
      // Handle other response status codes if necessary
      console.error('Unexpected response status:', response.status);
    }
  } catch (error) {
    console.error(error);
  }
};

export const editMethodologyName = (id, newName, token) => async dispatch => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/methodology-name`,
      { id, name: newName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Handle the successful response here
      // retrigger load universe list
      dispatch(setMethodologiesList(token));
      toast(`${response.data.result}`);
    } else {
      // Handle other response status codes if necessary
      console.error('Unexpected response status:', response.status);
    }
  } catch (error) {
    console.error(error);
  }
};

export const editBenchmarkName = (id, newName, token) => async dispatch => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/benchmark-name`,
      { id, name: newName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Handle the successful response here
      // retrigger load universe list
      dispatch(setBenchmarkList(token));
      toast(`${response.data.result}`);
    } else {
      // Handle other response status codes if necessary
      console.error('Unexpected response status:', response.status);
    }
  } catch (error) {
    console.error(error);
  }
};

export const editPortfolioName = (id, newName, token) => async dispatch => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/portfolio-name`,
      { id, name: newName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Handle the successful response here
      // retrigger load universe list
      dispatch(setBaskets(token));
      toast(`${response.data.result}`);
    } else {
      // Handle other response status codes if necessary
      console.error('Unexpected response status:', response.status);
    }
  } catch (error) {
    console.error(error);
  }
};

export const editDataFieldName = (id, newName, token) => async dispatch => {
  try {
    console.log(id, newName, 'edit df name');
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/data-field-name`,
      { id, name: newName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Handle the successful response here
      // retrigger load universe list
      dispatch(setClientDataFields(token));
      toast(`${response.data.result}`);
    } else {
      // Handle other response status codes if necessary
      toast('Unexpected response status:', response.status);
      console.error('Unexpected response status:', response.status);
    }
  } catch (error) {
    console.error(error);
  }
};

export const mapEditData = (data, id, name, token) => async dispatch => {
  const dataType = data.split('-')[1].toLowerCase();
  try {
    if (dataType === 'universes') {
      dispatch(editUniverseName(id, name, token));
    } else if (
      dataType === 'indexes' ||
      dataType === 'structured indexes' ||
      dataType === 'optimized indexes'
    ) {
      dispatch(editIndexName(id, name, token));
    } else if (dataType === 'methodologies') {
      dispatch(editMethodologyName(id, name, token));
    } else if (dataType === 'benchmark') {
      dispatch(editBenchmarkName(id, name, token));
    } else if (dataType === 'portfolios') {
      dispatch(editPortfolioName(id, name, token));
    } else if (dataType === 'data fields') {
      dispatch(editDataFieldName(id, name, token));
    }
  } catch (error) {
    console.error('No type found');
  }
};
