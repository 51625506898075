import { toast } from 'react-toastify';
import axios from '../../Axios';
import * as ACTIONS from '../../constants/auth-constants';
import { LocalStorage } from '../../localStorage';
import { LOCALSTORAGE_USERDATA } from '../../constants/global-constants';
import * as ApiDataActions from './api-data-actions';
import { loaderAxiosDecorator } from '../../utils/loading_status_tools';
import { setApiData } from './api-data-actions';

export const setLoginLoading = loading => {
  return { type: ACTIONS.LOGIN_LOADING, payload: loading };
};

export const setLoginError = error => {
  return { type: ACTIONS.LOGIN_ERROR, payload: error };
};

export const setLoginSuccess = data => {
  return { type: ACTIONS.LOGIN_SUCCESS, payload: data };
};

export const setRememberLogin = data => {
  return { type: ACTIONS.LOGIN_SUCCESS, payload: data };
};

export const setLogout = () => {
  return { type: ACTIONS.LOGOUT, payload: {} };
};

export const getUserFromStorage = data => {
  return { type: ACTIONS.LOGIN_SUCCESS, payload: data };
};

export const passwordChange = () => {
  return { type: ACTIONS.PASSWORD_CHANGE, payload: {} };
};
export const companyChange = data => {
  return { type: ACTIONS.COMPANY_CHANGE, payload: data };
};

export const updateCustomization = customization => {
  return { type: ACTIONS.UPDATE_CUSTOMIZATION, payload: customization };
};

export const logout = (message = 'Logout') => dispatch => {
  toast(message, { autoClose: 9000 });

  dispatch(setLogout());
  LocalStorage.delete(LOCALSTORAGE_USERDATA);
};

export const login = (user, password, remember) => dispatch => {
  dispatch(setLoginLoading(true));
  const body = {
    user,
    password,
  };
  axios
    .post(`${process.env.REACT_APP_API_URL}/login`, body)
    .then(rsp => {
      dispatch(setLoginSuccess(rsp.data));
      if (remember) {
        LocalStorage.save(LOCALSTORAGE_USERDATA, rsp.data);
      }
      toast.dismiss();
      toast(`Welcome ${rsp.data.user.first_name} ${rsp.data.user.last_name}`, { autoClose: 3500 });
    })
    .catch(err => {
      if (err.response?.status === 401) {
        if (err.response.data?.expired) {
          dispatch(
            ApiDataActions.setApiData({
              title: 'modal',
              data: {
                open: true,
                text: `
                Dear User,

                Thank you for participating in the trial of BITACORE. We hope you had a good experience with the platform.

                Your trial period has ended. However, if you would like to extend your trial period or purchase a full license, please do not hesitate to contact us at bitacore@bitadata.com.
                
                One of our customer service representatives will contact you right away.
                
                Regards,
                BITA team.`,
              },
            }),
          );
          dispatch(setLoginLoading(false));
        }
      } else {
        toast('Your credentials are invalid. Please, try again.');
        dispatch(setLoginError(err.message));
      }
    });
};

export const changePassword = (token, id, newPassword) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/user/${id}`;
  const headers = { Authorization: `Bearer ${token}` };
  axios
    .put(url, { password: newPassword }, { headers })
    .then(rsp => {
      toast('Your password has been successfully changed.', { autoClose: 5000 });
      dispatch(passwordChange());
    })
    .catch(err => {
      dispatch(setLoginError(err.message));
      toast('Error. your password has not been updated correctly, please try later', {
        autoClose: 5000,
      });
    });
};

export const verifyEmailId = email => dispatch => {
  (async () => {
    const request = loaderAxiosDecorator(
      dispatch,
      'verifyEmailId',
    )(axios.post(`${process.env.REACT_APP_API_URL}/user-generate-temp-password`, { email }));

    request
      .then(res => {
        toast('Temporary password sent.');
        dispatch(setApiData({ title: 'verify_email_id', data: true }));
      })
      .catch(err => {
        toast.error(
          'The account linked to the email ID is not active or unavailable, please contact us at bitacore@bita.io. Our concerned team will contact you shortly.',
        );
        dispatch(setApiData({ title: 'verify_email_id', data: false }));
      });
  })();
};
