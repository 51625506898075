/* eslint-disable */
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import FileSaver from 'file-saver';
import { useParams, useLocation } from 'react-router';
import axios from '../../../Axios';
import * as CommonActions from '../../../constants/common-constants';
import {
  roundUpPercentageString,
  isDateBetweenRanges,
  versionIndexName,
  hasValidBitaDomain,
} from '../../../utils/utils';
import BitaCharts from '../../../bitaComponents/BitaCharts/BitaCharts';
import {
  historicalPerformanceTheme,
  anualReturnTheme,
} from '../../../bitaComponents/BitaCharts/Themes';
import styles from './PerformanceAndRisk.module.scss';
import '../AnalyzerComponents.scss';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaSpinner from '../../BitaSpinner/BitaSpinner';
import BitaSelect from '../../BitaSelect/BitaSelect';
import BitaSearchBar from '../../../bitaComponents/BitaSearchBar/BitaSearchBar';
import ConfigYAML from '../../../config/ConfigYAML';
import { selectUniverse } from '../../../state/actions/universe-actions';
import { updateUniverse, updateMethodology } from '../../../state/actions/module-selection-actions';
import { getIndexBenchmark, getIndexPCFInfo } from '../../../state/actions/benchmark-actions';
import * as Action from '../../../state/actions/api-data-actions';
import RunBacktestModal from '../../../pages/runBacktestModal';
import RunRebalancingReconstitution from '../../../pages/runRebalancingReconstitution';
import { DownloadCenterModal } from '../../BitaModal/DownloadCenterModal';
import ModalBasketCalculation from '../ModalBasketCalculation/ModalBasketCalculation';
import { IconsModules } from '../../../constants/icons-module';
import { excludeUser } from '../../../constants/exclude-modules-user';
import { loaderAxiosDecorator, useLoadStatus } from '../../../utils/loading_status_tools';
import IndexDescription from '../IndexDescription';
import DownloadCenter from '../DownloadCenter';
import { thousandFormat } from '../../../utils/utils';

function getFormatter() {
  return {
    tooltip: {
      formatter() {
        return `${this.points[0].point.date.split('T')[0]} <br> ${
          this.points[0].series.name
        } : ${thousandFormat(this.y)}  `;
      },
    },
  };
}

const timeframeFilter = (data, timelapse, lastDate) => {
  if (data) {
    const date = data.split('T')[0];
    const lastFormattedDate = lastDate.date && lastDate.date.split('T')[0];

    const firstDayCurrentYear = moment()
      .startOf('year')
      .format('YYYY-MM-DD');

    const subtractYear = amount => {
      const d = lastFormattedDate;
      return moment(d)
        .subtract(amount, 'year')
        .format('YYYY-MM-DD');
    };
    switch (timelapse) {
      case 'YTD':
        return moment(date).isAfter(firstDayCurrentYear);
      case '1Y': {
        const subtracted = subtractYear(1);
        return isDateBetweenRanges(date, { startDate: subtracted, endDate: lastFormattedDate });
      }
      case '3Y': {
        const subtracted = subtractYear(3);
        return isDateBetweenRanges(date, { startDate: subtracted, endDate: lastFormattedDate });
      }
      case '5Y': {
        const subtracted = subtractYear(5);
        return isDateBetweenRanges(date, { startDate: subtracted, endDate: lastFormattedDate });
      }
      default:
        return true;
    }
  }
  return false;
};

const HistoricalPerformanceCharts = ({
  indexStats,
  benchmarkStats,
  indexValues,
  benchmarkValues,
}) => {
  const [selectedChart, setSelectedChart] = useState('historicalPerformance');
  const [anualReturnData, setAnualReturnData] = useState(indexStats.getIn(['data', '44', 'value']));
  const [benchmarkAnualReturnData, setAnualBenchmarReturnData] = useState(
    benchmarkStats.getIn(['data', '44', 'value']),
  );
  const [historicalPIT, setHistoricalPIT] = useState('Max');
  const PITButtons = [
    { name: 'YTD', value: 'YTD' },
    { name: '1 Year', value: '1Y' },
    { name: '3 Years', value: '3Y' },
    { name: '5 Years', value: '5Y' },
    { name: 'Max', value: 'Max' },
  ];
  useEffect(() => {
    setAnualReturnData(indexStats.getIn(['data', '44', 'value']));
  }, [indexStats, indexValues]);

  useEffect(() => {
    setAnualBenchmarReturnData(benchmarkStats.getIn(['data', '44', 'value']));
  }, [benchmarkStats]);

  const performanceBenchmarkData =
    indexValues && indexValues.size > 0 && benchmarkValues
      ? fromJS(benchmarkValues).filter(v =>
          timeframeFilter(v.get('date'), historicalPIT, indexValues.last().toJS()),
        )
      : fromJS([]);

  const performanceData =
    indexValues && indexValues.size > 0
      ? fromJS(indexValues).filter(v =>
          timeframeFilter(v.get('date'), historicalPIT, indexValues.last().toJS()),
        )
      : fromJS([]);

  const normalizedBenchmark = () => {
    const benchmarkData = performanceBenchmarkData.toJS().filter(stat => stat.y);

    if (benchmarkData.length > 0) {
      const index_stat = performanceData.toJS().find(stat => stat.date === benchmarkData[0].date);

      if (index_stat && benchmarkData[0].y) {
        let init_value = index_stat.y;
        let value = 1;
        const normalizedData = [
          {
            date: benchmarkData[0].date,
            x: benchmarkData[0].x,
            y: roundUpPercentageString(index_stat.y),
            name: benchmarkData[0].name,
          },
        ];
        for (let i = 1; benchmarkData.length > i; i += 1) {
          value = (init_value * benchmarkData[i].y) / benchmarkData[i - 1].y;
          normalizedData.push({
            date: benchmarkData[i].date,
            x: benchmarkData[i].x,
            y: roundUpPercentageString(value),
            name: benchmarkData[i].name,
          });
          init_value = value;
        }
        return normalizedData;
      }
    }
    return benchmarkData;
  };

  const getInterval = () => {
    switch (historicalPIT) {
      case 'YTD':
        return 86400000 * 31;
      case '1Y':
        return 86400000 * 31;
      default:
        return 86400000 * 31 * 6;
    }
  };
  const performanceChartOptions = {
    xAxis: {
      type: 'datetime',
      labels: {
        format: '{value:%Y-%b-%e}',
      },
      tickInterval: getInterval(),
    },
    yAxis: {
      labels: {
        formatter() {
          return `${thousandFormat(this.value)}`;
        },
      },
    },
    series: [
      {
        name: 'Index Value',
        data: performanceData.toJS(),
      },
      {
        name: 'Benchmark Value',
        data: normalizedBenchmark(),
      },
    ],
    lang: {
      noData: "There's no data available for the time frame you've selected",
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '12px',
        color: '#ffffff',
      },
    },
  };

  const anualCategories = anualReturnData
    ? anualReturnData
        .map(
          stat =>
            stat
              .get('timestamp')
              .split('T')[0]
              .split('-')[0],
        )
        .slice()?._tail?.array
    : fromJS([]);

  const anualSeries = anualReturnData
    ? anualReturnData
        .map(stat => {
          return {
            value: roundUpPercentageString(stat.get('value') * 100),
            timestamp: stat.get('timestamp'),
          };
        })
        .slice()?._tail?.array
    : [];

  const anualBenchmarkSeries = benchmarkAnualReturnData
    ? benchmarkAnualReturnData
        .map(stat => {
          return {
            value: roundUpPercentageString(stat.get('value') * 100),
            timestamp: stat.get('timestamp'),
          };
        })
        .slice()?._tail?.array
    : [];

  const anualReturnOptions = {
    xAxis: {
      categories: anualCategories?.sort((a, b) => new Date(a) - new Date(b)) ?? [],
    },
    series: [
      {
        name: 'Index',
        data:
          anualSeries
            ?.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
            ?.map(item => item.value) ?? [],
        color: '#2962FF',
      },
      {
        name: 'Benchmark',
        data:
          anualBenchmarkSeries
            ?.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
            ?.map(item => item.value) ?? [],
        color: '#FA5400',
      },
    ],
  };

  const chartTabs = {
    historicalPerformance: (
      <BitaCharts
        key="historicalPerformanceChart"
        options={{ ...performanceChartOptions, ...getFormatter() }}
        theme={historicalPerformanceTheme}
        containerProps={{
          style: { padding: 20, minHeight: '400px', height: '100%', width: '100%' },
        }}
      />
    ),
    anualReturn: (
      <BitaCharts
        key="anualReturnChart"
        options={anualReturnOptions}
        theme={anualReturnTheme}
        containerProps={{
          style: { padding: 20, minHeight: '400px', height: '100%', width: '100%' },
        }}
      />
    ),
  };

  return (
    <>
      {/* REFACTOR */}
      <div className={styles.performanceTitleContainer}>
        <span className={styles.performanceTitle}>Performance</span>
      </div>
      <div className={styles.performanceButtonsContainer}>
        <div className={styles.performanceTabs}>
          <button
            onClick={() => setSelectedChart('historicalPerformance')}
            className={classNames(
              styles.tabStyle,
              selectedChart === 'historicalPerformance' && styles.selectedTab,
            )}
          >
            Historical Performance
          </button>
          <button
            onClick={() => setSelectedChart('anualReturn')}
            className={classNames(
              styles.tabStyle,
              selectedChart === 'anualReturn' && styles.selectedTab,
            )}
          >
            Annual Returns
          </button>
        </div>
        <div className={styles.radioButtonsContainer} style={{ height: '20px' }}>
          {selectedChart !== 'anualReturn' &&
            PITButtons.map(pit => (
              <div>
                <input
                  id={pit.value}
                  type="radio"
                  name="radio-filterChart"
                  onClick={() => setHistoricalPIT(pit.value)}
                  checked={historicalPIT === pit.value}
                />
                <label htmlFor={pit.value}>{pit.name}</label>
              </div>
            ))}
        </div>
      </div>
      <div className={styles.performanceChartContainer}>{chartTabs[`${selectedChart}`]}</div>
    </>
  );
};

const GeneralStatsPITButtons = [
  { name: 'YTD', value: 'ytd' },
  { name: '1 Year', value: '1y' },
  { name: '3 Years', value: '3y' },
  { name: '5 Years', value: '5y' },
  { name: 'Max', value: 'max' },
];

const PerformanceAndRisk = ({ benchmarkList }) => {
  const [indexStats, setIndexStats] = useState(fromJS({}));
  const [pseudoTimeout, setPseudoTimeout] = useState(false);
  const [benchmarkStats, setBenchmarkStats] = useState(fromJS({}));
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('1y');
  const [indexValues, setIndexValues] = useState([]);
  const [benchmarkValues, setBenchmarkValues] = useState([]);
  const optionsBaseDefault = benchmarkList || [];
  const [riskModel, setRiskModel] = useState(11);
  const [optionsBase, setOptionsBase] = React.useState(optionsBaseDefault[0]);
  const apiData = useSelector(state => state.apiData);
  const company = useSelector(state => state.auth.company);
  const userData = useSelector(state => state.auth.userdata);
  const backtestData = useSelector(state => state.backtest);
  const [indexBenchmark, setIndexBenchmark] = useState();
  const dispatch = useDispatch();
  const modalRef = useRef();
  const ModalRRRef = useRef();
  const ModalBCRef = useRef();

  const index_list = _.get(apiData, ['apiData', 'Indexes', 'data'], []);
  const currentIndexSelected = useSelector(
    state => state.apiData.apiData['Current Index Selected'],
  );
  const token = useSelector(state => state.auth.token);
  const location = useLocation();
  const { indexId } = useParams();

  const isShowActivateCalculation =
    hasValidBitaDomain(userData?.email) || userData?.company?.name === 'demo_company';

  useEffect(() => {
    if (apiData.apiData && apiData.apiData['Index Stats']) {
      setIndexStats(fromJS(apiData.apiData['Index Stats']));
    }
  }, [apiData.apiData['Index Stats']]);

  useEffect(() => {
    if (apiData.apiData && apiData.apiData['Benchmark Card Stats']) {
      setBenchmarkStats(fromJS(apiData.apiData['Benchmark Card Stats']));
    }
  }, [apiData.apiData['Benchmark Card Stats']]);

  let ImageI;
  if (currentIndexSelected?.data?.index_type === 'regular') {
    ImageI = IconsModules.IndexModule;
  } else if (currentIndexSelected?.data?.index_type === 'optimized_index') {
    ImageI = IconsModules.OptimizedModule;
  } else if (currentIndexSelected?.data?.index_type === 'basket_index') {
    ImageI = IconsModules.PortfolioBasketModule;
  } else {
    ImageI = IconsModules.StructureIModule;
  }

  /**
   * Para borrar cuando se activen las otras pantallas de los Structured Index
   */
  const [datesArray, setDateArray] = useState([]);
  const [dateLabel, setDateLabel] = useState('');
  const [pcfInfo, setPcfInfo] = useState([]);
  const [dateSelected, setDateSelected] = useState('');

  useEffect(() => {
    if (
      dateSelected !== '' &&
      currentIndexSelected.data &&
      currentIndexSelected?.data?.index_type === 'structured_index'
    ) {
      getIndexPCFInfo(currentIndexSelected.data.id, dateSelected, token).then(resp => {
        if (resp.data && resp.data.data.pcf_info) {
          const pcInfo = resp.data.data.pcf_info;
          setPcfInfo(resp.data.data.pcf_info);
        }
      });
    }
  }, [dateSelected]);
  useEffect(() => {
    setDateSelected(datesArray[0]?.date || '');
    setDateLabel(datesArray[0]?.value || '');
  }, [datesArray]);

  useEffect(() => {
    const dateList = _.get(currentIndexSelected, 'data.dates_constitutions_real', false);
    if (dateList && currentIndexSelected?.data?.index_type === 'structured_index') {
      setDateArray(
        Object.entries(dateList)
          .slice()
          .reverse()
          .map(([key, date], i) => {
            return { id: i, value: key, date };
          }),
      );
    }
  }, [currentIndexSelected]);
  /** */

  useEffect(() => {
    if (currentIndexSelected.data && currentIndexSelected.data.benchmark_id) {
      setIndexBenchmark(currentIndexSelected.data.benchmark_id);

      const usedBenchmark = optionsBaseDefault.filter(
        bench => bench.id === currentIndexSelected.data.benchmark_id,
      );
      if (usedBenchmark.length > 0) setOptionsBase(usedBenchmark[0]);
      const riskModelList = _.get(
        apiData,
        ['apiData', 'risk_models', 'data', 'Risk Model Bita'],
        [],
      );
      const risk = riskModelList.filter(r => r.name === currentIndexSelected.data.risk_name);
      if (risk.length > 0) {
        setRiskModel(risk[0]);
      }
    }
  }, [currentIndexSelected, benchmarkList]);

  useEffect(() => {
    if (indexId && indexBenchmark) {
      getIndexBenchmark(indexId, indexBenchmark, token).then(resp => {
        dispatch(
          Action.setApiData({
            title: 'Benchmark Card Stats',
            data: resp.data,
          }),
        );
      });
    }
  }, [indexBenchmark]);

  useEffect(() => {
    if (indexId) {
      dispatch(Action.setCurrentIndex(token, indexId));
      dispatch(Action.setIndexStats(token, indexId));
    }
  }, [indexId]);

  useLayoutEffect(() => {
    const arr = indexStats.getIn(['data', '45', 'value']) || fromJS([]);
    const formattedValues = arr
      .sort((a, b) => {
        const currentDate = new Date(a.get('timestamp'));
        const nextDate = new Date(b.get('timestamp'));
        if (currentDate < nextDate) {
          return 1;
        }
        if (currentDate > nextDate) {
          return -1;
        }
        return 0;
      })
      .map(stat =>
        fromJS({
          date: stat.get('timestamp'),
          x: new Date(stat.get('timestamp')),
          y: roundUpPercentageString(stat.get('value')),
          name: stat.get('timestamp'),
        }),
      );

    setIndexValues(formattedValues.slice().reverse());
  }, [indexStats]);

  useLayoutEffect(() => {
    const arr = benchmarkStats.getIn(['data', '45', 'value']) || fromJS([]);

    const formattedValues = arr
      .sort((a, b) => {
        const currentDate = new Date(a.get('timestamp'));
        const nextDate = new Date(b.get('timestamp'));
        if (currentDate < nextDate) {
          return 1;
        }
        if (currentDate > nextDate) {
          return -1;
        }
        return 0;
      })
      .map(stat =>
        fromJS({
          date: stat.get('timestamp'),
          y: roundUpPercentageString(stat.get('value')),
          x: new Date(stat.get('timestamp')),
          name: new Date(stat.get('timestamp')),
        }),
      );

    setBenchmarkValues(formattedValues.slice().reverse());
  }, [benchmarkStats]);

  React.useEffect(() => {
    dispatch({
      type: CommonActions.SUBHEADER_UPDATE,
      payload: 'ANALYZER · PERFORMANCE & RISK',
    });
  }, [dispatch]);
  const isSameAsBacktest = () => {
    if (
      backtestData &&
      currentIndexSelected &&
      currentIndexSelected.data &&
      backtestData.id === currentIndexSelected.data.id
    ) {
      return true;
    }
    return false;
  };

  const filesDownloadCenter = isSameAsBacktest()
    ? _.get(backtestData, 'pdfs', {
        status: 'loading',
      })
    : _.get(apiData, 'apiData.[Download Center]', {
        status: 'loading',
      });

  const timer = ConfigYAML.jsonPath(['analyzer', 'timeout_files']);
  const filesSpinnerMessage = ConfigYAML.jsonPath(['analyzer', 'timeout_text']);
  useEffect(() => {
    if (indexStats.size > 0) {
      setTimeout(() => {
        setPseudoTimeout(true);
      }, timer);
    }
  }, [indexStats]);

  const getMethodology = idMeth => {
    const allMethodologies = _.get(apiData, 'apiData.[Methodologies]', {});
    const selectedMeth = allMethodologies.data.filter(m => m.id === idMeth)[0];
    if (selectedMeth) {
      dispatch(updateMethodology(selectedMeth));
      dispatch(
        Action.setApiData({
          title: 'methodologySelected',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
      dispatch(
        Action.setApiData({
          title: 'methodologySelectedCard',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
    }
  };

  const getUniverse = idUniverse => {
    const allUniverses = _.get(apiData, 'apiData.[AllUniverses]', {});
    const selectedUni = allUniverses.data.filter(u => u.id === idUniverse)[0];
    if (selectedUni) {
      dispatch(selectUniverse(selectedUni));
      dispatch(updateUniverse(selectedUni));
      dispatch(
        Action.setApiData({
          title: 'universeSelected',
          data: selectedUni,
        }),
      );
      dispatch(
        Action.setApiData({
          title: 'universeSelectedCard',
          data: selectedUni,
        }),
      );
    }
  };

  const activeRunBackTest = () => {
    if (currentIndexSelected && currentIndexSelected.data) {
      const idUniverse = currentIndexSelected.data.universe_id;
      const idMethodology = currentIndexSelected.data.methodology_id;
      getUniverse(idUniverse);
      getMethodology(idMethodology);
      dispatch(
        Action.setApiData({
          title: 'indexBacktest',
          data: {
            'Index Backtest': {
              'INDEX NAME': versionIndexName(currentIndexSelected.data.name, index_list),
              'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
              'Start Date': currentIndexSelected.data.start_date.split('T')[0],
              'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
              Benchmark: optionsBase,
              risk_model_id: riskModel,
              RiskOption: riskModel,
            },
          },
        }),
      );
      modalRef.current.runModal();
    }
    return {};
  };

  const activeRebalanceReconstitution = () => {
    dispatch(
      Action.setApiData({
        title: 'indexBacktest',
        data: {
          'Index Backtest': {
            'PORTFOLIO NAME': versionIndexName(currentIndexSelected.data.name, index_list),
            'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
            'Start Date': currentIndexSelected.data.start_date.split('T')[0],
            'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
            Benchmark: optionsBase,
            risk_model_id: riskModel,
            RiskOption: riskModel,
          },
        },
      }),
    );
    const idUniverse = currentIndexSelected.data.universe_id;
    const idMethodology = currentIndexSelected.data.methodology_id;
    getUniverse(idUniverse);
    getMethodology(idMethodology);
    ModalRRRef.current.runModal();
  };

  const getIndexName = () => {
    if (currentIndexSelected && currentIndexSelected.data) {
      return currentIndexSelected.data.name;
    }
    return '';
  };

  const generalStats = ConfigYAML.jsonPath([
    'analyzer',
    "widgets[?(@.title == 'General Statistics')]",
    'components',
  ]);

  const tableData = {
    index: indexStats && indexStats.get('data')?.toJS(),
    benchmark: benchmarkStats && benchmarkStats.get('data')?.toJS(),
  };

  const tableValue = (labelValue, value) => {
    if (labelValue) {
      if (
        labelValue !== 'Beta' &&
        labelValue !== 'Annualized Information Ratio' &&
        labelValue !== 'Sharpe Ratio'
      ) {
        if (value !== null) {
          return `${roundUpPercentageString(value * 100)}%`;
        } else {
          return 'N/A';
        }
      }
      if (value !== null) {
        return parseFloat(value).toFixed(2);
      }
      return 'N/A';
    }
    return 'N/A';
  };
  const generateTables = (data, timeframe) => {
    return generalStats.map(
      stat =>
        stat.type === 'Table' && (
          <div className={styles.tableContainer}>
            <table className={styles.generatedTable}>
              <thead className={styles.generatedTableHeader}>
                <th className={styles.firstTableHeader}>{stat.title}</th>
                {Object.entries(stat.data).map(([, value]) => (
                  <th className={styles.restTableHeaders}>{value.column_name}</th>
                ))}
              </thead>
              {Object.entries(stat.rows_names).map(([, value], index) => (
                <tr className={styles.generatedTableValues}>
                  <td className={styles.firstTableValue}>
                    {value === 'Low Volatility' || value === 'Volatility'
                      ? 'Daily Volatility '
                      : value}
                  </td>
                  {Object.entries(stat.data).map(([valueKey, valueD]) => (
                    <td className={styles.restTableValues}>
                      {data[valueD.column_name.toLowerCase()] &&
                      data[valueD.column_name.toLowerCase()][valueD.rows[index][timeframe]]
                        ? tableValue(
                            value,
                            data[valueD.column_name.toLowerCase()][valueD.rows[index][timeframe]]
                              .value,
                          )
                        : tableValue(false)}
                    </td>
                  ))}
                </tr>
              ))}
            </table>
          </div>
        ),
    );
  };
  const getGeneralStatisticTitle = () => {
    let date = false;
    if (indexValues) {
      const datesItems = fromJS(indexValues).toJS();
      if (datesItems && datesItems.length > 0) {
        const lastDate = datesItems[datesItems.length - 1].date.split('T');
        // eslint-disable-next-line prefer-destructuring
        date = lastDate[0];
      }
      if (location.search.includes('live_index')) {
        const today = moment();
        const adjustedDate = today.subtract(1, 'days');
        date = adjustedDate.format('YYYY-MM-DD');
      }
    }
    return date ? `General Statistics (as of ${date})` : 'General Statistics';
  };

  return (
    <div className={styles.base}>
      <RunBacktestModal ubication="analyzer" showData ref={modalRef} disable_title />
      <RunRebalancingReconstitution ubication="analyzer" showData ref={ModalRRRef} disable_title />
      <ModalBasketCalculation ubication="analyzer" showData ref={ModalBCRef} disable_title />
      <DownloadCenterModal />
      <div className={styles.contentContainer}>
        <div className={styles.firstHalfContainer}>
          <div className={styles.halfWidthContainer}>
            <div className={styles.baseInfoContainer}>
              <div className={styles.baseInfoTitleContainer}>
                <span className={styles.baseInfoTitle}>
                  {' '}
                  <ImageI /> {getIndexName()}
                </span>
              </div>
              <IndexDescription
                indexType={currentIndexSelected?.data?.index_type}
                universe={currentIndexSelected?.data?.universe || ''}
                methodology={currentIndexSelected?.data?.methodology || ''}
                start={currentIndexSelected?.data?.start_date.split('T')[0] || ''}
                end={currentIndexSelected?.data?.end_date?.split('T')[0] || ''}
                author={currentIndexSelected?.data?.author || ''}
                benchmark={currentIndexSelected?.data?.benchmark || ''}
                risk={currentIndexSelected?.data?.risk_name || ''}
                parentIndex={currentIndexSelected?.data?.parent_index}
                frontParameters={currentIndexSelected?.data?.front_parameters || {}}
              />
              {currentIndexSelected?.data?.index_type === 'regular' && (
                <div
                  className={styles.benchmarkContainer}
                  style={{
                    marginTop:
                      currentIndexSelected?.data?.index_type === 'optimized_index'
                        ? '-15px'
                        : '0px',
                  }}
                >
                  <span className={styles.benchmarkTitle}>Select New Benchmark</span>
                  <div className={styles.benchmarkSearch}>
                    <div className={styles.benchmarkSearchDropdown}>
                      <BitaSearchBar
                        searchOptions={optionsBaseDefault}
                        onChange={selected => {
                          setOptionsBase(selected);
                        }}
                        value={optionsBase?.value}
                      />
                    </div>
                    <BitaButton
                      primaryWhite
                      onClick={activeRunBackTest}
                      disabled={currentIndexSelected?.data?.index_type === 'structured_index'}
                    >
                      Recalculate
                    </BitaButton>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.downloadContainer}>
              <span className={styles.downloadTitle}>Download Center</span>
              {(filesDownloadCenter.status === 'done' || filesDownloadCenter?.files) &&
              pseudoTimeout ? (
                <DownloadCenter
                  files={Object.assign(
                    {
                      constituents: currentIndexSelected?.data?.constituents_url,
                      machine_readable_constituents_url:
                        currentIndexSelected?.data?.machine_readable_constituents_url,
                      machine_readable_constituents_summary_url:
                        currentIndexSelected?.data?.machine_readable_constituents_summary_url,
                      price: currentIndexSelected?.data?.price_series_url,
                      guidebook: currentIndexSelected?.data?.guidebook_url,
                    },
                    filesDownloadCenter.files,
                  )}
                  company={company}
                  indexType={currentIndexSelected?.data?.index_type}
                  frontParameters={currentIndexSelected?.data?.front_parameters || {}}
                />
              ) : (
                <div>
                  <BitaSpinner />
                  {filesSpinnerMessage}
                </div>
              )}
            </div>
          </div>
          <div className={styles.generalStatsContainer}>
            {isShowActivateCalculation && currentIndexSelected?.data?.index_type !== 'regular' && (
              <div
                style={{
                  position: 'relative',
                  top: '-85px',
                  left: '20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <BitaButton
                  primaryWhite
                  style={{
                    whiteSpace: 'nowrap',
                    width: '200px',
                    marginRight: '10px',
                    padding: '5px',
                    display: excludeUser[userData?.company?.name] ? 'none' : '',
                  }}
                  onClick={() => {
                    ModalBCRef.current.runModal();
                  }}
                >
                  Activate in Calculation
                </BitaButton>
              </div>
            )}
            {currentIndexSelected?.data?.index_type === 'regular' && (
              <div
                style={{
                  position: 'relative',
                  top: '-85px',
                  left: '20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {isShowActivateCalculation && (
                  <BitaButton
                    primaryWhite
                    style={{
                      whiteSpace: 'nowrap',
                      width: '200px',
                      marginRight: '10px',
                      padding: '5px',
                    }}
                    onClick={() => {
                      ModalBCRef.current.runModal();
                    }}
                  >
                    Activate in Calculation
                  </BitaButton>
                )}
                {(hasValidBitaDomain(userData?.email) ||
                  userData?.company?.name === 'demo_company') && (
                  <BitaButton
                    primaryWhite
                    style={{
                      whiteSpace: 'nowrap',
                      width: '200px',
                      marginRight: '10px',
                      padding: '5px',
                    }}
                    onClick={() => {
                      ModalBCRef.current.runModal();
                    }}
                  >
                    Activate for Direct Indexing
                  </BitaButton>
                )}
                <BitaButton
                  primaryWhite
                  style={{
                    whiteSpace: 'nowrap',
                    width: '200px',
                    marginRight: '10px',
                  }}
                  onClick={activeRebalanceReconstitution}
                >
                  Rebalance Portfolio/Index
                </BitaButton>
                <BitaButton
                  primaryWhite
                  style={{
                    whiteSpace: 'nowrap',
                    width: '145px',
                  }}
                  onClick={activeRunBackTest}
                >
                  Update Index
                </BitaButton>
              </div>
            )}
            <div className={styles.generalTitleContainer}>
              <span className={styles.generalTitle} style={{ whiteSpace: 'nowrap' }}>
                {getGeneralStatisticTitle()}
              </span>
            </div>
            <div className={styles.tablesContainer}>
              <div className={styles.radioButtonsContainer}>
                {GeneralStatsPITButtons.map(button => (
                  <div>
                    <input
                      id={button.value}
                      type="radio"
                      name="radio-filtertables"
                      onClick={() => setSelectedTimeFrame(button.value)}
                      checked={selectedTimeFrame === button.value}
                    />
                    <label htmlFor={button.value}>{button.name}</label>
                  </div>
                ))}
              </div>
              <div className={styles.generalCharts}>
                {tableData && generateTables(tableData, selectedTimeFrame)}
              </div>
            </div>
            {currentIndexSelected?.data?.index_type === 'structured_index' &&
              currentIndexSelected?.data?.front_parameters?.['New Structured Product'] && (
                <div id="eliminarAlActivarCharacteristic">
                  <div className={styles.dateContainer}>
                    <div className={styles.generalTitleContainer}>
                      <span className={styles.generalTitle}>CONSTITUENTS</span>
                    </div>
                    <div style={{ marginTop: '5px', marginLeft: '10px' }}>
                      <BitaSelect
                        data={datesArray}
                        handleSelection={selected => {
                          setDateSelected(selected.date);
                          setDateLabel(selected.value);
                        }}
                        label={dateLabel}
                      />
                    </div>
                  </div>
                  <div className={styles.tableContainer} data-simplebar style={{ height: '180px' }}>
                    <table className={styles.generatedTable}>
                      <thead className={styles.generatedTableHeader}>
                        <tr>
                          <th className={styles.firstTableHeader} style={{ width: '50%' }} />
                          <th className={styles.restTableHeaders}>Weight</th>
                        </tr>
                      </thead>
                      {!_.has(currentIndexSelected, [
                        'data',
                        'front_parameters',
                        'Structured Index',
                      ]) && (
                        <tbody>
                          {_.get(
                            currentIndexSelected,
                            ['data', 'front_parameters', 'Instrument Selected'],
                            '',
                          ).map((item, i) => (
                            <tr className={styles.generatedTableValues}>
                              <td className={styles.firstTableValue}>
                                {item?.['Instrument name']}
                              </td>
                              <td className={styles.restTableValues}>
                                {tableValue(
                                  'Instrument',
                                  pcfInfo?.constituents?.data?.[i]?.[
                                    pcfInfo?.constituents?.columns?.indexOf('adjusted_weight')
                                  ] || pcfInfo.equity_weight,
                                )}
                              </td>
                            </tr>
                          ))}

                          <tr className={styles.generatedTableValues}>
                            <td className={styles.firstTableValue}>Cash</td>
                            <td className={styles.restTableValues}>
                              {tableValue('Cash', pcfInfo.cash)}
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className={styles.performanceContainer}>
          {indexStats ? (
            <HistoricalPerformanceCharts
              indexStats={indexStats}
              indexValues={indexValues}
              benchmarkStats={benchmarkStats}
              benchmarkValues={benchmarkValues}
            />
          ) : (
            <BitaSpinner />
          )}
        </div>
      </div>
    </div>
  );
};
export default PerformanceAndRisk;
